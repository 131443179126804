import React, { Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import MemberFiles from './MemberFiles';
import MemberNotes from './MemberNotes';
import PaymentHistory from './PaymentHistory';
import PrivateRoute from '../PrivateRoute';
import { APPLICATION_BASE_PATH } from '../../constants';
import './NotesView.scss';

const NotesView = (props) => {
  return (
    <Fragment>
      <div className="sub-tab-selector">
        <NavLink exact to={`${props.match.url}`} activeClassName="is-active">
          <Translate value="patient_view.notes.notes_tab" />
        </NavLink>
        <NavLink to={`${props.match.url}/files`} activeClassName="is-active">
          <Translate value="patient_view.notes.documents_tab" />
        </NavLink>
        <NavLink to={`${props.match.url}/payment-history`} activeClassName="is-active">
          <Translate value="payments_view.header" />
        </NavLink>
      </div>

      <PrivateRoute exact path={`${props.match.url}`} component={MemberNotes} />
      <PrivateRoute path={`${props.match.url}/files`} component={MemberFiles} />
      <PrivateRoute
        path={`/${APPLICATION_BASE_PATH}/member/:memberId/notes/payment-history`}
        component={PaymentHistory}
      />
    </Fragment>
  );
};

export default NotesView;
