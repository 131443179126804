import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import sv from 'date-fns/locale/sv';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
  selectNewCheckupCaregiverType,
  showNewCheckupBooking,
  selectNewCheckupCategory,
  selectNewCheckupType,
  setNewCheckupDate,
  postMemberCheckup,
  toggleNewReminderReason,
  setNewReminderReasonFreeText,
  includeFreeTextReason
} from '../../../actions';
import { decamelize } from '../../../utils';
import 'react-datepicker/dist/react-datepicker.css';
import './CheckupBookingSelector.scss';
import LoaderButton from '../../LoaderButton';
import RadioButton from '../../RadioButton';
import { PATIENT_FEE_EXEMPTED_SOURCES } from '../../../constants';
registerLocale('sv', sv);

const CheckupBookingSelector = ({
  checkups,
  member,
  authToken,
  selectNewCheckupCaregiverType,
  showNewCheckupBooking,
  selectNewCheckupCategory,
  selectNewCheckupType,
  setNewCheckupDate,
  postMemberCheckup,
  toggleNewReminderReason,
  setNewReminderReasonFreeText,
  includeFreeTextReason,
  locale
}) => {
  const doctorCheckupTypes = [
    'newMemberCheckup',
    'sixWeekCheckup',
    'threeMonthCheckup',
    'yearlyCheckup',
    'extraCheckup'
  ];

  const nurseCheckupTypes = ['nurseNewMember', 'nurseTwoWeek', 'nurseSixMonth', 'nurseYearly', 'nurseExtra'];

  const saveNewCheckup = (e) => {
    e.preventDefault();

    const newCheckup = {
      type:
        checkups.selectedCheckupType ||
        (checkups.selectedCaregiverBookingType === 'doctor' ? 'reminder' : 'nurseReminder'),
      checkupDate: checkups.newCheckupBookingDate.format('YYYY-MM-DD'),
      checkupReasons: checkups.selectedCheckupCategory.toLowerCase().includes('reminder')
        ? checkups.checkupReasons.filter((reason) => checkups.newReminderReasons.includes(reason.id))
        : undefined
    };

    if (checkups.includeFreeTextReason && checkups.newReminderFreeTextReason.length) {
      newCheckup.checkupReasonFreeText = checkups.newReminderFreeTextReason;
    }

    postMemberCheckup(authToken, member.guid, newCheckup);
  };

  return (
    <div className="checkup-booking-selector">
      {!checkups.isBookingCheckup ? (
        <button className="button is-primary" onClick={showNewCheckupBooking}>
          +&nbsp;
          <Translate value="decision_support_view.checkup_section.new_checkup_booking.add_booking" />
        </button>
      ) : (
        <form>
          <h3>
            <Translate value="decision_support_view.checkup_section.new_checkup_booking.caregiver_type_header" />
          </h3>
          <div className="mb-30">
            <RadioButton
              id="caregiver-type-doctor"
              value="doctor"
              name="caregiver-type"
              onChange={selectNewCheckupCaregiverType}
              labelI18nKey="decision_support_view.checkup_section.checkup_timeline.caregiver_type.doctor"
            />
            <RadioButton
              id="caregiver-type-nurse"
              value="nurse"
              name="caregiver-type"
              onChange={selectNewCheckupCaregiverType}
              labelI18nKey="decision_support_view.checkup_section.checkup_timeline.caregiver_type.nurse"
              className="ml-20"
            />
          </div>
          {checkups.selectedCaregiverBookingType ? (
            <div>
              <h3>
                <Translate value="decision_support_view.checkup_section.new_checkup_booking.checkup_or_reminder" />
              </h3>
              <div className="mb-10">
                <RadioButton
                  id="checkup-category-checkup"
                  value="checkup"
                  name="checkup-category"
                  onChange={selectNewCheckupCategory}
                  checked={checkups.selectedCheckupCategory === 'checkup'}
                  labelI18nKey="decision_support_view.checkup_section.new_checkup_booking.checkup_category.checkup"
                />
              </div>
              {checkups.selectedCheckupCategory === 'checkup' ? (
                <div className="ml-20">
                  <div className="checkup-type-heading">
                    <Translate value="decision_support_view.checkup_section.new_checkup_booking.checkup_type_header" />
                  </div>
                  {(checkups.selectedCaregiverBookingType === 'doctor' ? doctorCheckupTypes : nurseCheckupTypes).map(
                    (checkupType, i) => (
                      <CheckupTypeOption
                        key={i}
                        checkupType={checkupType}
                        onSelectCheckup={selectNewCheckupType}
                        selectedCheckupType={checkups.selectedCheckupType}
                        newCheckupBookingDate={checkups.newCheckupBookingDate}
                        checkupHighlights={checkups.checkupHighlights}
                        selectedCaregiverBookingType={checkups.selectedCaregiverBookingType}
                        paymentExemption={member.paymentExemption}
                        memberSource={member.source}
                        onSelectDate={setNewCheckupDate}
                        locale={locale}
                      />
                    )
                  )}
                </div>
              ) : null}
              <div>
                <RadioButton
                  id="checkup-category-reminder"
                  value="reminder"
                  name="checkup-category"
                  onChange={selectNewCheckupCategory}
                  checked={checkups.selectedCheckupCategory === 'reminder'}
                  labelI18nKey="decision_support_view.checkup_section.new_checkup_booking.checkup_category.reminder"
                />
              </div>
              {checkups.selectedCheckupCategory === 'reminder' ? (
                <div className="ml-20 reminder-selection">
                  <DatePicker
                    customInput={<CustomDateInput />}
                    minDate={moment().toDate()}
                    selected={checkups.newCheckupBookingDate && checkups.newCheckupBookingDate.toDate()}
                    onChange={(date) => setNewCheckupDate(date)}
                    highlightDates={checkups.checkupHighlights}
                    locale={locale}
                    dateFormat="yyyy-MM-dd"
                    popperPlacement="top"
                  />
                  <ReminderReasons
                    reasons={checkups.checkupReasons}
                    newReminderReasons={checkups.newReminderReasons}
                    freeTextChecked={checkups.includeFreeTextReason}
                    onFreeTextCheckedChanged={includeFreeTextReason}
                    onReminderReasonChange={toggleNewReminderReason}
                    freeTextReason={checkups.newReminderFreeTextReason}
                    onFreeTextReasonChanged={setNewReminderReasonFreeText}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="mt-30">
            <LoaderButton
              isLoading={checkups.savingNewCheckup}
              disabled={
                !checkups.newCheckupBookingDate || checkups.savingNewCheckup || !checkups.selectedCheckupCategory
              }
              onClick={saveNewCheckup}
            >
              <Translate value="global.buttons.save" />
            </LoaderButton>
          </div>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  showNewCheckupBooking,
  selectNewCheckupCaregiverType,
  selectNewCheckupCategory,
  selectNewCheckupType,
  setNewCheckupDate,
  postMemberCheckup,
  toggleNewReminderReason,
  setNewReminderReasonFreeText,
  includeFreeTextReason
};

export default connect(mapStateToProps, mapActionsToProps)(CheckupBookingSelector);

const CustomDateInput = ({ value, onClick }) => {
  return (
    <div className="flex align-center">
      <div className="date-input" onClick={onClick}>
        {!value ? (
          <span className="date-input__placeholder">
            <Translate value="decision_support_view.checkup_section.choose_date" />
          </span>
        ) : (
          <span>{value}</span>
        )}
      </div>
    </div>
  );
};

const ReminderReasons = ({
  reasons,
  newReminderReasons,
  freeTextChecked,
  onFreeTextCheckedChanged,
  freeTextReason,
  onFreeTextReasonChanged,
  onReminderReasonChange
}) => {
  return (
    <>
      <div className="checkup-type-heading mt-10">
        <Translate value="decision_support_view.checkup_section.new_checkup_booking.reminder_header" />
      </div>
      {reasons.slice(0, -1).map((reason, i) => (
        <div key={i} className="mb-10 ml-10">
          <input
            type="checkbox"
            value={reason.id}
            id={`checkup-reason-${i}`}
            checked={newReminderReasons.includes(reason.id)}
            onChange={onReminderReasonChange}
          />
          <label htmlFor={`checkup-reason-${i}`}>{reason.leadText}</label>
        </div>
      ))}
      <div className="mb-10 ml-10">
        <input
          type="checkbox"
          id="new-reminder-freetext"
          checked={freeTextChecked}
          onChange={onFreeTextCheckedChanged}
        />
        <label htmlFor="new-reminder-freetext">
          <DebounceInput
            value={freeTextReason}
            onChange={onFreeTextReasonChanged}
            placeholder={I18n.t('global.freetext')}
            maxLength={500}
          />
        </label>
      </div>
    </>
  );
};

const CheckupTypeOption = ({
  checkupType,
  selectedCheckupType,
  checkupHighlights,
  selectedCaregiverBookingType,
  paymentExemption,
  memberSource,
  newCheckupBookingDate,
  onSelectCheckup,
  onSelectDate,
  locale
}) => {
  return (
    <>
      <div className="mb-10 ml-10">
        <RadioButton
          id={`checkup-type-${checkupType}`}
          value={checkupType}
          name="checkup-type"
          onChange={onSelectCheckup}
          checked={selectedCheckupType === checkupType}
          labelI18nKey={`global.checkup_type.${decamelize(checkupType)}`}
        />
      </div>
      {selectedCheckupType === checkupType ? (
        <div className="flex align-center mb-10">
          <DatePicker
            customInput={<CustomDateInput />}
            minDate={moment().toDate()}
            selected={newCheckupBookingDate && newCheckupBookingDate.toDate()}
            onChange={(date) => onSelectDate(date)}
            highlightDates={checkupHighlights}
            locale={locale}
            dateFormat="yyyy-MM-dd"
            popperPlacement="top"
          />
          {selectedCaregiverBookingType === 'doctor' &&
          paymentExemption &&
          !PATIENT_FEE_EXEMPTED_SOURCES.includes(memberSource) &&
          newCheckupBookingDate &&
          newCheckupBookingDate.isAfter(moment(paymentExemption.validUntil)) ? (
            <div className="flex align-center">
              <span className="icons info-warning"></span>
              <div>
                <Translate
                  value="decision_support_view.checkup_section.checkup_timeline.exemption_card_expiration_warning"
                  validUntil={paymentExemption.validUntil}
                />
              </div>
            </div>
          ) : null}
          {selectedCaregiverBookingType === 'doctor' &&
          !paymentExemption &&
          !PATIENT_FEE_EXEMPTED_SOURCES.includes(memberSource) ? (
            <div className="flex align-center">
              <span className="icons info-warning"></span>
              <div>
                <Translate value="decision_support_view.checkup_section.new_checkup_booking.patient_fee_notice" />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
