import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import cross from '../../assets/icons/cross.png';

class Notification extends Component {
  render() {
    const { open, message, duration, onClose, notificationType } = this.props;

    return (
      <span>
        <Snackbar
          open={open}
          autoHideDuration={duration || 5000}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <SnackbarContent
            className={`notification-${notificationType}`}
            aria-describedby="client-snackbar"
            message={message}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                <img src={cross} alt="Close button" className="notification-icon" />
              </IconButton>
            ]}
          />
        </Snackbar>
      </span>
    );
  }
}

export default Notification;
