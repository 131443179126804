import * as types from '../constants/actionTypes';
import moment from 'moment';
import { extractProperties, extractCaregivers, updateObjectInArray } from '../utils';
import { USER_PROPERTY_KEYS, DATE_FORMAT } from '../constants';
import { loadState, saveState } from '../services/localStorage';

const sortByName = (a, b) => {
  const aName = a.member.familyName.toLowerCase();
  const bName = b.member.familyName.toLowerCase();

  if (aName === bName) {
    return 0;
  }

  if (aName < bName) {
    return -1;
  }

  return 1;
};

const sort = (historical, eventType) => (a, b) => {
  let aComparisonValue;
  let bComparisonValue;

  switch (eventType) {
    case 'age':
      aComparisonValue = new Date(a['oldestTimestamp']).getTime();
      bComparisonValue = new Date(b['oldestTimestamp']).getTime();

      if (historical) {
        aComparisonValue = a.events[a.events.length - 1].handledTimestamp;
        bComparisonValue = b.events[b.events.length - 1].handledTimestamp;
      }
      if (aComparisonValue === bComparisonValue) {
        return sortByName(a, b);
      }

      if (aComparisonValue < bComparisonValue) {
        return -1;
      }

      return 1;
    case 'latest':
      aComparisonValue = new Date(a['newestTimestamp']).getTime();
      bComparisonValue = new Date(b['newestTimestamp']).getTime();

      if (historical) {
        aComparisonValue = a.events[a.events.length - 1].handledTimestamp;
        bComparisonValue = b.events[b.events.length - 1].handledTimestamp;
      }

      if (aComparisonValue === bComparisonValue) {
        return sortByName(a, b);
      }

      if (aComparisonValue > bComparisonValue) {
        return -1;
      }

      return 1;
    case 'responsibleDoctor': {
      const aName = a.member.responsibleDoctor
        ? `${a.member.responsibleDoctor.givenName} ${a.member.responsibleDoctor.familyName}`
        : 'öö';
      const bName = b.member.responsibleDoctor
        ? `${b.member.responsibleDoctor.givenName} ${b.member.responsibleDoctor.familyName}`
        : 'öö';

      if (aName === bName) {
        return 0;
      }

      if (aName < bName) {
        return -1;
      }

      return 1;
    }
    default: {
      let aEventTimestamps = a.events
        .filter((e) => e.eventType === eventType)
        .map((e) => moment(e.timestamp, DATE_FORMAT).valueOf());
      let bEventTimestamps = b.events
        .filter((e) => e.eventType === eventType)
        .map((e) => moment(e.timestamp, DATE_FORMAT).valueOf());

      aComparisonValue = Math.max.apply(null, aEventTimestamps);
      bComparisonValue = Math.max.apply(null, bEventTimestamps);
    }
  }

  if (aComparisonValue === bComparisonValue) {
    return sortByName(a, b);
  }

  if (aComparisonValue > bComparisonValue) {
    return -1;
  }

  return 1;
};

const initialState = {
  userEvents: [],
  showOnlyOwnEvents: true,
  hasOwnHandledErrands: false,
  caregiverIsResponsibleFor: [],
  memberEventHistory: [],
  memberActiveEvents: [],
  allEventCount: 0,
  ownEventCount: 0,
  viewingAllEvents: false,
  memberEventListOpen: false,
  handledEventIds: [],
  prioritizedEvents: [],
  totalNumPrioritizedEvents: 0,
  activeConsultations: [],
  totalNumActiveConsultations: 0
};

const sortProperty = loadState('eventsSortProperty');

if (sortProperty) {
  initialState.sortedBy = sortProperty;
}

const getUserEventsFromPaginatedResults = (paginatedUserEvents) => {
  let extractedEvents = [];

  if (paginatedUserEvents && paginatedUserEvents.userEvents.length) {
    extractedEvents = paginatedUserEvents.userEvents
      .map((event) => {
        return { ...event, member: extractProperties(event.member, USER_PROPERTY_KEYS) };
      })
      .map((event) => {
        return { ...event, member: extractCaregivers(event.member) };
      });
  }

  return extractedEvents;
};

const getListOfPatientsCaregiverIsResponsibleFor = (userEvents, caregiverGuid) => {
  return userEvents
    .filter(
      (userEvent) =>
        (userEvent.member.responsibleDoctor && userEvent.member.responsibleDoctor.guid === caregiverGuid) ||
        (userEvent.member.responsibleNurse && userEvent.member.responsibleNurse.guid === caregiverGuid)
    )
    .map((userEvent) => userEvent.member.guid);
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EVENTS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_EVENTS_SUCCESS: {
      const extractedEvents = getUserEventsFromPaginatedResults(action.payload);
      const ownerProperty = action.isNurse ? 'responsibleNurse' : 'responsibleDoctor';

      return {
        ...state,
        userEvents: state.sortedBy
          ? extractedEvents.slice().sort(sort(false, state.sortedBy))
          : extractedEvents.slice().sort(sort(false, 'age')),
        sortedBy: state.sortedBy || 'age',
        allEventCount: extractedEvents.flatMap((event) => event.events).length,
        ownEventCount: extractedEvents
          .filter((event) => event.member[ownerProperty] && event.member[ownerProperty].guid === action.currentUserGuid)
          .flatMap((event) => event.events).length,
        caregiverIsResponsibleFor: getListOfPatientsCaregiverIsResponsibleFor(extractedEvents, action.currentUserGuid),
        isLoading: false,
        viewingAllEvents: action.fetchAllEvents,
        error: undefined
      };
    }
    case types.GET_EVENTS_ERROR:
      return { ...state, error: action.payload, isLoading: false, sortedBy: 'age' };
    case types.SORT_EVENTS:
      saveState(action.sortBy, 'eventsSortProperty');

      return {
        ...state,
        userEvents: state.userEvents ? state.userEvents.slice().sort(sort(false, action.sortBy)) : undefined,
        sortedBy: action.sortBy
      };
    case types.GET_HANDLED_EVENTS_REQUEST:
      return { ...state, handledEventsIsLoading: true };
    case types.GET_HANDLED_EVENTS_SUCCESS: {
      let extractedHandledEvents = [];

      if (action.payload && action.payload.userEvents.length) {
        extractedHandledEvents = action.payload.userEvents
          .map((event) => {
            return { ...event, member: extractProperties(event.member, USER_PROPERTY_KEYS) };
          })
          .map((event) => {
            return { ...event, member: extractCaregivers(event.member) };
          });
      }

      return {
        ...state,
        handledUserEvents: extractedHandledEvents.slice().sort(sort(true, 'latest')),
        hasOwnHandledErrands: extractedHandledEvents.some((userEvent) => {
          return (
            userEvent.member.responsibleDoctor && userEvent.member.responsibleDoctor.guid === action.currentUserGuid
          );
        }),
        caregiverIsResponsibleFor: [
          ...extractedHandledEvents
            .filter(
              (userEvent) =>
                (userEvent.member.responsibleDoctor &&
                  userEvent.member.responsibleDoctor.guid === action.currentUserGuid) ||
                (userEvent.member.responsibleNurse && userEvent.member.responsibleNurse.guid === action.currentUserGuid)
            )
            .map((userEvent) => userEvent.member.guid)
        ],
        handledEventsIsLoading: false,
        handledUserEventsError: undefined
      };
    }
    case types.GET_HANDLED_EVENTS_ERROR:
      return { ...state, handledUserEventsError: action.payload, handledEventsIsLoading: false };
    case types.SCHEDULE_EVENT_REQUEST:
      return { ...state, schedulingEvent: true };
    case types.SCHEDULE_EVENT_SUCCESS:
      return { ...state, schedulingEvent: false, eventSchedulingError: undefined };
    case types.SCHEDULE_EVENT_ERROR:
      return { ...state, schedulingEvent: false, eventSchedulingError: action.payload };
    case types.TOGGLE_SHOW_OWN_EVENTS:
      return { ...state, showOnlyOwnEvents: !state.showOnlyOwnEvents };
    case types.GET_MEMBER_EVENT_HISTORY_REQUEST:
      return { ...state };
    case types.GET_MEMBER_EVENT_HISTORY_SUCCESS:
      return { ...state, memberEventHistory: action.payload.events };
    case types.GET_MEMBER_EVENT_HISTORY_ERROR:
      return { ...state };
    case types.CLEAR_MEMBER_EVENT_HISTORY:
      return { ...state, memberEventHistory: [], memberEventListOpen: false };
    case types.CLEAR_MEMBER_ACTIVE_EVENTS:
      return { ...state, memberActiveEvents: [], memberEventListOpen: false, handledEventIds: [] };
    case types.HANDLE_EVENT_SUCCESS:
      return {
        ...state,
        allEventCount: state.allEventCount - 1,
        ownEventCount: state.ownEventCount - 1,
        handledEventIds: [...state.handledEventIds, action.payload.id]
      };
    case types.TOGGLE_MEMBER_EVENT_LIST: {
      const memberActiveEvents = state.memberActiveEvents;
      if (state.memberEventListOpen) {
        memberActiveEvents.forEach((event) => (event.isUpdated = false));
      }
      return { ...state, memberEventListOpen: !state.memberEventListOpen, memberActiveEvents };
    }
    case types.GET_MEMBER_ACTIVE_EVENTS_REQUEST:
      return { ...state, loadingMemberActiveEvents: true };
    case types.GET_MEMBER_ACTIVE_EVENTS_SUCCESS:
      return {
        ...state,
        loadingMemberActiveEvents: false,
        memberActiveEvents: action.payload.events,
        memberActiveEventsError: undefined
      };
    case types.GET_MEMBER_ACTIVE_EVENTS_ERROR:
      return { ...state, loadingMemberActiveEvents: false, memberActiveEventsError: action.payload };
    case types.GET_PRIORITIZED_EVENTS_REQUEST:
      return { ...state, loadingPrioritizedEvents: true };
    case types.GET_PRIORITIZED_EVENTS_SUCCESS: {
      const prioritizedEvents = getUserEventsFromPaginatedResults(action.payload);
      return {
        ...state,
        loadingPrioritizedEvents: false,
        prioritizedEvents: prioritizedEvents,
        totalNumPrioritizedEvents: action.payload.pagination.totalNumberOfItems,
        caregiverIsResponsibleFor: getListOfPatientsCaregiverIsResponsibleFor(
          [...prioritizedEvents, ...state.userEvents],
          action.currentUserGuid
        ),
        prioritizedEventsError: undefined
      };
    }
    case types.GET_PRIORITIZED_EVENTS_ERROR:
      return { ...state, loadingPrioritizedEvents: false, prioritizedEventsError: action.payload };
    case types.GET_ACTIVE_CONSULTATIONS_REQUEST:
      return { ...state, loadingActiveConsultations: true };
    case types.GET_ACTIVE_CONSULTATIONS_SUCCESS: {
      const activeConsultations = getUserEventsFromPaginatedResults(action.payload);
      return {
        ...state,
        loadingActiveConsultations: false,
        activeConsultations,
        totalNumActiveConsultations: action.payload.pagination.totalNumberOfItems,
        caregiverIsResponsibleFor: getListOfPatientsCaregiverIsResponsibleFor(
          [...activeConsultations, ...state.userEvents],
          action.currentUserGuid
        ),
        activeConsultationsError: undefined
      };
    }
    case types.GET_ACTIVE_CONSULTATIONS_ERROR:
      return { ...state, loadingActiveConsultations: false, activeConsultationsError: action.payload };
    case types.SNOOZE_EVENT_REQUEST:
      return { ...state };
    case types.SNOOZE_EVENT_SUCCESS:
      return {
        ...state,
        memberActiveEvents: updateObjectInArray(state.memberActiveEvents, {
          index: state.memberActiveEvents.findIndex((event) => event.id === action.payload.id),
          item: { ...action.payload, isUpdated: true }
        })
      };
    case types.SNOOZE_EVENT_ERROR:
      return { ...state };
    default:
      return state;
  }
};

export default eventsReducer;
