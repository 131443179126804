import moment from 'moment';
import * as types from '../constants/actionTypes';
import { removeItemFromArray, extractProperties, extractCaregivers } from '../utils';
import { DATE_FORMAT, USER_PROPERTY_KEYS } from '../constants';

const initialState = {
  selfAssigned: [],
  assigningIds: [],
  unassigned: []
};

const sortByCreatedDate = (a, b) => {
  let aCreatedDate = moment(a.createdDate, DATE_FORMAT).valueOf();
  let bCreatedDate = moment(b.createdDate, DATE_FORMAT).valueOf();

  if (aCreatedDate === bCreatedDate) {
    return 0;
  }

  if (aCreatedDate > bCreatedDate) {
    return 1;
  }

  return -1;
};

const newMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NEW_MEMBERS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_NEW_MEMBERS_SUCCESS: {
      const unassigned =
        action.payload &&
        action.payload
          .map((member) => ({ ...member.user, assignmentReadyReason: member.assignmentReadyReason }))
          .map((member) => extractProperties(member, USER_PROPERTY_KEYS))
          .map((member) => extractCaregivers(member));

      const fromPAL = unassigned.filter((member) => !!member.responsibleDoctor).sort(sortByCreatedDate);
      const unassignedWithChatMessage = unassigned
        .filter((member) => member.assignmentReadyReason.includes('chatMessage'))
        .sort(sortByCreatedDate);
      const unassignedWithMeterSent = unassigned
        .filter(
          (member) => member.assignmentReadyReason.includes('meterSent') && member.assignmentReadyReason.length === 1
        )
        .sort(sortByCreatedDate);

      return {
        ...state,
        isLoading: false,
        unassigned: action.isNurse
          ? [...fromPAL, ...unassignedWithChatMessage, ...unassignedWithMeterSent]
          : unassigned.sort(sortByCreatedDate),
        selfAssigned: [],
        error: undefined
      };
    }
    case types.GET_NEW_MEMBERS_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case types.ASSIGN_PAL_REQUEST:
      return { ...state, isAssigning: true, assigningIds: [...state.assigningIds, action.payload] };
    case types.ASSIGN_PAL_SUCCESS:
      return {
        ...state,
        isAssigning: false,
        selfAssigned: [...state.selfAssigned, action.payload.guid],
        assigningIds: removeItemFromArray(state.assigningIds, {
          index: state.assigningIds.indexOf(action.payload.guid)
        }),
        assignError: undefined
      };
    case types.ASSIGN_PAL_ERROR:
      return {
        ...state,
        isAssigning: false,
        assigningIds: [],
        assignError: action.payload
      };
    case types.CLEAR_ASSIGNED_MEMBERS:
      return { ...state, selfAssigned: [] };
    default:
      return state;
  }
};

export default newMembersReducer;
