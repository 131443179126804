import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import useClickOutside from '../../hooks/useClickOutside';
import './DropdownMenu.scss';

const DropdownMenu = ({ actions, userAuthorities = [], icon = 'more', padIcon = false }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const toggle = (e) => {
    e.stopPropagation();
    setDropdownActive(!dropdownActive);
  };
  const ref = useClickOutside(() => setDropdownActive(false));

  return (
    <div ref={ref} className={`dropdown dropdown-menu-actions ${dropdownActive ? 'is-active' : ''}`}>
      <span onClick={toggle} className={`dropdown-trigger icon ${icon} ${padIcon ? 'pad-icon' : ''}`}></span>
      <div className="dropdown-menu">
        <div className="dropdown-content">
          {actions.map((action, i) => {
            return (
              <div
                key={i}
                className={`dropdown-item ${!userAuthorities.includes(action.authority) ? 'disabled' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  action.onClick();
                  setDropdownActive(false);
                }}
              >
                <Translate value={action.i18nKey} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
