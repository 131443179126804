import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { NavLink, withRouter } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'react-tooltip';
import ModalDialog from '../../ModalDialog';
import HasAuthority from '../../HasAuthority';
import CannedResponseSelector from '../CannedResponseSelector';
import { AUTHORITY } from '../../../constants';
import {
  toggleChatModal,
  updateMessageText,
  populateCannedResponse,
  setMessageType,
  toggleChatResponseMenu,
  populateNudgeMessage,
  setInputScroll
} from '../../../actions';
import './ChatFooter.scss';

class ChatFooter extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setNudgeMessage = this.setNudgeMessage.bind(this);
    this.chatMessageUpdated = this.chatMessageUpdated.bind(this);

    this.chatInput = React.createRef();
  }

  componentDidMount() {
    const observer = new MutationObserver(this.chatMessageUpdated);
    const config = { childList: true };
    observer.observe(this.chatInput.current, config);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { message, messageType } = this.props.chat;

    if (message !== '') {
      this.props.onSubmit({ message, messageType });
    }
  }

  setNudgeMessage() {
    this.props.setMessageType('nudge');
    this.props.populateNudgeMessage(
      I18n.t('patient_view.chat.canned_response.nudge', { name: this.props.member.givenName })
    );
  }

  chatMessageUpdated() {
    if (this.props.chat.scrollToBottom && this.chatInput.current.scrollHeight !== this.chatInput.current.offsetHeight) {
      this.chatInput.current.scrollTo({
        top: this.chatInput.current.scrollHeight,
        behavior: 'smooth'
      });
      this.props.setInputScroll(false);
    }
  }

  render() {
    return (
      <div className="chat-footer">
        <ModalDialog
          header={I18n.t('patient_view.chat.chat_modal_header').replace('{0}', this.props.memberName)}
          actionI18nKey="patient_view.chat.send"
          visible={this.props.chat.chatModalActive}
          onClose={this.props.toggleChatModal}
          onActionCompleted={this.handleSubmit}
          actionCompletable={this.props.canSendMessage && this.props.chat.message.length > 0}
          actionCompleting={false}
          size="medium"
          onAuxiliaryAction={this.props.toggleChatModal}
          auxiliaryIcon="minimize"
        >
          <div className="chat-modal-content">
            <textarea value={this.props.chat.message} onChange={this.props.updateMessageText} />
          </div>
        </ModalDialog>
        <form onSubmit={this.handleSubmit}>
          <div className="columns">
            <div className="column is-11 new-message-container">
              <DebounceInput
                element="textarea"
                placeholder={I18n.t('patient_view.chat.chat_message_placeholder')}
                debounceTimeout={200}
                inputRef={this.chatInput}
                value={this.props.chat.message}
                onChange={this.props.updateMessageText}
              />
            </div>
            <div className="vertical-align">
              {this.props.member.serviceStatus !== 'blocked' ? (
                <div className="expand-chat icon-expand" onClick={this.props.toggleChatModal}></div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="columns chat-footer-auxiliary-actions">
              <div className="column vertical-align footer-action">
                <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]}>
                  <CannedResponseSelector
                    isLoading={this.props.chat.loadingChatResponses}
                    responses={this.props.chat.chatResponses}
                    onSelection={this.props.populateCannedResponse}
                    menuOpen={this.props.chat.chatResponseMenuOpen}
                    onToggle={this.props.toggleChatResponseMenu}
                  />
                </HasAuthority>
              </div>
              <div
                className={`column footer-action vertical-align ${
                  this.props.chat.message.length || this.props.chat.nudgeDisabled
                    ? 'opacity-7 cursor-not-allowed'
                    : 'pointer'
                }`}
                onClick={!this.props.chat.nudgeDisabled ? this.setNudgeMessage : undefined}
                data-html={true}
                data-tip={I18n.t('patient_view.chat.nudge_tooltip')}
              >
                <span className="icons nudge"></span>
                <span className="populate-canned-message ml-0">
                  <Translate value="patient_view.chat.nudge" />
                </span>
              </div>
              <div className="column has-text-right">
                <NavLink to={`${this.props.match.url}/notes/files`} className="file-select"></NavLink>
                <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]} disable={true}>
                  <button
                    className="button is-primary"
                    disabled={
                      !this.props.canSendMessage ||
                      this.props.member.serviceStatus === 'blocked' ||
                      (this.props.authorities.includes(AUTHORITY.NURSE) && this.props.member.chatDisabledForNurse) ||
                      !this.props.chat.message.length
                    }
                    title={
                      !this.props.canSendMessage ? I18n.t('patient_view.chat.draft.sending_blocked_active_draft') : ''
                    }
                    type="submit"
                    id="chat-submit"
                  >
                    <Translate value="patient_view.chat.send" />
                  </button>
                </HasAuthority>
                <ReactTooltip visible={true} multiline={true} effect="solid" className="custom-tooltip text-left" />
              </div>
            </div>
          </div>
          {this.props.chat.postMessageError ? (
            <div className="error-message ml-10">
              <Translate
                value={`patient_view.chat.${this.props.chat.isConflict ? 'conflict' : 'generic'}_post_error`}
              />
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    member: state.currentMember,
    chat: state.patientChat,
    locale: state.i18n.locale,
    authorities: state.auth.authorities
  };
};

const mapActionsToProps = {
  toggleChatModal,
  updateMessageText,
  populateCannedResponse,
  setMessageType,
  toggleChatResponseMenu,
  populateNudgeMessage,
  setInputScroll
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(ChatFooter));
