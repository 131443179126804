import React from 'react';
import { configuredMoment as moment, decamelize } from '../../../utils';
import './HistoricalNote.scss';
import { DATE_FORMAT } from '../../../constants';
import { Translate, I18n } from 'react-redux-i18n';

const MINIMUM_NOTE_LENGTH_TO_COLLAPSE = 100;

const HistoricalNote = ({ note, dateTimeFormat, collapsed, setCollapsed, chatMessageId, collapsible }) => {
  const findChatMessage = () => {
    const chatMessageElement = document.getElementById(chatMessageId);
    if (chatMessageElement) {
      chatMessageElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <div className="historical-note">
      <div className="note-content">
        {collapsed && note.text.length > MINIMUM_NOTE_LENGTH_TO_COLLAPSE ? (
          <span data-sensitive>{`${note.text.substring(0, 50)}...`}</span>
        ) : (
          note.text.split('\n').map((item, key) => {
            return (
              <span key={key} data-sensitive>
                {item}
                <br />
              </span>
            );
          })
        )}
        {note.text.length > MINIMUM_NOTE_LENGTH_TO_COLLAPSE && collapsible ? (
          <div className="text-button" onClick={setCollapsed}>
            <Translate value={`patient_view.notes.${collapsed ? 'show' : 'hide'}_full_note`} />
          </div>
        ) : null}
      </div>
      {note.icd ? <div>ICD: {note.icd.join(', ')}</div> : null}
      {note.reason ? (
        <div>
          <Translate value="global.reason" />:{' '}
          <Translate value={`global.checkup_type.${decamelize(note.reason, '_')}`} />
          {note.reason.includes('nurse') ? ` (${I18n.t('global.nurse')})` : null}
        </div>
      ) : null}
      <div>
        <time>{`${moment(note.timestamp, DATE_FORMAT).format(dateTimeFormat)}`}</time>
      </div>
      <div className="historical-note-footer">
        <div>
          {note.author ? `${note.author.givenName} ${note.author.familyName}` : null}
          {note.author.caregiverRole ? (
            <span className={`note-author note-author--${note.author.caregiverRole}`}>
              <Translate value={`global.caregiver_role.${note.author.caregiverRole}`} />
            </span>
          ) : null}
        </div>
        {chatMessageId ? (
          <div>
            <span className="text-button chevron-right" onClick={findChatMessage}>
              <Translate value="patient_view.notes.show_in_chat" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HistoricalNote;
