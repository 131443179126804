import React, { Component } from 'react';
import './ToggleSwitch.scss';

class ToggleSwitch extends Component {
  render() {
    return (
      <span className="toggle-switch">
        <input onChange={this.props.onChange} checked={this.props.checked} type="checkbox" id="switch" />
        <label htmlFor="switch">Label</label>
      </span>
    );
  }
}

export default ToggleSwitch;
