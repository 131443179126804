import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import { getActiveConsultations } from '../../actions';
import { APPLICATION_BASE_PATH, INBOX_VISITED_PATIENTS_KEY } from '../../constants';
import TopBar from '../TopBar';
import CaregiverPriorityList from '../InboxView/CaregiverPriorityList';
import { loadState } from '../../services/localStorage';

const ActiveConsultationsView = ({ authToken, user, events, history, getActiveConsultations }) => {
  useEffect(() => {
    getActiveConsultations(authToken, user.guid);
  }, []);

  const visitedPatients = loadState(INBOX_VISITED_PATIENTS_KEY, true) || {};

  const navigateToMember = (member) => {
    return () => {
      history.push(`/${APPLICATION_BASE_PATH}/member/${member.guid}`);
    };
  };

  return (
    <>
      <TopBar header="active_consultations_view.header" />
      <div className="wrapper">
        <div className="mb-30">
          <Link to={`/${APPLICATION_BASE_PATH}`}>
            <span className="text-button chevron-left">
              <Translate value="global.back_to_inbox" />
            </span>
          </Link>
        </div>
        <CaregiverPriorityList
          onRowClick={navigateToMember}
          items={events.activeConsultations}
          totalNumItems={events.totalNumActiveConsultations}
          isLoading={events.loadingActiveConsultations}
          filterProperties={['standing', 'nurseCheckup', 'titrationComplete']}
          visitedPatients={visitedPatients}
          i18nKey="active_consultations_view"
          showAnimations={false}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    events: state.events
  };
};

const mapActionsToProps = {
  getActiveConsultations
};

export default connect(mapStateToProps, mapActionsToProps)(ActiveConsultationsView);
