import jwtDecode from 'jwt-decode';
import * as types from '../constants/actionTypes';
import { loadSessionState } from '../services/sessionStorage';
import { LOGIN_AUTHORITY, HTTP_STATUS_CODES } from '../constants';
import api from '../api/apiClient';
import { isTokenNearingExpiry } from '../utils';

const initialState = loadSessionState('auth', true) || {};
delete initialState.refreshingToken;
delete initialState.error;
delete initialState.isLoggingIn;

let clientId;
let customClaim = 'https://api.accumbo.se/jwt_claims/client_id';

const authReducer = (
  state = {
    ...initialState,
    redirectToReferrer: true,
    stompClient: null,
    broadcastChannel: null,
    tokenExpirationWarningModalVisible: false
  },
  action
) => {
  if (action && action.type && action.type !== 'LOGIN_ERROR' && action.type.indexOf('_ERROR') > -1) {
    if (action.payload && action.payload.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      if (state.stompClient) {
        state.stompClient.deactivate();
      }

      if (state.broadcastChannel) {
        state.broadcastChannel.close();
      }

      return { ...state, token: {}, redirectToReferrer: true, stompClient: null, broadcastChannel: null };
    }
  }

  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoggingIn: true, refreshingToken: false };
    case types.LOGIN_SUCCESS: {
      const authorities = jwtDecode(action.payload.jwt).rol.split(',');
      clientId = jwtDecode(action.payload.jwt)[customClaim];
      let loginError;

      if (authorities.indexOf(LOGIN_AUTHORITY) === -1) {
        // Create a synthetic "insufficient privileges" error response object if the login authority was not present
        loginError = {
          response: {
            text: '{"code": "4"}'
          }
        };
      }

      api.setClientIdHeader(clientId);

      return {
        ...state,
        isLoggingIn: false,
        redirectToReferrer: true,
        token: action.payload,
        error: loginError,
        refreshingToken: false,
        authorities,
        isNurse: authorities.includes('nurse'),
        clientId
      };
    }
    case types.LOGIN_ERROR:
      return { ...state, isLoggingIn: false, redirectToReferrer: true, error: action.payload, refreshingToken: false };
    case types.LOGOUT:
      if (state.stompClient) {
        state.stompClient.deactivate();
      }

      if (state.broadcastChannel) {
        state.broadcastChannel.close();
      }

      return {
        ...state,
        token: {},
        redirectToReferrer: false,
        stompClient: null,
        broadcastChannel: null,
        tokenExpirationWarningModalVisible: false
      };
    case types.REFRESH_TOKEN_REQUEST:
      return { ...state, refreshingToken: true };
    case types.REFRESH_TOKEN_SUCCESS:
      clientId = jwtDecode(action.payload.jwt)[customClaim];
      api.setClientIdHeader(clientId);
      if (state.stompClient) {
        state.stompClient.connectHeaders.Authorization = `Bearer ${action.payload.jwt}`;
      }
      return { ...state, token: action.payload, refreshingToken: false, clientId };
    case types.ACKNOWLEDGE_RELEASE_NOTES:
      return { ...state, token: { ...state.token, releaseSinceLastLogin: false } };
    case types.ACKNOWLEDGE_LOGIN_ERROR:
      return { ...state, error: undefined };
    case types.SET_STOMP_CLIENT:
      return { ...state, stompClient: action.payload };
    case types.SET_BROADCAST_CHANNEL:
      return { ...state, broadcastChannel: action.payload };
    case types.CHECK_TOKEN_VALIDITY:
      return {
        ...state,
        tokenExpirationWarningModalVisible: isTokenNearingExpiry(state.token, 10)
      };
    case types.MANUAL_TOKEN_REFRESH_REQUEST:
      return { ...state, manuallyRefreshingToken: true };
    case types.MANUAL_TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        manuallyRefreshingToken: false,
        token: action.payload,
        tokenExpirationWarningModalVisible: false
      };
    case types.CAREGIVER_PROFILE_IMAGE_UPDATE_SUCCESS:
      return { ...state, token: { ...state.token, user: { ...state.token.user, profileImage: action.payload } } };
    default:
      return state;
  }
};

export default authReducer;
