import { Translate } from 'react-redux-i18n';

const PatientAlerts = ({ member, allergy, oversensitivity, allergyDssItemComments }) => {
  return (
    <>
      {member.serviceStatus === 'blocked' ? (
        <div className="alert-container">
          <div className="alert-content blocked">
            <Translate value="patient_view.overview.blocked_alert" />
          </div>
        </div>
      ) : null}
      {allergy || oversensitivity || (allergyDssItemComments && allergyDssItemComments.length) ? (
        <div className="alert-container">
          {oversensitivity ? (
            <div className="alert-content" data-sensitive>
              <Translate value="patient_view.overview.oversensitivity_header" />: {oversensitivity}
            </div>
          ) : null}
          {allergy ? (
            <div className="alert-content" data-sensitive>
              <Translate value="patient_view.overview.allergy_header" />: {allergy}
            </div>
          ) : null}
          {allergyDssItemComments && allergyDssItemComments.length ? (
            <div className="alert-content" data-sensitive>
              <Translate value="patient_view.overview.allergy_comments_header" />
              :&nbsp;
              {allergyDssItemComments.map((comment, i, comments) => {
                return <span key={i}>{`${comment}${comments.length > 1 && i < comments.length - 1 ? '; ' : ''}`}</span>;
              })}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default PatientAlerts;
