import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import './LargeInput.scss';

class LargeInput extends Component {
  render() {
    const { name, required, pattern, onChange, value } = this.props;
    return (
      <input
        className="input-large"
        value={value}
        onChange={onChange}
        name={name}
        type="text"
        placeholder={I18n.t(this.props.placeholder)}
        required={required}
        pattern={pattern}
      />
    );
  }
}

export default LargeInput;
