import { Translate } from 'react-redux-i18n';
import {
  SERVICE_STATUS,
  NULL_GUID,
  MANDATORY_QUESTIONS_ONBOARDING_CHAPTER_ID,
  MANDATORY_QUESTIONS_BEFORE_CHECKUP_CHAPTER_ID
} from '../../../constants';
import DecisionSupportSection from '../DecisionSupportSection';
import { groupBy } from '../../../utils';
import './OnboardingStatus.scss';

const OnboardingStatus = ({ member, healthProfile, anamnesisChapters, medications, isLoading }) => {
  // Render nothing while loading because we don't yet know if the component should be visible or not.
  if (isLoading || !healthProfile.profileData.questions.length) {
    return null;
  }

  // This component is not needed when the patient is handled by the doctor.
  if (member.serviceStatus !== SERVICE_STATUS.NURSE) {
    return null;
  }

  const requiredMorningMeasurements = 3;
  const requiredEveningMeasurements = 3;

  const chapterQuestions = {};
  const onboardingChapter = anamnesisChapters.filter(
    (chapter) => chapter.chapterId === MANDATORY_QUESTIONS_ONBOARDING_CHAPTER_ID
  )[0];
  const preCheckupChapter = anamnesisChapters.filter(
    (chapter) => chapter.chapterId === MANDATORY_QUESTIONS_BEFORE_CHECKUP_CHAPTER_ID
  )[0];

  anamnesisChapters.forEach((chapter) => {
    const questions = healthProfile.profileData.questions.filter(
      (question) => question.chapterId === chapter.chapterId
    );
    chapterQuestions[chapter.chapterId] = {
      questions,
      allAnsweredOrSkipped: questions.every(
        (question) => question.userAnswer && (question.userAnswer.answers.length || question.userAnswer.skipped)
      )
    };
  });

  const patientActivation = {
    hasSelectedRegion: member.region && member.region.id !== NULL_GUID,
    hasSelectedArmCircumference: member.armCircumference !== null && member.armCircumference !== 'NA',
    hasAnsweredMandatoryQuestions: onboardingChapter && onboardingChapter.remainingQuestions === 0
  };

  const beforeCheckup = {
    numMorningMeasurements: Math.min(
      requiredMorningMeasurements,
      Object.keys(groupBy(healthProfile.morningMeasurements, 'calendarDate')).length
    ),
    numEveningMeasurements: Math.min(
      requiredEveningMeasurements,
      Object.keys(groupBy(healthProfile.eveningMeasurements, 'calendarDate')).length
    ),
    hasStandingMeasurement: healthProfile.memberMeasurements.some(
      (measurement) => measurement.measurementMode === 'standing'
    ),
    hasAnsweredMandatoryQuestions: preCheckupChapter && preCheckupChapter.remainingQuestions === 0,
    hasAddedMedications:
      medications.unsignedMedications.length > 0 ||
      medications.currentMedications.length > 0 ||
      medications.previousMedications.length > 0 ||
      healthProfile.medicationAnswer === '1'
  };

  // If the patient has completed all actions then this component is no longer needed.
  if (
    Object.values(patientActivation).every((value) => value === true) &&
    beforeCheckup.numMorningMeasurements === requiredMorningMeasurements &&
    beforeCheckup.numEveningMeasurements === requiredEveningMeasurements &&
    beforeCheckup.hasStandingMeasurement &&
    beforeCheckup.hasAnsweredMandatoryQuestions
  ) {
    return null;
  }

  return (
    <DecisionSupportSection headerI18nKey="decision_support_view.onboarding.header" minimizable={false}>
      <div className="columns">
        <div className="column is-4 no-padding">
          <h3>
            <Translate value="decision_support_view.onboarding.patient_activation.header" />
          </h3>
          <ul className="onboarding">
            <li className={patientActivation.hasSelectedRegion ? 'done' : ''}>
              <Translate value="decision_support_view.onboarding.patient_activation.chosen_region" />
            </li>
            <li className={patientActivation.hasSelectedArmCircumference ? 'done' : ''}>
              <Translate value="decision_support_view.onboarding.patient_activation.chosen_arm_circumference" />
              {member.armCircumference === 'NA' ? (
                <span className="error-message">
                  {' ('}
                  <Translate value="decision_support_view.onboarding.patient_activation.no_monitor_fits" />)
                </span>
              ) : null}
            </li>
            <li className={patientActivation.hasAnsweredMandatoryQuestions ? 'done' : ''}>
              <Translate value="decision_support_view.onboarding.answered_mandatory_questions" />
            </li>
          </ul>
        </div>
        <div
          className={`column is-4 no-padding ${
            !Object.values(patientActivation).every((value) => value === true) ? 'opacity-7' : ''
          }`}
        >
          <h3>
            <Translate value="decision_support_view.onboarding.before_doctor_visit.header" />
          </h3>
          <ul className="onboarding">
            <li className={beforeCheckup.numMorningMeasurements === requiredMorningMeasurements ? 'done' : ''}>
              <Translate
                value="decision_support_view.onboarding.before_doctor_visit.morning_measurements"
                numMeasurements={beforeCheckup.numMorningMeasurements}
                requiredMeasurements={requiredMorningMeasurements}
              />
            </li>
            <li className={beforeCheckup.numEveningMeasurements === requiredEveningMeasurements ? 'done' : ''}>
              <Translate
                value="decision_support_view.onboarding.before_doctor_visit.evening_measurements"
                numMeasurements={beforeCheckup.numEveningMeasurements}
                requiredMeasurements={requiredEveningMeasurements}
              />
            </li>
            <li className={beforeCheckup.hasStandingMeasurement ? 'done' : ''}>
              <Translate value="decision_support_view.onboarding.before_doctor_visit.standing_measurement" />
            </li>
            <li className={beforeCheckup.hasAnsweredMandatoryQuestions ? 'done' : ''}>
              <Translate value="decision_support_view.onboarding.answered_mandatory_questions" />
            </li>
            <li className={beforeCheckup.hasAddedMedications ? 'done' : ''}>
              <Translate value="decision_support_view.onboarding.patient_activation.added_medications" />
              {healthProfile.medicationAnswer === '1' && !medications.currentMedications.length ? (
                <span>
                  {' '}
                  (<Translate value="decision_support_view.onboarding.patient_activation.does_not_take_medications" />)
                </span>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    </DecisionSupportSection>
  );
};

export default OnboardingStatus;
