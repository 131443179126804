import moment from 'moment';
import jwtDecode from 'jwt-decode';
import metadata from '../metadata.json';
import { Mixpanel, eventType } from '../utils/mixpanel';
import * as types from '../constants/actionTypes';
import { decamelize, dedupe, extractCaregivers } from '../utils';
import { AUTHORITY, DATE_FORMAT } from '../constants';
import { en } from '../i18n/en';

let activeEvents = [];

const trackTab = {
  notes: false,
  medications: false,
  referrals: false,
  anamnesis: false,
  dss: false
};

let isSigned = false;
let writtenFinalNoteType = 'Remaining patient time';

const trackedActions = {
  [types.SET_CURRENT_MEMBER]: {
    eventType: eventType.viewPatient,
    trackingType: 'time_event'
  },
  [types.SELECT_MEDICATION]: {
    eventType: eventType.signMedication,
    trackingType: 'time_event'
  },
  [types.SIGN_MEDICATION_SUCCESS]: {
    eventType: eventType.signMedication,
    trackingType: 'track'
  },
  [types.UPDATE_MESSAGE_TEXT]: {
    eventType: eventType.writeChatMessage,
    trackingType: 'time_event'
  },
  [types.POST_CHAT_MESSAGE_REQUEST]: {
    eventType: eventType.writeChatMessage,
    trackingType: 'track'
  },
  [types.SHOW_FINAL_NOTE_MODAL]: {
    eventType: eventType.writeFinalNote,
    trackingType: 'time_event'
  },
  [types.SIGN_DSS_SUCCESS]: {
    eventType: eventType.writeFinalNote,
    trackingType: 'track'
  },
  [types.TOGGLE_CHAT_RESPONSE_MENU]: {
    eventType: eventType.selectChatResponse,
    trackingType: 'time_event'
  },
  [types.POPULATE_CANNED_RESPONSE]: {
    eventType: eventType.selectChatResponse,
    trackingType: 'track'
  },
  [types.SET_CHAT_MESSAGE_HIGHLIGHT]: {
    eventType: eventType.selectChatSearchResult,
    trackingType: 'track'
  },
  [types.NOTIFY_CLEAR_DSS]: {
    eventType: eventType.clearDssData,
    trackingType: 'track'
  },
  [types.TOGGLE_MEMBER_COMMENT_FIELD]: {
    eventType: eventType.viewPatientComment,
    trackingType: 'track'
  },
  [types.SAVE_MEMBER_COMMENT_SUCCESS]: {
    eventType: eventType.updatePatientComment,
    trackingType: 'track'
  }
};

const trackingMiddleware = (store) => (next) => (action) => {
  if (trackedActions[action.type]) {
    const trackedAction = trackedActions[action.type];
    if (action.type === types.UPDATE_MESSAGE_TEXT) {
      if (!store.getState().patientChat.message.length) {
        Mixpanel[trackedAction.trackingType](trackedAction.eventType);
      }
    } else if (action.type === types.CLEAR_PATIENT_DATA) {
      Mixpanel[trackedAction.trackingType](trackedAction.eventType, { activeEvents });
      activeEvents = [];
    } else {
      Mixpanel[trackedAction.trackingType](trackedAction.eventType);
    }
  }

  if (action.type === types.ASSIGN_PAL_SUCCESS) {
    const member = extractCaregivers(action.payload);
    const createdDate = moment(member.createdDate, DATE_FORMAT);

    Mixpanel.track(eventType.assignCaregiver, {
      waitingTimeInMinutes: moment().diff(createdDate, 'minutes'),
      caregiverType: action.isNurse ? (member.responsibleDoctor ? 'PAS (from PAL)' : 'PAS') : 'PAL'
    });
  }

  if (action.type === types.SET_CURRENT_MEMBER) {
    Mixpanel.time_event(eventType.dss);
    trackTab.dss = true;

    Mixpanel.time_event(eventType.patientVisit);
  }

  if (action.type === types.CHANGE_TAB) {
    Object.getOwnPropertyNames(trackTab).forEach((tab) => {
      if (trackTab[tab]) {
        Mixpanel.track(eventType[tab]);
        trackTab[tab] = false;
      }
    });

    const tabName = action.payload.length ? action.payload : 'dss';

    if (!trackTab[tabName]) {
      Mixpanel.time_event(eventType[tabName]);
      trackTab[tabName] = true;
    }
  }

  if (action.type === types.CLEAR_PATIENT_DATA) {
    Object.getOwnPropertyNames(trackTab).forEach((tab) => {
      if (trackTab[tab]) {
        Mixpanel.track(eventType[tab]);
        trackTab[tab] = false;
      }
    });

    if (isSigned) {
      Mixpanel.track(eventType.lagAfterSigning);
      isSigned = false;
    }

    Mixpanel.track(eventType.viewPatient, { writtenFinalNote: writtenFinalNoteType });

    writtenFinalNoteType = 'Remaining patient time';
  }

  if (action.type === types.GET_MEMBER_ACTIVE_EVENTS_SUCCESS) {
    activeEvents = dedupe(action.payload.events.map((event) => event.eventType));
  }

  if (
    action.type === types.SET_CURRENT_MEMBER &&
    action.payload.user &&
    action.payload.user.createdDate &&
    !action.payload.user.responsibleDoctor
  ) {
    Mixpanel.track(eventType.viewUnassignedPatient);
  }

  if (action.type === types.LOGIN_SUCCESS) {
    Mixpanel.set('$name', `${action.payload.user.givenName} ${action.payload.user.familyName}`);
    Mixpanel.identify(action.payload.user.email);
    Mixpanel.set('$email', action.payload.user.email);
    Mixpanel.identify();

    const authorities = jwtDecode(action.payload.jwt).rol.split(',');
    const role = authorities.includes(AUTHORITY.CAREGIVER)
      ? 'Caregiver'
      : authorities.includes(AUTHORITY.NURSE)
      ? 'Nurse'
      : 'Health scientist';
    Mixpanel.set('Role', role);
    Mixpanel.identify();
  }

  if (action.type === types.LOGOUT) {
    Mixpanel.reset();
  }

  if (action.type === types.LOGIN_ERROR) {
    Mixpanel.track(eventType.loginFailure, {
      errorCode: action.payload.response && action.payload.response.body && action.payload.response.body.code
    });
  }

  if (action.type === types.ADD_NOTE_SUCCESS && action.payload.noteType === 'finalnote') {
    let visitType = action.payload.reason;
    visitType = decamelize(visitType, ' ');
    visitType = visitType[0].toUpperCase() + visitType.slice(1);
    Mixpanel.track(eventType.patientVisit, { visitType });
    writtenFinalNoteType = visitType;
  }

  if (action.type === types.SIGN_DSS_SUCCESS) {
    Mixpanel.time_event(eventType.lagAfterSigning);
    isSigned = true;
  }

  if (action.type === types.GET_EVENTS_SUCCESS) {
    Mixpanel.set('Version', metadata.version);
    Mixpanel.identify();

    Mixpanel.track(eventType.fetchErrands, { Amount: action.fetchAllEvents ? 'All' : 'Own' });
  }

  if (action.type === types.DISABLE_INSTANCE) {
    Mixpanel.track(eventType.tabDisabled, { reason: action.payload });
  }

  if (action.type === types.POST_CHAT_MESSAGE_SUCCESS) {
    if (action.responseType) {
      const trackingObject = {
        messageType: action.responseType[0].toUpperCase() + action.responseType.slice(1) + ' message',
        messageLength: action.payload.text.length,
        isEditedDraft: action.hasEditedDraft
      };

      Mixpanel.track(eventType.chatMessageType, trackingObject);
    }
  }

  if (action.type === types.HANDLE_EVENT_SUCCESS) {
    const eventCreationDate = moment(action.payload.timestamp, DATE_FORMAT);
    Mixpanel.track(eventType.handleErrand, {
      waitingTimeInMinutes: moment(action.payload.handledTimestamp, DATE_FORMAT).diff(eventCreationDate, 'minutes'),
      eventType: en.global.event_type[action.payload.eventType]
    });
  }

  if (action.type === types.SORT_EVENTS) {
    Mixpanel.track(eventType.sortErrands, {
      sortBy: action.sortBy
    });
  }

  if (action.type === types.SNOOZE_EVENT_SUCCESS) {
    Mixpanel.track(eventType.snoozeErrand, {
      eventType: action.payload.eventType,
      snoozeDuration: moment(action.payload.snoozedUntilDate).diff(moment(), 'd') + 1
    });
  }

  if (action.type === types.CAREGIVER_PROFILE_IMAGE_UPDATE_SUCCESS) {
    Mixpanel.track(eventType.uploadProfileImage, {
      imageType: action.isWebcamCapture ? 'webcamCapture' : 'imageFile'
    });
  }

  if (action.type === types.TOGGLE_CHAT_MESSAGE_FILTER) {
    const activeFilters = store.getState().patientChat.messageFilters;
    Mixpanel.track(eventType.filterChat, {
      messageType: `${action.payload} ${activeFilters.includes(action.payload) ? 'on' : 'off'}`
    });
  }

  return next(action);
};

export default trackingMiddleware;
