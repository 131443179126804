import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Translate, I18n } from 'react-redux-i18n';
import { AUTHORITY, DATE_FORMAT } from '../../../constants';
import { decamelize } from '../../../utils';
import ModalDialog from '../../ModalDialog';
import DecisionSupportTable from '../DecisionSupportTable';
import CheckboxRow from '../DecisionSupportTable/RowTypes/CheckboxRow';
import HasAuthority from '../../HasAuthority';
import {
  showCancellationReasonModal,
  hideCancellationReasonModal,
  deleteCheckup,
  showEditCheckupModal,
  hideEditCheckupModal,
  setEditedCheckupDate,
  editCheckup,
  setCheckupReasonFreeText,
  toggleEditedCheckupReason
} from '../../../actions';
import 'react-datepicker/dist/react-datepicker.css';
import './CheckupTimeline.scss';
registerLocale('sv', sv);

const isReminder = (checkup) => {
  return checkup.type.toLowerCase().includes('reminder');
};

const warnAboutExpiredExemptionCard = (checkup, newCheckupDate, member) => {
  if (!member.paymentExemption) {
    return false;
  }

  if (isReminder(checkup)) {
    return false;
  }

  if (checkup.type.includes('nurse')) {
    return false;
  }

  if (moment(checkup.checkupDate).isAfter(moment(member.paymentExemption.validUntil))) {
    return false;
  }

  if (newCheckupDate.isSameOrBefore(moment(member.paymentExemption.validUntil))) {
    return false;
  }

  return true;
};

const CheckupTimeline = ({
  checkups,
  member,
  authToken,
  showCancellationReasonModal,
  hideCancellationReasonModal,
  deleteCheckup,
  showEditCheckupModal,
  hideEditCheckupModal,
  setEditedCheckupDate,
  editCheckup,
  setCheckupReasonFreeText,
  toggleEditedCheckupReason,
  locale
}) => {
  const [fullHistoryVisible, setFullHistoryVisible] = useState(false);
  const [cancellationReasonInput, setCancellationReasonInput] = useState('');
  const [showPastReminders, setShowPastReminders] = useState(false);

  const PATIENT_NOTIFICATION_THRESHOLD_IN_DAYS = 15;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const pastCheckups = checkups.checkupList.filter((checkup) => {
    if (!showPastReminders) {
      return moment(checkup.checkupDate, DATE_FORMAT).isBefore(moment(), 'day') && !isReminder(checkup);
    }

    return moment(checkup.checkupDate, DATE_FORMAT).isBefore(moment(), 'day');
  });
  const futureCheckups = checkups.checkupList.filter((checkup) =>
    moment(checkup.checkupDate, DATE_FORMAT).isSameOrAfter(moment(), 'day')
  );
  const pastCheckupIds = pastCheckups.map((checkup) => checkup.id);
  const futureCheckupIds = futureCheckups.map((checkup) => checkup.id);
  const visiblePastCheckups = fullHistoryVisible ? pastCheckups : pastCheckups.slice(pastCheckups.length - 1);

  const deleteReminder = (checkup) => {
    if (window.confirm(I18n.t('decision_support_view.checkup_section.checkup_timeline.confirm_delete_reminder'))) {
      deleteCheckup(authToken, member.guid, checkup);
    }
  };

  const removeCheckup = () => {
    deleteCheckup(authToken, member.guid, checkups.selectedCheckup, cancellationReasonInput, () => {
      setCancellationReasonInput('');
    });
  };

  const saveEditedCheckup = () => {
    const editedCheckup = { ...checkups.selectedCheckup };
    editedCheckup.checkupDate = checkups.selectedCheckupDate.format('YYYY-MM-DD');

    if (isReminder(editedCheckup)) {
      editedCheckup.checkupReasons = checkups.checkupReasons.filter((reason) =>
        checkups.selectedCheckupReasons.includes(reason.id)
      );
      editedCheckup.checkupReasonFreeText = checkups.freeTextReason;
    }

    editCheckup(authToken, member.guid, editedCheckup);
  };

  const toggleCheckupReason = (checkupReasonId) => {
    return () => {
      if (!checkupReasonId) return;
      toggleEditedCheckupReason(checkupReasonId);
    };
  };

  return (
    <>
      <h2 className="mb-15">
        <Translate value="decision_support_view.checkup_section.checkup_timeline.header" />
      </h2>

      <div className="checkup-timeline-wrapper">
        {!pastCheckups.length && !futureCheckups.length ? (
          <Translate value="decision_support_view.checkup_section.checkup_timeline.empty" />
        ) : null}
        <div className="checkup-timeline">
          {checkups.hasPastCheckups ? (
            <div className="text-center">
              <span
                className={`text-button chevron ${fullHistoryVisible ? 'down' : 'up'}`}
                onClick={() => setFullHistoryVisible(!fullHistoryVisible)}
              >
                <Translate value={`decision_support_view.${fullHistoryVisible ? 'hide' : 'show'}_history`} />
              </span>
            </div>
          ) : null}
          {fullHistoryVisible && checkups.hasPastReminders ? (
            <div className="reminder-control">
              <input
                type="checkbox"
                id="show-reminders"
                checked={showPastReminders}
                onChange={() => setShowPastReminders(!showPastReminders)}
              />
              <label htmlFor="show-reminders">Visa påminnelser</label>
            </div>
          ) : null}

          {[...visiblePastCheckups, ...futureCheckups].map((checkup, i, array) => (
            <CheckupRow
              key={checkup.id}
              checkup={checkup}
              nextCheckupDate={i < array.length - 1 ? array[i + 1].checkupDate : undefined}
              completed={pastCheckupIds.includes(checkup.id)}
              isCancelling={checkups.deletingIds.includes(checkup.id)}
              onEdit={futureCheckupIds.includes(checkup.id) ? () => showEditCheckupModal(checkup) : undefined}
              onDelete={
                futureCheckupIds.includes(checkup.id)
                  ? !isReminder(checkup)
                    ? () => showCancellationReasonModal(checkup)
                    : () => deleteReminder(checkup)
                  : undefined
              }
            />
          ))}
        </div>
        <TreatmentYearOverview />
      </div>
      <ReactTooltip multiline={true} effect="solid" className="custom-tooltip" />
      <ModalDialog
        headerI18nKey="decision_support_view.checkup_section.checkup_timeline.delete_header"
        actionI18nKey="global.buttons.delete"
        visible={checkups.cancellationReasonModalVisible}
        onClose={hideCancellationReasonModal}
        onActionCompleted={removeCheckup}
        actionCompletable={cancellationReasonInput.length > 0}
        actionCompleting={checkups.deletingIds.includes(checkups.selectedCheckup.id)}
      >
        <div className="fw-input">
          <input
            type="text"
            maxLength={200}
            value={cancellationReasonInput}
            onChange={(e) => setCancellationReasonInput(e.target.value)}
          />
        </div>
      </ModalDialog>
      <ModalDialog
        headerI18nKey={`decision_support_view.notes_section.${
          isReminder(checkups.selectedCheckup) ? 'edit_checkup' : 'edit_checkup_date'
        }`}
        actionI18nKey={`decision_support_view.notes_section.${
          isReminder(checkups.selectedCheckup) ? 'save_checkup' : 'save_new_checkup_date'
        }`}
        visible={checkups.editCheckupModalVisible}
        onClose={hideEditCheckupModal}
        onActionCompleted={saveEditedCheckup}
        actionCompletable={true}
        actionCompleting={checkups.isSavingEditedCheckup}
        size={!isReminder(checkups.selectedCheckup) ? 'small' : undefined}
      >
        <div>
          <div className="text-center mb-20">
            <DatePicker
              inline
              minDate={moment().toDate()}
              selected={checkups.selectedCheckupDate.toDate()}
              onChange={(date) => setEditedCheckupDate(date)}
              highlightDates={checkups.checkupHighlights}
              locale={locale}
            />
          </div>
          {(checkups.selectedCheckup.type === 'threeMonthCheckup' ||
            checkups.selectedCheckup.type === 'yearlyCheckup') &&
          moment(checkups.selectedCheckup.checkupDate, DATE_FORMAT).isBefore(
            moment().add(PATIENT_NOTIFICATION_THRESHOLD_IN_DAYS, 'days')
          ) ? (
            <div className="flex align-center">
              <span className="icons info-warning"></span>
              <div>
                <Translate
                  value="decision_support_view.checkup_section.checkup_timeline.patient_checkup_notification_warning"
                  checkupDate={moment(checkups.selectedCheckup.checkupDate, DATE_FORMAT).format('YYYY-MM-DD')}
                />
              </div>
            </div>
          ) : null}
          {warnAboutExpiredExemptionCard(checkups.selectedCheckup, checkups.selectedCheckupDate, member) ? (
            <div className="flex align-center">
              <span className="icons info-warning"></span>
              <div>
                <Translate
                  value="decision_support_view.checkup_section.checkup_timeline.exemption_card_expiration_warning"
                  validUntil={member.paymentExemption.validUntil}
                />
              </div>
            </div>
          ) : null}
          {isReminder(checkups.selectedCheckup) ? (
            <DecisionSupportTable
              data={checkups.checkupReasons}
              error={null}
              errorTitleI18n="decision_support_view.checkup_section.error"
              titleI18n="decision_support_view.checkup_section.checkup_reason"
              emptyI18n="decision_support_view.checkup_section.checkup_reasons_empty"
              rowType={CheckboxRow}
              onCheckboxChange={toggleCheckupReason}
              selectedCheckboxes={checkups.selectedCheckupReasons}
              scopeName="checkup-timeline"
              freeTextCheckboxOption={checkups.freeTextReason}
              onFreeTextChanged={setCheckupReasonFreeText}
            />
          ) : null}
        </div>
      </ModalDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  showCancellationReasonModal,
  hideCancellationReasonModal,
  deleteCheckup,
  showEditCheckupModal,
  hideEditCheckupModal,
  setEditedCheckupDate,
  editCheckup,
  setCheckupReasonFreeText,
  toggleEditedCheckupReason
};

export default connect(mapStateToProps, mapActionsToProps)(CheckupTimeline);

const CheckupRow = ({ checkup, nextCheckupDate, completed, onDelete, isCancelling, onEdit }) => {
  const caregiverType = checkup.type.includes('nurse') ? 'nurse' : 'doctor';
  const NEAR_THRESHOLD = 30;
  let daysToNextCheckup = 1;

  if (nextCheckupDate) {
    daysToNextCheckup = moment(nextCheckupDate, DATE_FORMAT).diff(moment(checkup.checkupDate, DATE_FORMAT), 'days');
  }

  const checkupClasses = classNames({
    checkup,
    completed,
    [`${caregiverType}-checkup`]: true,
    near: daysToNextCheckup <= NEAR_THRESHOLD,
    cancelled: !!checkup.cancellationReason,
    new: checkup.new
  });

  return (
    <div className={checkupClasses} key={checkup.id}>
      <div className="checkup-date">{moment(checkup.checkupDate, DATE_FORMAT).format('D MMMM yyyy')}</div>
      <div className={`flex ${isReminder(checkup) && isCancelling ? 'opacity-6' : ''}`}>
        <div className="checkup-row-wrapper">
          <div>
            <Translate value={`global.checkup_type.${decamelize(checkup.type)}`} />,{' '}
            <span className="caregiver-type">
              <Translate
                value={`decision_support_view.checkup_section.checkup_timeline.caregiver_type.${caregiverType}`}
              />
            </span>
          </div>
          <ReminderReasonsIcon checkup={checkup} />
          <ChangeDateButton checkup={checkup} onClick={onEdit} />
        </div>
        <CancelCheckupButton checkup={checkup} onClick={onDelete} isCancelling={isCancelling} />
      </div>
      <CancellationReason checkup={checkup} />
    </div>
  );
};

const ChangeDateButton = ({ checkup, onClick }) => {
  if (checkup.cancellationReason || moment(checkup.checkupDate, DATE_FORMAT).isBefore(moment(), 'day')) {
    return null;
  }

  return (
    <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]}>
      <div>
        <span className="text-button" onClick={onClick}>
          <Translate
            value={`decision_support_view.checkup_section.checkup_timeline.edit_${
              isReminder(checkup) ? 'reminder' : 'checkup'
            }`}
          />
        </span>
      </div>
    </HasAuthority>
  );
};

const CancelCheckupButton = ({ checkup, onClick }) => {
  if (checkup.cancellationReason || moment(checkup.checkupDate, DATE_FORMAT).isBefore(moment(), 'day')) {
    return null;
  }

  return (
    <HasAuthority authority={[AUTHORITY.CAREGIVER, AUTHORITY.NURSE]}>
      <div>
        <button className="button is-danger" onClick={onClick}>
          <Translate value="global.buttons.delete" />
        </button>
      </div>
    </HasAuthority>
  );
};

const CancellationReason = ({ checkup }) => {
  if (!checkup.cancellationReason) {
    return null;
  }

  return <div className="checkup-info">{checkup.cancellationReason}</div>;
};

const ReminderReasonsIcon = ({ checkup }) => {
  if (!isReminder(checkup)) {
    return null;
  }

  let reminderTooltip;

  if (checkup.checkupReasons && checkup.checkupReasons.length) {
    reminderTooltip = checkup.checkupReasons.map((r) => r.leadText).join('<br />');
  }

  if (checkup.checkupReasonFreeText) {
    reminderTooltip
      ? (reminderTooltip += '<br />' + checkup.checkupReasonFreeText)
      : (reminderTooltip = checkup.checkupReasonFreeText);
  }

  if (!reminderTooltip) {
    return null;
  }

  return <span className="icons info" data-tip={reminderTooltip}></span>;
};

const TreatmentYearOverview = () => {
  return (
    <div className="treatment-year-overview-wrapper">
      <h2>
        <Translate value="decision_support_view.checkup_section.treatment_year_overview.header" />
      </h2>
      <div className="treatment-year-overview">
        <div>
          <h3>
            <Translate value="decision_support_view.checkup_section.treatment_year_overview.year_1.header" />
          </h3>
          <ul>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
              <li key={i}>
                <Translate
                  value={`decision_support_view.checkup_section.treatment_year_overview.year_1.checkup_${i}`}
                  dangerousHTML
                />
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3>
            <Translate value="decision_support_view.checkup_section.treatment_year_overview.year_2.header" />
          </h3>
          <ul className="mb-0">
            {[0, 1, 2, 3, 4, 5].map((i) => (
              <li key={i}>
                <Translate
                  value={`decision_support_view.checkup_section.treatment_year_overview.year_2.checkup_${i}`}
                  dangerousHTML
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
