import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import LoadingSpinner from '../LoadingSpinner';
import './Referrals.scss';
import ModalDialog from '../ModalDialog';
import {
  ENCIA_REGIONS,
  UNILABS_REGIONS,
  REGION_STOCKHOLM,
  REGION_SKANE,
  REGION_JONKOPING,
  REGION_LINKOPING,
  REGION_HUDIKSVALL,
  REGION_OSTERSUND,
  REGION_KUNGSBACKA,
  REGION_RATTVIK,
  REGION_VAXJO,
  REGION_UMEA,
  REGION_SE,
  REGION_KIRUNA
} from '../../constants';

const Referrals = ({ referrals, member }) => {
  const [referralInfoModalVisible, setReferralInfoModalVisible] = useState(false);

  const getRegionReminderI18nKey = (region) => {
    if (ENCIA_REGIONS.includes(region.id)) {
      return 'patient_view.referrals.region.encia';
    }

    if (UNILABS_REGIONS.includes(region.id)) {
      return 'patient_view.referrals.region.unilabs';
    }

    if (region.id === REGION_STOCKHOLM) {
      return 'patient_view.referrals.region.stockholm';
    }

    if (region.id === REGION_SKANE) {
      return 'patient_view.referrals.region.skane';
    }

    if (region.id === REGION_JONKOPING) {
      return 'patient_view.referrals.region.jonkoping';
    }

    if (region.id === REGION_LINKOPING) {
      return 'patient_view.referrals.region.linkoping';
    }

    if (region.id === REGION_HUDIKSVALL) {
      return 'patient_view.referrals.region.hudiksvall';
    }

    if (region.id === REGION_OSTERSUND) {
      return 'patient_view.referrals.region.ostersund';
    }

    if (region.id === REGION_KUNGSBACKA) {
      return 'patient_view.referrals.region.kungsbacka';
    }

    if (region.id === REGION_RATTVIK) {
      return 'patient_view.referrals.region.rattvik';
    }

    if (region.id === REGION_VAXJO) {
      return 'patient_view.referrals.region.vaxjo';
    }

    if (region.id === REGION_UMEA) {
      return 'patient_view.referrals.region.umea';
    }

    if (region.id === REGION_SE) {
      return 'patient_view.referrals.region.sweden';
    }

    if (region.id === REGION_KIRUNA) {
      return 'patient_view.referrals.region.kiruna';
    }
  };

  return (
    <>
      <div className="member-referrals-container">
        {referrals.isLoading ? (
          <LoadingSpinner size="small" />
        ) : (
          <section>
            {member && member.region && getRegionReminderI18nKey(member.region) ? (
              <div className="medium-alert mb-10">
                <div>
                  <Translate
                    value={getRegionReminderI18nKey(member.region)}
                    regionName={member.region.name}
                    dangerousHTML
                  />
                </div>
                <span className="text-button" onClick={() => setReferralInfoModalVisible(true)}>
                  <Translate value="patient_view.referrals.show_info" />
                </span>
              </div>
            ) : null}
            {referrals && !referrals.error && referrals.referralSession && referrals.referralSession.redirectUrl ? (
              <iframe
                src={referrals.referralSession.redirectUrl}
                frameBorder="0"
                title="Info Solutions"
                id="info-solutions-iframe"
              ></iframe>
            ) : (
              <div className="error-message">
                <Translate value="patient_view.referrals.error" />
              </div>
            )}
          </section>
        )}
      </div>
      <ModalDialog
        headerI18nKey="patient_view.referrals.modal_header"
        actionI18nKey="global.buttons.ok"
        visible={referralInfoModalVisible}
        onClose={() => setReferralInfoModalVisible(false)}
        onActionCompleted={() => setReferralInfoModalVisible(false)}
        actionCompletable={true}
        size="wide"
      >
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.unilabs_stockholm" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.unilabs_stockholm_body" />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.unilabs_goteborg" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.unilabs_goteborg_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.encia_aleris" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.encia_aleris_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.skane" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.skane_body" dangerousHTML />
          </div>
        </div>

        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.wetterhalsans" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.wetterhalsans_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.cordinator" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.cordinator_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.uddevalla" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.uddevalla_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.hudiksvall" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.hudiksvall_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.ostersund" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.ostersund_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.kungsbacka" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.kungsbacka_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.rattvik" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.rattvik_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.vaxjo" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.vaxjo_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.umea" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.umea_body" dangerousHTML />
          </div>
        </div>
        <div className="mb-10">
          <h3>
            <Translate value="patient_view.referrals.kiruna" />
          </h3>
          <div>
            <Translate value="patient_view.referrals.kiruna_body" dangerousHTML />
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    referrals: state.referrals,
    member: state.currentMember
  };
};

export default connect(mapStateToProps)(Referrals);
