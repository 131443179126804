import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import './styles/index.scss';
import Root from './Root';
import { Provider } from 'react-redux';
import { saveState } from './services/localStorage';
import { saveSessionState } from './services/sessionStorage';
import configureStore from './store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import metadata from './metadata.json';
import { CURRENT_ENV } from './constants';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;

Sentry.init({
  dsn: 'https://966f059009f74e689c93373906393765@o557843.ingest.sentry.io/5690623',
  release: 'accumbo-clinic@' + metadata.version,
  environment: env,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: env === 'production' ? 0.01 : 0.1,
  normalizeDepth: 2
});

const store = configureStore();

let currentLocale;
let currentAuthToken = {};
let currentProfileImage;
store.subscribe(() => {
  const locale = store.getState().i18n.locale;
  const auth = { ...store.getState().auth };

  if (
    auth.token &&
    (currentAuthToken.jwt !== auth.token.jwt ||
      currentAuthToken.releaseSinceLastLogin !== auth.token.releaseSinceLastLogin ||
      currentProfileImage?.id !== auth.token?.user?.profileImage?.id)
  ) {
    // Delete stuff that shouldn't or can't be serialized
    delete auth.stompClient;
    delete auth.broadcastChannel;

    saveSessionState(auth, 'auth');
    currentAuthToken = auth.token;
    currentProfileImage = auth.token?.user?.profileImage;
  }

  if (currentLocale !== locale) {
    saveState(locale, 'locale');
    moment.locale(locale);
    currentLocale = locale;
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
);
