import { CURRENT_ENV } from '../constants';

const apiFactory = require('accumbo-js-client');
const uuidv4 = require('uuid/v4');
const metadata = require('../metadata.json');
const superagent = require('superagent');

const client = new apiFactory.ApiClient();
const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');
client.basePath = basePath;

const userAgent = navigator.userAgent;
let accumboClientId;

function setClientIdHeader(clientId) {
  if (clientId) {
    accumboClientId = clientId;
    client.defaultHeaders = {
      'Accumbo-Client-ID': clientId,
      'x-accumbo-api-version': '1.3.29'
    };
  }
}

function healthPing() {
  return superagent.get(`${basePath}/healthping`);
}

function logInTest(data) {
  const authApi = new apiFactory.AuthorizationApi(client);
  const loginRequest = new apiFactory.LoginRequest.constructFromObject(data);
  const opts = {};

  return authApi.loginUsingPOST(userAgent, loginRequest, opts);
}

function getHealthProfile(jwtToken, guid) {
  const memberHealtProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealtProfileApi.getHealthProfileUsingGET(userAgent, guid, opts);
}

function getMemberDevices(jwtToken, guid) {
  const memberMobileDevicesApi = new apiFactory.MemberMobileDevicesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const deviceId = '00000000-0000-0000-0000-000000000000'; // Only ID supported before multi device support

  return memberMobileDevicesApi.getMobileDeviceUsingGET(userAgent, guid, deviceId, opts);
}

function updateMember(jwtToken, memberGuid, updatedMemberFields) {
  const memberUserProfileApi = new apiFactory.MemberUserProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberUserProfileApi.updateMemberUsingPATCH(userAgent, memberGuid, updatedMemberFields, opts);
}

function getHealthProfileChapters(jwtToken, guid) {
  const memberHealtProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealtProfileApi.getHealthProfileChaptersUsingGET(userAgent, guid, opts);
}

function getMembersList(jwtToken, offset, limit, query, sort = 'familyName:asc,givenName:asc', extraFields) {
  const membersListApi = new apiFactory.ListMembersApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    limit,
    offset,
    q: query,
    sort
  };

  if (extraFields) {
    opts.extraFields = extraFields.join(',');
  }

  return membersListApi.getMembersUsingGET(userAgent, opts);
}

function getNewMembers(jwtToken) {
  const newMembersApi = new apiFactory.UnassignedApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return newMembersApi.getUnassignedMembersUsingGET(userAgent, opts);
}

function getAnamnesisUpdateSummary(jwtToken, memberGuid) {
  const memberActionApi = new apiFactory.MemberActionApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberActionApi.getCheckupAnswersUsingGETWithHttpInfo(userAgent, memberGuid, opts);
}

function assignResponsibleDoctor(jwtToken, memberGuid) {
  const assignPalApi = new apiFactory.AssignResponsiblePalApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return assignPalApi.assignResponsibleDoctorUsingPOST(userAgent, memberGuid, opts);
}

function challenge(identifier) {
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const challengeObj = {
    identifier: identifier,
    method: 'bankid'
  };
  const challengeRequest = new apiFactory.ChallengeRequest.constructFromObject(challengeObj);
  const opts = {};

  return authorizationApi.challengeUsingPOST(userAgent, challengeRequest, opts);
}

function login(refToken, identifier) {
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const loginObj = {
    role: 'caregiver',
    method: 'bankid',
    identifier
  };

  const opts = {
    authorization: `Token ${refToken}`
  };

  const loginRequest = new apiFactory.LoginRequest.constructFromObject(loginObj);

  return authorizationApi.loginUsingPOST(userAgent, loginRequest, opts);
}

function refreshToken(jwtToken, requestId) {
  console.log('refreshing token');
  const authorizationApi = new apiFactory.AuthorizationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    requestId
  };

  return authorizationApi.loginUsingGET(userAgent, opts);
}

function getChatMessages(jwtToken, memberGuid) {
  const memberChatApi = new apiFactory.MemberChatApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberChatApi.getChatMessagesUsingGET(userAgent, memberGuid, opts);
}

function getNewChatMessages(jwtToken, memberGuid, fromId) {
  const memberChatApi = new apiFactory.MemberChatApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    fromId
  };

  return memberChatApi.getChatMessagesUsingGET(userAgent, memberGuid, opts);
}

function postChatMessage(jwtToken, memberGuid, message, fileInfo) {
  const memberChatApi = new apiFactory.MemberChatApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };
  const chatMessage = new apiFactory.ChatMessage.constructFromObject({
    id: uuidv4(),
    text: message.message,
    fileinfo: fileInfo
  });

  chatMessage.type = fileInfo ? 'file' : message.messageType;
  opts.message = chatMessage;

  return memberChatApi.postChatMessageUsingPOST(userAgent, memberGuid, opts);
}

function deleteChatMessage(jwtToken, memberGuid, messageId) {
  const memberChatApi = new apiFactory.MemberChatApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberChatApi.stopChatMessageUsingDELETE(userAgent, memberGuid, messageId, opts);
}

function uploadFile(jwtToken, memberGuid, filename, file, contentType) {
  const memberFilesApi = new apiFactory.MemberFilesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };
  const fileId = uuidv4();

  return memberFilesApi.postMemberFilesUsingPOST(userAgent, memberGuid, fileId, filename, file, contentType, opts);
}

function downloadFile(jwtToken, memberGuid, fileId) {
  const memberFilesApi = new apiFactory.MemberFilesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberFilesApi.getMemberFileUsingGETWithHttpInfo(userAgent, memberGuid, fileId, opts);
}

function getFile(jwtToken, memberGuid, fileId, maxWidth, maxHeight) {
  const filesApi = new apiFactory.FilesApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    maxWidth,
    maxHeight
  };

  return filesApi.getFileUsingGETWithHttpInfo(userAgent, 'secured', memberGuid, fileId, opts);
}

function getMemberFiles(jwtToken, memberGuid) {
  const memberFilesApi = new apiFactory.MemberFilesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberFilesApi.getMemberFilesUsingGET(userAgent, memberGuid, opts);
}

function generateDocument(jwtToken, memberGuid, documentType, documentContent) {
  const documentsTemplatesApi = new apiFactory.DocumentsTemplatesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const properties = Object.getOwnPropertyNames(documentContent);
  const keyValues = properties.map((property) => {
    return {
      key: property,
      value: documentContent[property]
    };
  });

  const templateContent = {
    templateType: documentType,
    templateContent: keyValues
  };

  return documentsTemplatesApi.postDocumentTemplateContentUsingPOST(userAgent, memberGuid, templateContent, opts);
}

function getUserProfile(jwtToken, memberGuid) {
  const memberUserProfileApi = new apiFactory.MemberUserProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberUserProfileApi.getMemberByGUIDUsingGET(userAgent, memberGuid, opts);
}

function getUserProfileWithUserExtras(jwtToken, memberGuid, extraFields) {
  const membersListApi = new apiFactory.ListMembersApi(client);

  const options = {
    authorization: `Bearer ${jwtToken}`,
    q: `guid==${memberGuid}`,
    offset: 0,
    limit: 1
  };

  if (extraFields) {
    options.extraFields = extraFields.join(',');
  }

  return membersListApi.getMembersUsingGET(userAgent, options);
}

function getBloodPressureMeasurements(jwtToken, memberGuid, startDate, endDate) {
  const memberMeasurementsApi = new apiFactory.MemberMeasurementsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (startDate) {
    opts.startDate = startDate;
  }

  if (endDate) {
    opts.endDate = endDate;
  }

  return memberMeasurementsApi.getBloodPressureUsingGET(userAgent, memberGuid, opts);
}

function getPrescriptionSession(jwtToken, memberGuid) {
  const prescriptionApi = new apiFactory.PrescriptionApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return prescriptionApi.getPrescriptionSessionUsingGET(userAgent, memberGuid, opts);
}

function postPrescriptionSession(jwtToken, memberGuid, prescriptionSession) {
  const prescriptionApi = new apiFactory.PrescriptionApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return prescriptionApi.postPrescriptionSessionUsingPOST(userAgent, memberGuid, prescriptionSession, opts);
}

function updateMemberMedicationList(jwtToken, memberGuid, medicationList) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    medicationList
  };

  return memberHealthProfileApi.putHealthProfileMedicationsUsingPUT(userAgent, memberGuid, opts);
}

function getEvents(jwtToken, caregiverGuid) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    offset: 0,
    limit: 10000,
    guid: caregiverGuid
  };
  return eventsApi.getEventsUsingGET(userAgent, opts);
}

function getPrioritizedEvents(jwtToken, caregiverGuid, limit = 10000) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    offset: 0,
    limit,
    guid: caregiverGuid
  };

  return eventsApi.getPrioritizedEventsUsingGET(userAgent, opts);
}

function getActiveConsultations(jwtToken, caregiverGuid, limit = 10000) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    offset: 0,
    limit,
    guid: caregiverGuid
  };

  return eventsApi.getActiveConsultationsUsingGET(userAgent, opts);
}

function getBloodPressureStats(jwtToken, memberGuid, startPeriod, periodSize) {
  const bloodPressureStatsApi = new apiFactory.MemberStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (startPeriod >= 0) {
    opts.startPeriod = startPeriod;
  } else {
    delete opts.startPeriod;
  }

  opts.periodSize = periodSize;

  return bloodPressureStatsApi.getBloodPressureStatsUsingGET(userAgent, memberGuid, opts);
}

function handleEvent(jwtToken, eventId, event) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return eventsApi.updateEventUsingPATCH(userAgent, eventId, event, opts);
}

function getHandledEvents(jwtToken, caregiverGuid) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    offset: 0,
    limit: 10000,
    guid: caregiverGuid
  };

  return eventsApi.getEventsHistoryUsingGET(userAgent, opts);
}

function getEventHistoryForMember(jwtToken, memberGuid) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return eventsApi.getEventsHistoryForMemberUsingGET(userAgent, memberGuid, opts);
}

function getActiveEventsForMember(jwtToken, memberGuid) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return eventsApi.getEventsForMemberUsingGET(userAgent, memberGuid, opts);
}

function scheduleEvent(jwtToken, timestamp, comment, memberGuid, eventType) {
  const eventsApi = new apiFactory.EventsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const event = {
    timestamp,
    comment,
    eventType
  };

  return eventsApi.scheduleEventUsingPOST(userAgent, memberGuid, event, opts);
}

function getMemberMedicationHistory(jwtToken, memberGuid) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.getHealthProfileMedicationsHistoryUsingGET(userAgent, memberGuid, opts);
}

function deleteMedication(jwtToken, memberGuid, medicationId) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.deleteHealthProfileMedicationUsingDELETE(userAgent, memberGuid, medicationId, opts);
}

function addMemberMedication(jwtToken, memberGuid, medication) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    medication: new apiFactory.Medication.constructFromObject(medication)
  };

  return memberHealthProfileApi.postHealthProfileMedicationsUsingPOST(userAgent, memberGuid, opts);
}

function getReferralSession(jwtToken, memberGuid) {
  const referralApi = new apiFactory.ReferralAndTestsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    params: []
  };

  return referralApi.obtainMemberLabPortalenSessionUsingPOST(userAgent, memberGuid, opts);
}

function getAllReferrals(jwtToken) {
  const referralApi = new apiFactory.ReferralAndTestsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    params: [{ key: 'view', value: 'out' }]
  };

  return referralApi.obtainLabPortalenSessionUsingPOST(userAgent, opts);
}

function getAllLabResults(jwtToken) {
  const referralApi = new apiFactory.ReferralAndTestsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    params: [{ key: 'view', value: 'in' }]
  };

  return referralApi.obtainLabPortalenSessionUsingPOST(userAgent, opts);
}

function signMedicationList(jwtToken, memberGuid) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.signHealthProfileMedicationsUsingPATCH(userAgent, memberGuid, opts);
}

function getMemberOwnMedications(jwtToken, memberGuid) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.getHealthProfileMemberMedicationsUsingGET(userAgent, memberGuid, opts);
}

function getMemberOwnMedicationsHistory(jwtToken, memberGuid) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.getHealthProfileMemberMedicationsHistoryUsingGET(userAgent, memberGuid, opts);
}

function getMemberPrescribedMedications(jwtToken, memberGuid) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.getHealthProfilePrescribedMedicationsUsingGET(userAgent, memberGuid, opts);
}

function getMemberPrescribedMedicationsHistory(jwtToken, memberGuid) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.getHealthProfilePrescribedMedicationsHistoryUsingGET(userAgent, memberGuid, opts);
}

function getMemberNotes(jwtToken, memberGuid) {
  const notesApi = new apiFactory.DayFinalRiskNotesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return notesApi.getMemberNotesUsingGET(userAgent, memberGuid, opts);
}

function addMemberNote(jwtToken, memberGuid, note) {
  const notesApi = new apiFactory.DayFinalRiskNotesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (!note) {
    // Facilitates promise chaining
    return new Promise((resolve) => {
      resolve();
    });
  }

  opts.note = {
    noteType: note.noteType,
    text: note.text,
    icd: note.icd ? note.icd.split(/,\s|,|\s/) : undefined,
    reason: note.reason,
    patientFeeRequired: note.patientFeeRequired
  };

  return notesApi.updateMemberNotesUsingPOST(userAgent, memberGuid, opts);
}

function addDssItemComment(jwtToken, memberGuid, itemId, comment) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    comment
  };

  return decisionSupportSystemApi.postItemCommentUsingPOST(userAgent, memberGuid, itemId, opts);
}

function setDssRelevantValues(jwtToken, memberGuid, itemId, itemValues) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };
  const relevantValues = { selected: itemValues };

  return decisionSupportSystemApi.postItemRelevancyUsingPOST(userAgent, memberGuid, itemId, relevantValues, opts);
}

function getDssItemHistory(jwtToken, memberGuid, itemId) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getItemHistoryUsingGET(userAgent, memberGuid, itemId, opts);
}

function getMemberDecisionSupportSummary(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getOverviewUsingGET(userAgent, memberGuid, opts);
}

function getMemberMeasurementStatus(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getMeasureStatusUsingGET(userAgent, memberGuid, opts);
}

function getMemberBloodPressureSummary(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getBloodPressureSummaryUsingGET(userAgent, memberGuid, opts);
}

function getMemberLabResults(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getLabResultsUsingGET(userAgent, memberGuid, opts);
}

function getMemberBloodPressureTrends(jwtToken, memberGuid, startPeriod, periodSize) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  opts.startPeriod = startPeriod;
  opts.periodSize = periodSize || 14;

  return decisionSupportSystemApi.getBloodPressureTrendUsingGET(userAgent, memberGuid, opts);
}

function getDssRiskProfile(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getRiskProfileUsingGET(userAgent, memberGuid, opts);
}

function postRiskFactorRecommendations(jwtToken, memberGuid, recommendations) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (!recommendations) {
    // Facilitates promise chaining
    return new Promise((resolve) => {
      resolve();
    });
  }

  return decisionSupportSystemApi.postRiskProfileUsingPOST(userAgent, memberGuid, recommendations, opts);
}

function getObjectives(jwtToken, memberGuid) {
  const objectivesApi = new apiFactory.ObjectivesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return objectivesApi.getObjectivesProgressUsingGET(userAgent, memberGuid, opts);
}

function getDssTreatmentGoals(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getTreatmentUsingGET(userAgent, memberGuid, opts);
}

function postDssTreatmentGoals(
  jwtToken,
  memberGuid,
  goalSystolic,
  goalDiastolic,
  measureFrequencyWeekly,
  treatmentOptions
) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const treatment = {
    goalSystolic,
    goalDiastolic,
    measureFrequencyWeekly,
    treatmentOptions
  };

  return decisionSupportSystemApi.postTreatmentUsingPOST(userAgent, memberGuid, treatment, opts);
}

function postBloodPressureGoals(jwtToken, memberGuid, goalSystolic, goalDiastolic, measureFrequencyWeekly) {
  const memberHealthGoalsApi = new apiFactory.MemberHealthGoalsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const payload = {
    systolic: goalSystolic,
    diastolic: goalDiastolic,
    measureFrequencyWeekly
  };

  return memberHealthGoalsApi.postBloodPressureGoalsUsingPOST(userAgent, memberGuid, payload, opts);
}

function getDssStatus(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getDecisionStatusUsingGET(userAgent, memberGuid, opts);
}

function deleteCheckup(jwtToken, memberGuid, checkupId, cancellationReason) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    cancellationReason
  };

  return decisionSupportSystemApi.removeCheckupUsingDELETE(userAgent, memberGuid, checkupId, opts);
}

function editCheckup(jwtToken, memberGuid, editedCheckup) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.patchCheckupUsingPATCH(userAgent, memberGuid, editedCheckup.id, editedCheckup, opts);
}

function getCheckupCalendar(jwtToken) {
  const checkupApi = new apiFactory.CheckupApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return checkupApi.getCaregiverCheckupCalendarUsingGET(userAgent, opts);
}

function createCheckup(jwtToken, memberGuid, checkup) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.postCheckupUsingPOST(userAgent, memberGuid, checkup, opts);
}

function signDss(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.signDecisionUsingPOST(userAgent, memberGuid, opts);
}

function getMemberMedications(jwtToken, memberGuid) {
  const memberMedicationsApi = new apiFactory.MemberMedicationsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberMedicationsApi.getMedicinesUsingGET(userAgent, memberGuid, opts);
}

function getMemberMedicineExtras(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getMedicineExtrasUsingGET(userAgent, memberGuid, opts);
}

function editMedication(jwtToken, memberGuid, medicationId, medicationEdits, changeType, comment) {
  const memberMedicationsApi = new apiFactory.MemberMedicationsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const medicationBasePatch = Object.assign({}, medicationEdits);

  delete medicationBasePatch.atcText;
  delete medicationBasePatch.packageContent;
  delete medicationBasePatch.approvalDate;
  delete medicationBasePatch.unregisteredDate;
  delete medicationBasePatch.salesStop;
  delete medicationBasePatch.itemNumber;
  delete medicationBasePatch.formIcon;

  const medicationChangeRequest = {
    changeType
  };

  if (medicationEdits) {
    medicationChangeRequest.medicine = medicationBasePatch;
  }

  if (comment) {
    medicationChangeRequest.comment = comment;
  }

  return memberMedicationsApi.updateMedicationUsingPATCH(
    userAgent,
    memberGuid,
    medicationId,
    medicationChangeRequest,
    opts
  );
}

function addMedication(jwtToken, memberGuid, medication) {
  const memberMedicationsApi = new apiFactory.MemberMedicationsApi(client);
  const opts = {};
  opts.authorization = `Bearer ${jwtToken}`;
  opts.medicine = medication;

  return memberMedicationsApi.postMedicineBaseUsingPOST(userAgent, memberGuid, opts);
}

function searchMedications(jwtToken, query) {
  const varaMedicineRegistryApi = new apiFactory.VARAMedicineRegisterApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    q: query
  };

  return varaMedicineRegistryApi.getMedicineFromRegisterUsingGET(userAgent, opts);
}

function getCaregiverMetrics(jwtToken, period) {
  const metricsApi = new apiFactory.MetricsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    month: period
  };

  return metricsApi.getCaregiverMetricsUsingGET(userAgent, opts);
}

function getMemberJiraIssues(jwtToken, memberGuid) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    guid: memberGuid
  };

  return jiraApi.getJiraIssuesUsingGET(userAgent, opts);
}

function postMemberJiraIssue(jwtToken, jiraIssue) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return jiraApi.createJiraIssueUsingPOST(userAgent, jiraIssue, opts);
}

function getChatResponses(jwtToken, memberGuid) {
  return superagent
    .get(`${basePath}/caregiver/chatresponse/member/${memberGuid}`)
    .set('Authorization', `Bearer ${jwtToken}`)
    .set('Accumbo-Client-ID', accumboClientId);
}

function getReferralPackages(jwtToken) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getReferralPackagesUsingGET(userAgent, opts);
}

function postReferral(jwtToken, memberGuid, referral) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.createReferralUsingPOST(userAgent, memberGuid, referral, opts);
}

function getReferrals(jwtToken, memberGuid) {
  const decisionSupportSystemApi = new apiFactory.DecisionSupportSystemApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return decisionSupportSystemApi.getReferralsUsingGET(userAgent, memberGuid, opts);
}

function setActiveCaregiver(jwtToken, memberGuid, caregiverRole, delayDays) {
  const activeCaregiverApi = new apiFactory.ActiveCaregiverApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };
  const roleTransfer = {
    caregiverRole,
    delayDays
  };

  return activeCaregiverApi.setActiveCaregiverUsingPOST(userAgent, memberGuid, roleTransfer, opts);
}

function getMemberPaymentHistory(jwtToken, memberGuid) {
  const paymentHistoryApi = new apiFactory.PaymentHistoryApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentHistoryApi.getPaymentHistoryUsingGET(userAgent, memberGuid, opts);
}

function getMemberComment(jwtToken, memberGuid) {
  const memberCommentApi = new apiFactory.MemberCommentApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberCommentApi.getMemberCommentUsingGET(userAgent, memberGuid, opts);
}

function saveMemberComment(jwtToken, memberGuid, newComment) {
  const memberCommentApi = new apiFactory.MemberCommentApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberCommentApi.updateMemberCommentUsingPUT(userAgent, memberGuid, { text: newComment }, opts);
}

const apiMethods = {
  healthPing,
  logInTest,
  getHealthProfile,
  getMembersList,
  challenge,
  login,
  refreshToken,
  getChatMessages,
  getNewChatMessages,
  postChatMessage,
  getHealthProfileChapters,
  uploadFile,
  downloadFile,
  getFile,
  getMemberFiles,
  generateDocument,
  getUserProfile,
  getUserProfileWithUserExtras,
  getBloodPressureMeasurements,
  getPrescriptionSession,
  postPrescriptionSession,
  updateMemberMedicationList,
  getEvents,
  getPrioritizedEvents,
  getActiveConsultations,
  getBloodPressureStats,
  handleEvent,
  getHandledEvents,
  getEventHistoryForMember,
  getMemberMedicationHistory,
  deleteMedication,
  addMemberMedication,
  getReferralSession,
  getAllReferrals,
  getAllLabResults,
  signMedicationList,
  getMemberOwnMedications,
  getMemberOwnMedicationsHistory,
  getMemberPrescribedMedications,
  getMemberPrescribedMedicationsHistory,
  getMemberNotes,
  addMemberNote,
  scheduleEvent,
  getMemberDecisionSupportSummary,
  addDssItemComment,
  setDssRelevantValues,
  getDssItemHistory,
  getMemberMeasurementStatus,
  getMemberBloodPressureSummary,
  getMemberLabResults,
  getMemberBloodPressureTrends,
  getDssRiskProfile,
  getDssTreatmentGoals,
  postDssTreatmentGoals,
  getDssStatus,
  signDss,
  createCheckup,
  deleteCheckup,
  editCheckup,
  getCheckupCalendar,
  getNewMembers,
  assignResponsibleDoctor,
  getMemberDevices,
  getObjectives,
  postBloodPressureGoals,
  postRiskFactorRecommendations,
  getAnamnesisUpdateSummary,
  updateMember,
  getMemberMedications,
  editMedication,
  searchMedications,
  addMedication,
  deleteChatMessage,
  setClientIdHeader,
  getMemberMedicineExtras,
  getCaregiverMetrics,
  getActiveEventsForMember,
  getMemberJiraIssues,
  postMemberJiraIssue,
  getChatResponses,
  getReferralPackages,
  postReferral,
  getReferrals,
  setActiveCaregiver,
  getMemberPaymentHistory,
  getMemberComment,
  saveMemberComment
};

export default apiMethods;
