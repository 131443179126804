import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import { getPrioritizedEvents } from '../../actions';
import { APPLICATION_BASE_PATH, INBOX_VISITED_PATIENTS_KEY } from '../../constants';
import TopBar from '../TopBar';
import UnauthorizedAccessWarning from '../UnauthorizedAccessWarning';
import CaregiverPriorityList from '../InboxView/CaregiverPriorityList';
import { loadState } from '../../services/localStorage';

const PriorityErrandsView = ({ authToken, user, events, history, getPrioritizedEvents }) => {
  useEffect(() => {
    getPrioritizedEvents(authToken, user.guid);
  }, []);

  const visitedPatients = loadState(INBOX_VISITED_PATIENTS_KEY, true) || {};

  const navigateToMember = (member) => {
    return () => {
      history.push(`/${APPLICATION_BASE_PATH}/member/${member.guid}`);
    };
  };

  return (
    <>
      <UnauthorizedAccessWarning ownPatients={events.caregiverIsResponsibleFor} />
      <TopBar header="prioritized_errands_view.header" />
      <div className="wrapper">
        <div className="mb-30">
          <Link to={`/${APPLICATION_BASE_PATH}`}>
            <span className="text-button chevron-left">
              <Translate value="global.back_to_inbox" />
            </span>
          </Link>
        </div>
        <CaregiverPriorityList
          onRowClick={navigateToMember}
          items={events.prioritizedEvents}
          totalNumItems={events.totalNumPrioritizedEvents}
          isLoading={events.loadingPrioritizedEvents}
          filterProperties={['symptom', 'titrationIncomplete', 'newMessage', 'lowBloodpressure', 'highBloodpressure']}
          visitedPatients={visitedPatients}
          i18nKey="prioritized_errands_view"
          showAnimations={false}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    events: state.events
  };
};

const mapActionsToProps = {
  getPrioritizedEvents
};

export default connect(mapStateToProps, mapActionsToProps)(PriorityErrandsView);
