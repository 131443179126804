import * as types from '../constants/actionTypes';
import { removeItemFromArray, updateObjectInArray, getRevisions } from '../utils';
import moment from 'moment';
import { DATE_FORMAT } from '../constants';

// These change types are not considered revisions
const CHANGE_TYPES_EXCLUDED_FROM_REVISION_COUNT = ['new', 'sign', 'stop'];

const initialState = {
  alfaEReceptModalVisible: false,
  currentMedications: [],
  unsignedMedications: [],
  previousMedications: [],
  invalidatedMedications: [],
  invalidatingIds: [],
  signMedicationModalActive: false,
  signErrorModalActive: false,
  selectedMedication: {},
  signMedicationModalHeaderI18nKey: 'patient_view.medications.header.sign_scanned_medication',
  signMedicationModalSubHeaderI18nKey: 'patient_view.medications.header.patient_scanned_info',
  signMedicationModalActionI18nKey: 'patient_view.medications.sign_medication_modal.sign_button',
  hasConflict: false,
  isScanned: true,
  editingDisabled: true,
  signMedicationModalSize: 'slim',
  alternativesSearchResults: [],
  selectedMedicationOptionIndex: 0,
  varaSearchModalVisible: false,
  varaResultSelectionModalVisible: false,
  varaNameSearchTerm: '',
  varaStrengthSearchTerm: '',
  varaSearchResults: { pagination: {}, medicines: [] },
  varaSearchSelection: {},
  varaImportCompleted: false,
  manualAdd: false,
  medicationModalActive: false,
  cancelErrorModalActive: false,
  medicationUpdates: {},
  revisions: {},
  changeMedicationPropertyModalActive: false,
  changeMedicationPropertyHeaderI18nKey: 'placeholder',
  changeMedicationPropertyActionI18nKey: 'placeholder',
  changeMedicationPropertyCurrentHeaderI18nKey: 'placeholder',
  changeMedicationPropertyNewHeaderI18nKey: 'placeholder',
  originalVersionI18nKey: 'patient_view.medications.medication_modal.original_version',
  revisionCount: 0,
  viewedMedicationAddedDate: null,
  showMedicationNameNotice: false,
  varaImportBypassed: false,
  varaBypassReasonType: null,
  varaBypassFreeTextReason: null,
  varaBypassInfoExpanded: false,
  varaImportType: 'manufacturerUnknown',
  doctorHasPrescribedMedication: false,
  memberHasRequestedPrescription: false,
  memberPrescriptionRequestAnswer: ''
};

const dummyMedication = { current: {}, original: {}, changes: [] };
initialState.viewedMedication = dummyMedication;

const modalHideState = {
  hasConflict: false,
  signMedicationModalSize: 'slim',
  signMedicationModalActive: false,
  selectedMedicationOptionIndex: 0,
  showMedicationNameNotice: false,
  varaImportBypassed: false,
  varaBypassReasonType: null,
  varaBypassFreeTextReason: null,
  varaBypassInfoExpanded: false
};

const medicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_MEDICATIONS:
      return { ...initialState };
    case types.GET_MEMBER_MEDICATIONS_REQUEST:
      return { ...state, loadingMemberMedications: true };
    case types.GET_MEMBER_MEDICATIONS_SUCCESS:
      return {
        ...state,
        loadingMemberMedications: false,
        memberMedications: action.payload,
        unsignedMedications: action.payload.filter((medicationEntry) => medicationEntry.latestChangeType === 'new'),
        currentMedications: action.payload.filter(
          (medicationEntry) =>
            medicationEntry.latestChangeType !== 'new' &&
            medicationEntry.latestChangeType !== 'stop' &&
            medicationEntry.latestChangeType !== 'invalidate'
        ),
        previousMedications: action.payload.filter((medicationEntry) => medicationEntry.latestChangeType === 'stop'),
        invalidatedMedications: action.payload.filter(
          (medicationEntry) => medicationEntry.latestChangeType === 'invalidate'
        ),
        memberMedicationsError: undefined
      };
    case types.GET_MEMBER_MEDICATIONS_ERROR:
      return { ...state, loadingMemberMedications: false, memberMedicationsError: action.payload };
    case types.GET_PRESCRIPTION_REQUEST:
      return { ...state, gettingPrescriptionSession: true };
    case types.GET_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        gettingPrescriptionSession: false,
        redirectUrl: action.payload.redirectUrl,
        sessionToken: action.payload.sessionToken,
        iframeUrl: action.payload.redirectUrl,
        alfaEReceptModalVisible: true,
        getPrescriptionError: undefined
      };
    case types.GET_PRESCRIPTION_ERROR:
      return { ...state, getPrescriptionError: action.payload };
    case types.POST_PRESCRIPTION_REQUEST:
      return { ...state, gettingPrescription: true };
    case types.POST_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        gettingPrescription: false,
        currentMedications: [...state.currentMedications, ...action.payload.prescription],
        alfaEReceptModalVisible: false,
        redirectUrl: undefined,
        iframeUrl: undefined,
        sessionToken: undefined,
        postPrescriptionError: undefined,
        doctorHasPrescribedMedication: true
      };
    case types.POST_PRESCRIPTION_ERROR:
      return { ...state, gettingPrescription: false, postPrescriptionError: action.payload };
    case types.OPEN_ALFA_ERECEPT_MODAL:
      return { ...state, alfaEReceptModalVisible: true };
    case types.CLOSE_ALFA_ERECEPT_MODAL:
      return { ...state, alfaEReceptModalVisible: false, iframeUrl: '', redirectUrl: '' };
    case types.SIGN_MEDICATION_REQUEST:
      return { ...state, isSigningMedication: true };
    case types.SIGN_MEDICATION_SUCCESS: {
      const signedMedicationIndex = state.unsignedMedications
        .map((medication) => medication.id)
        .indexOf(action.payload.medication.id);

      return {
        ...state,
        isSigningMedication: false,
        currentMedications: [
          {
            ...action.payload.medication,
            changes: [
              ...action.payload.medication.changes,
              {
                changeType: 'sign',
                timestamp: moment(),
                changedBy: {
                  guid: action.payload.user.guid,
                  givenName: action.payload.user.givenName,
                  familyName: action.payload.user.familyName
                }
              }
            ]
          },
          ...state.currentMedications
        ],
        unsignedMedications: removeItemFromArray(state.unsignedMedications, { index: signedMedicationIndex }),
        signMedicationError: undefined,
        selectedMedication: {},
        alternativesSearchResults: [],
        varaSearchResults: { pagination: {}, medicines: [] },
        varaNameSearchTerm: '',
        varaStrengthSearchTerm: '',
        varaImportType: 'manufacturerUnknown',
        varaImportCompleted: false,
        ...modalHideState
      };
    }
    case types.SIGN_MEDICATION_ERROR: {
      let newUnsignedMedicationsList = [...state.unsignedMedications];
      let showSignMedicationModal = true;
      if (action.payload.medicationId) {
        const canceledMedicationIndex = state.unsignedMedications
          .map((medication) => medication.id)
          .indexOf(action.payload.medicationId);
        newUnsignedMedicationsList = removeItemFromArray(state.unsignedMedications, { index: canceledMedicationIndex });
        showSignMedicationModal = false;
      }

      return {
        ...state,
        isSigningMedication: false,
        unsignedMedications: newUnsignedMedicationsList,
        signMedicationModalActive: showSignMedicationModal,
        signErrorModalActive: !!action.payload.medicationId,
        signMedicationError: action.payload.error
      };
    }
    case types.SELECT_MEDICATION: {
      const hasConflict =
        action.payload.original && action.payload.original.barcode && !action.payload.current.nplPackId;
      const isScanned = !!(action.payload.original && action.payload.original.barcode);
      const editingDisabled = !!(action.payload.original && action.payload.original.barcode);
      const selectedMedication =
        hasConflict &&
        state.alternativesSearchResults &&
        state.alternativesSearchResults.medicines &&
        state.alternativesSearchResults.medicines.length
          ? {
              ...action.payload,
              current: { ...action.payload.current, ...state.alternativesSearchResults.medicines[0] }
            }
          : action.payload;

      return {
        ...state,
        signMedicationModalActive: true,
        manualAdd: false,
        selectedMedication,
        hasConflict,
        isScanned,
        editingDisabled,
        varaNameSearchTerm: selectedMedication.current.name,
        varaStrengthSearchTerm: selectedMedication.current.strength,
        varaImportCompleted: false,
        signMedicationModalSubHeaderI18nKey: `patient_view.medications.header.patient_${
          isScanned ? 'scanned' : 'manual'
        }_info`,
        signMedicationModalHeaderI18nKey: `patient_view.medications.header.sign_${
          isScanned ? 'scanned' : 'manual'
        }_medication`,
        signMedicationModalActionI18nKey: 'patient_view.medications.sign_medication_modal.sign_button',
        signMedicationModalSize: hasConflict ? 'wide' : 'slim'
      };
    }
    case types.SHOW_SIGN_MEDICATION_MODAL:
      return { ...state, signMedicationModalActive: true };
    case types.HIDE_SIGN_MEDICATION_MODAL:
      return {
        ...state,
        ...modalHideState
      };
    case types.CLOSE_SIGN_MEDICATION_MODAL:
      return {
        ...state,
        signMedicationModalActive: false,
        selectedMedication: {},
        alternativesSearchResults: [],
        showMedicationNameNotice: false,
        varaImportBypassed: false
      };
    case types.UPDATE_MEDICATION_PROPERTY: {
      const eventValue =
        action.payload.event.target.type === 'checkbox'
          ? action.payload.event.target.checked
          : action.payload.event.target.value;

      return {
        ...state,
        selectedMedication: {
          ...state.selectedMedication,
          current: {
            ...state.selectedMedication.current,
            [action.payload.property]: eventValue
          }
        }
      };
    }
    case types.UPDATE_CURRENT_MEDICATION_PROPERTY: {
      const eValue =
        action.payload.event.target.type === 'checkbox'
          ? action.payload.event.target.checked
          : action.payload.event.target.value;

      return {
        ...state,
        medicationUpdates: {
          ...state.medicationUpdates,
          [action.payload.property]: eValue
        }
      };
    }
    case types.DISMISS_SIGNING_ERROR_MESSAGE:
      return { ...state, signErrorModalActive: false };
    case types.SEARCH_MEDICATION_ALTERNATIVES_REQUEST:
      return { ...state, loadingSearchResults: true };
    case types.SEARCH_MEDICATION_ALTERNATIVES_SUCCESS:
      return {
        ...state,
        loadingSearchResults: false,
        alternativesSearchResults: action.payload,
        searchMedicationsError: undefined,
        selectedMedication: {
          ...state.selectedMedication,
          current: { ...state.selectedMedication.current, ...action.payload.medicines[0] }
        }
      };
    case types.SEARCH_MEDICATION_ALTERNATIVES_ERROR:
      return { ...state, loadingSearchResults: false, searchMedicationAlternativesError: action.payload };
    case types.SELECT_MEDICATION_CONFLICT_OPTION:
      return {
        ...state,
        signMedicationModalActionI18nKey:
          action.payload > 0
            ? 'patient_view.medications.sign_medication_modal.replace_and_sign'
            : 'patient_view.medications.sign_medication_modal.sign_button',
        selectedMedicationOptionIndex: action.payload,
        selectedMedication: {
          ...state.selectedMedication,
          current: { ...state.selectedMedication.current, ...state.alternativesSearchResults.medicines[action.payload] }
        }
      };
    case types.SHOW_VARA_SEARCH_MODAL:
      return {
        ...state,
        varaSearchModalVisible: true
      };
    case types.HIDE_VARA_SEARCH_MODAL:
      return { ...state, varaSearchModalVisible: false };
    case types.SHOW_VARA_RESULT_SELECTION_MODAL:
      return { ...state, varaResultSelectionModalVisible: true };
    case types.HIDE_VARA_RESULT_SELECTION_MODAL:
      return { ...state, varaResultSelectionModalVisible: false, varaImportType: 'manufacturerUnknown' };
    case types.VARA_GET_SUGGESTIONS_REQUEST:
      return {
        ...state,
        loadingVaraMedications: true,
        varaNameSearchTerm: action.payload.name,
        varaStrengthSearchTerm: action.payload.strength
      };
    case types.VARA_GET_SUGGESTIONS_SUCCESS:
      return { ...state, loadingVaraMedications: false, varaSearchResults: action.payload, varaSearchError: undefined };
    case types.VARA_GET_SUGGESTIONS_ERROR:
      return { ...state, loadingVaraMedications: false, varaSearchError: action.payload };
    case types.SELECT_VARA_SEARCH_RESULT:
      return {
        ...state,
        varaSearchSelection: state.varaSearchResults.medicines[action.payload],
        varaSearchModalVisible: false,
        varaResultSelectionModalVisible: true
      };
    case types.SELECT_VARA_IMPORT_TYPE:
      return { ...state, varaImportType: action.payload };
    case types.IMPORT_VARA_SEARCH_RESULT: {
      let addedMedicationInformation;

      if (state.varaImportType === 'manufacturerKnown') {
        addedMedicationInformation = { ...state.varaSearchSelection };
      } else {
        addedMedicationInformation = {
          atcCode: state.varaSearchSelection.atcCode,
          strength: state.varaSearchSelection.strength,
          form: state.varaSearchSelection.form
        };
      }

      return {
        ...state,
        signMedicationModalActive: true,
        varaResultSelectionModalVisible: false,
        editingDisabled: true,
        showMedicationNameNotice: state.varaImportType === 'manufacturerUnknown',
        varaImportType: 'manufacturerUnknown',
        varaImportCompleted: true,
        selectedMedication: {
          ...state.selectedMedication,
          current: { ...state.selectedMedication.current, ...addedMedicationInformation }
        }
      };
    }
    case types.ADD_MEDICATION_MANUALLY:
      return {
        ...state,
        manualAdd: true,
        signMedicationModalActive: true,
        selectedMedication: { current: {} },
        editingDisabled: false,
        varaImportCompleted: false,
        isScanned: false,
        hasConflict: false,
        signMedicationModalSize: 'slim',
        signMedicationModalHeaderI18nKey: 'patient_view.medications.header.add_medication_manually',
        signMedicationModalSubHeaderI18nKey: 'patient_view.medications.header.add_medication_manually_sub_header',
        signMedicationModalActionI18nKey: 'patient_view.medications.add_medication_modal.add_button'
      };
    case types.ADD_MEDICATION_REQUEST:
      return { ...state, isSigningMedication: true };
    case types.ADD_MEDICATION_SUCCESS:
      return {
        ...state,
        manualAdd: false,
        isSigningMedication: false,
        signMedicationModalActive: false,
        currentMedications: [...state.currentMedications, action.payload],
        signMedicationModalActionI18nKey: 'patient_view.medications.sign_medication_modal.sign_button',
        showMedicationNameNotice: false
      };
    case types.ADD_MEDICATION_ERROR:
      return { ...state, isSigningMedication: false };
    case types.SHOW_MEDICATION_MODAL:
      return { ...state, medicationModalActive: true };
    case types.HIDE_MEDICATION_MODAL:
      return {
        ...state,
        medicationModalActive: false,
        viewedMedication: dummyMedication,
        viewedMedicationAddedDate: null,
        medicationUpdates: {}
      };
    case types.VIEW_MEDICATION: {
      const createdByPatient = action.payload.changes.filter((c) => c.changeType === 'new')[0]?.changedBy === null;
      const revisions = getRevisions(action.payload.changes, 'global.no_data');
      const created = action.payload.changes.filter((change) => change.changeType === 'new')[0];
      const createdDate = created && created.timestamp;

      return {
        ...state,
        originalVersionI18nKey: `patient_view.medications.medication_modal.original_version${
          createdByPatient ? '_by_patient' : ''
        }`,
        viewedMedication: action.payload,
        viewedMedicationAddedDate: createdDate ? moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD') : null,
        revisions,
        revisionCount: action.payload.changes.filter(
          (change) => !CHANGE_TYPES_EXCLUDED_FROM_REVISION_COUNT.includes(change.changeType)
        ).length,
        medicationModalActive: true,
        medicationUpdates: { ...action.payload.current }
      };
    }
    case types.CANCEL_MEDICATION_REQUEST:
      return { ...state, isCancelingMedication: true };
    case types.CANCEL_MEDICATION_SUCCESS: {
      const canceledMedicationIndex = state.currentMedications
        .map((medication) => medication.id)
        .indexOf(action.payload.medication.id);

      return {
        ...state,
        isCancelingMedication: false,
        previousMedications: [
          {
            ...action.payload.medication,
            changes: [
              ...action.payload.medication.changes,
              {
                changeType: 'stop',
                timestamp: moment(),
                changedBy: {
                  guid: action.payload.user.guid,
                  givenName: action.payload.user.givenName,
                  familyName: action.payload.user.familyName
                }
              }
            ]
          },
          ...state.previousMedications
        ],
        currentMedications: removeItemFromArray(state.currentMedications, { index: canceledMedicationIndex }),
        cancelMedicationError: undefined,
        viewedMedication: dummyMedication,
        medicationModalActive: false
      };
    }
    case types.CANCEL_MEDICATION_ERROR: {
      let newCurrentMedicationsList = [...state.currentMedications];
      let newPreviousMedicationsList = [...state.previousMedications];
      let showMedicationModal = true;
      if (action.payload.medicationId) {
        const canceledMedication = state.currentMedications.filter(
          (medication) => medication.id === action.payload.medicationId
        )[0];
        const canceledMedicationIndex = state.currentMedications
          .map((medication) => medication.id)
          .indexOf(action.payload.medicationId);
        newCurrentMedicationsList = removeItemFromArray(state.currentMedications, { index: canceledMedicationIndex });
        newPreviousMedicationsList = [canceledMedication, ...state.previousMedications];
        showMedicationModal = false;
      }

      return {
        ...state,
        isCancelingMedication: false,
        currentMedications: newCurrentMedicationsList,
        previousMedications: newPreviousMedicationsList,
        medicationModalActive: showMedicationModal,
        cancelErrorModalActive: !!action.payload.medicationId,
        cancelMedicationError: action.payload.error
      };
    }
    case types.DISMISS_CANCELLATION_ERROR_MESSAGE:
      return { ...state, cancelErrorModalActive: false };
    case types.INVALIDATE_MEDICATION_REQUEST:
      return { ...state, invalidatingIds: [...state.invalidatingIds, action.medicationId] };
    case types.INVALIDATE_MEDICATION_SUCCESS: {
      const invalidatedMedicationIndex = state.unsignedMedications
        .map((medication) => medication.id)
        .indexOf(action.payload.medicationId);
      const invalidatedMedication = { ...state.unsignedMedications[invalidatedMedicationIndex] };

      return {
        ...state,
        invalidatingIds: removeItemFromArray(state.invalidatingIds, {
          index: state.invalidatingIds.indexOf(action.payload.medicationId)
        }),
        unsignedMedications: removeItemFromArray(state.unsignedMedications, { index: invalidatedMedicationIndex }),
        invalidatedMedications: [
          ...state.invalidatedMedications,
          {
            ...invalidatedMedication,
            changes: [
              ...invalidatedMedication.changes,
              {
                changeType: 'invalidate',
                timestamp: moment(),
                changedBy: {
                  guid: action.payload.user.guid,
                  givenName: action.payload.user.givenName,
                  familyName: action.payload.user.familyName
                }
              }
            ]
          }
        ],
        invalidateMedicationError: undefined
      };
    }
    case types.INVALIDATE_MEDICATION_ERROR:
      return {
        ...state,
        invalidateMedicationError: action.payload.error,
        invalidatingIds: removeItemFromArray(state.invalidatingIds, {
          index: state.invalidatingIds.indexOf(action.payload.medicationId)
        })
      };
    case types.CHANGE_MEDICATION_PROPERTY_REQUEST:
      return { ...state, changingMedicationProperty: true };
    case types.CHANGE_MEDICATION_PROPERTY_SUCCESS: {
      const updatedMedicationIndex = state.currentMedications
        .map((medication) => medication.id)
        .indexOf(action.payload.medicationId);

      const newChange = {
        changeType: action.payload.changeType,
        timestamp: moment(),
        changedBy: {
          guid: action.payload.user.guid,
          givenName: action.payload.user.givenName,
          familyName: action.payload.user.familyName
        },
        previousBase: {
          ...action.payload.newMedicationBase,
          [action.payload.changedProperty]: action.payload.previousPropertyValue,
          whenNeeded: action.payload.whenNeeded
        },
        newBase: { ...action.payload.newMedicationBase }
      };
      const currentMedicationChanges = [...state.viewedMedication.changes, newChange];
      const newRevisionList = getRevisions(currentMedicationChanges, 'global.no_data');
      const viewedMedication = {
        ...state.viewedMedication,
        current: state.medicationUpdates,
        changes: currentMedicationChanges
      };
      return {
        ...state,
        revisions: newRevisionList,
        revisionCount: currentMedicationChanges.length - 2,
        changeMedicationPropertyModalActive: false,
        medicationModalActive: true,
        viewedMedication,
        currentMedications: updateObjectInArray(state.currentMedications, {
          index: updatedMedicationIndex,
          item: { ...viewedMedication }
        }),
        changingMedicationProperty: false,
        medicationPropertyChangeError: undefined
      };
    }
    case types.CHANGE_MEDICATION_PROPERTY_ERROR:
      return { ...state, changingMedicationProperty: false, medicationPropertyChangeError: action.payload };
    case types.SHOW_CHANGE_MEDICATION_PROPERTY_MODAL:
      return {
        ...state,
        medicationModalActive: false,
        changeMedicationPropertyModalActive: true,
        changeMedicationPropertyHeaderI18nKey: action.payload.headerI18nKey,
        changeMedicationPropertyActionI18nKey: action.payload.actionI18nKey,
        changeMedicationPropertyCurrentHeaderI18nKey: action.payload.currentHeaderI18nKey,
        changeMedicationPropertyNewHeaderI18nKey: action.payload.newHeaderI18nKey,
        medicationChangeProperty: action.payload.property,
        currentMedicationPropertyValue: state.viewedMedication.current[action.payload.property],
        changeMedicationPropertyChangeType: action.payload.changeType,
        currentMedicationWhenNeeded: state.viewedMedication.current.whenNeeded
      };
    case types.HIDE_CHANGE_MEDICATION_PROPERTY_MODAL:
      return { ...state, medicationModalActive: true, changeMedicationPropertyModalActive: false };
    case types.TOGGLE_VARA_BYPASS_INFO:
      return { ...state, varaBypassInfoExpanded: !state.varaBypassInfoExpanded };
    case types.CONTINUE_WITHOUT_VARA_IMPORT:
      return { ...state, signMedicationModalActive: true, varaSearchModalVisible: false, varaImportBypassed: true };
    case types.SET_VARA_BYPASS_REASON:
      return { ...state, varaBypassReasonType: action.payload };
    case types.SET_VARA_BYPASS_REASON_TEXT:
      return { ...state, varaBypassFreeTextReason: action.payload };
    case types.GET_ANAMNESIS_UPDATE_SUMMARY_SUCCESS: {
      const memberHasRequestedPrescription = !!(
        action.payload.length &&
        !action.payload[0].signedOff &&
        action.payload[0].medicineQuestions.length &&
        action.payload[0].medicineQuestions[1]?.answer?.answer
      );
      const memberPrescriptionRequestAnswer = memberHasRequestedPrescription
        ? action.payload[0].medicineQuestions[1].answer?.answer
        : '';

      return { ...state, memberHasRequestedPrescription, memberPrescriptionRequestAnswer };
    }
    default:
      return state;
  }
};

export default medicationReducer;
