import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import LoaderButton from '../../LoaderButton';
import DropdownMenu from '../../DropdownMenu';
import { decamelize, configuredMoment as moment } from '../../../utils';
import { DATE_FORMAT, PAL_EVENTS, PAS_EVENTS } from '../../../constants';
import './MemberEventRow.scss';

const MemberEventRow = ({
  event,
  dateTimeFormat,
  doneHandler,
  historical,
  handled,
  beingHandled,
  url,
  onAnamnesisLink,
  onSnooze
}) => {
  const nonHandleableEventTypes = ['patientNotReady'];
  let questionHash;

  if (event.eventType === 'profileUpdate') {
    const digitMatches = event.comment.match(/\d+/g);

    if (digitMatches) {
      questionHash = `anamnesis-${digitMatches[0]}-${digitMatches[1]}`;
    }
  }

  const snoozeOptions = [
    {
      i18nKey: 'patient_view.events.snooze.one_day',
      authority: 'viewPatients',
      onClick: () => onSnooze(1, 'day')
    },
    {
      i18nKey: 'patient_view.events.snooze.three_days',
      authority: 'viewPatients',
      onClick: () => onSnooze(3, 'days')
    },
    {
      i18nKey: 'patient_view.events.snooze.one_week',
      authority: 'viewPatients',
      onClick: () => onSnooze(1, 'week')
    }
  ];

  if (process.env.REACT_APP_API_ENV !== 'production') {
    snoozeOptions.unshift({
      i18nKey: 'patient_view.events.snooze.zero_days',
      authority: 'viewPatients',
      onClick: () => onSnooze(0, 'day')
    });
  }

  return (
    <div className="member-event-row-wrapper">
      <div className="columns member-event-row">
        {!historical ? (
          <div className="mr-10 vertical-align">
            {!handled ? (
              <DropdownMenu actions={snoozeOptions} userAuthorities={['viewPatients']} icon="snooze" />
            ) : (
              <span className="icon snooze opacity-3 cursor-not-allowed"></span>
            )}
          </div>
        ) : null}
        <div className="column is-3 no-padding vertical-align event-icon-wrapper">
          <span
            className={`icons ${decamelize(event.eventType, '-')}`}
            title={I18n.t(`global.event_type.${event.eventType}`)}
          >
            {PAL_EVENTS.includes(event.eventType) || PAS_EVENTS.includes(event.eventType) ? (
              <span className="fs-12 caregiver-abbr">{PAL_EVENTS.includes(event.eventType) ? 'PAL' : 'PAS'}</span>
            ) : null}
          </span>
        </div>
        <div className="column is-7 no-padding">
          <div className="event-type">
            {event.eventType === 'profileUpdate' && questionHash ? (
              <NavLink smooth to={`${url}/anamnesis#${questionHash}`} onClick={onAnamnesisLink}>
                {event.comment}
              </NavLink>
            ) : (
              event.comment || I18n.t(`global.event_type.${event.eventType}`)
            )}
          </div>
          <div className="event-timestamp">{moment(event.timestamp, DATE_FORMAT).format(dateTimeFormat)}</div>
          {!historical && event.snoozedUntilDate ? (
            <div className={`snoozed-until ${event.isUpdated ? 'animate' : ''}`}>
              <span className="icon snooze"></span>
              <Translate
                value={`patient_view.events.${
                  moment(event.snoozedUntilDate).isSameOrBefore(moment()) ? 'was_' : ''
                }snoozed_until`}
                date={event.snoozedUntilDate}
              />
            </div>
          ) : null}
          {historical && event.handledBy ? (
            <div className="event-handled-by">
              {`${event.handledBy.givenName} ${event.handledBy.familyName}`},{' '}
              {moment(event.handledTimestamp, DATE_FORMAT).format(dateTimeFormat)}
            </div>
          ) : null}
        </div>
        {!historical ? (
          <div className="column is-2 no-padding event-done-button-wrapper">
            {handled ? (
              <span className="checkmark"></span>
            ) : !nonHandleableEventTypes.includes(event.eventType) ? (
              <LoaderButton
                buttonI18nKey="global.buttons.done"
                isLoading={beingHandled}
                onClick={doneHandler}
                disabled={beingHandled}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MemberEventRow;
