import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { getAgeFromPersonalNumber, configuredMoment as moment, groupBy, decamelize, initials } from '../../utils';
import { DATE_FORMAT, PAL_EVENTS, PAS_EVENTS } from '../../constants';
import DropdownMenu from '../DropdownMenu';
import WaitTime from '../WaitTime';
import './ErrandRow.scss';

const ErrandRow = ({ userEvent, historical, currentUser, onClick, isNurse, visited, onMarkAsUnread, authorities }) => {
  const groupedByEventType = groupBy(userEvent.events, 'eventType');
  const groupedPropertyNames = Object.getOwnPropertyNames(groupedByEventType);
  const timestamp = historical
    ? userEvent.events[userEvent.events.length - 1].handledTimestamp
    : userEvent.oldestTimestamp;
  const caregiverProperty = isNurse ? 'responsibleNurse' : 'responsibleDoctor';
  const caregiver = userEvent.member[caregiverProperty];
  const isOwnPatient =
    userEvent.member[caregiverProperty] && currentUser.guid === userEvent.member[caregiverProperty].guid;
  const ACTIVE_ERRANDS_COLLAPSE_THRESHOLD = 6;

  let isSecondaryCaregiver =
    (userEvent.member.secondaryResponsibleDoctor &&
      userEvent.member.secondaryResponsibleDoctor.guid === currentUser.guid) ||
    (userEvent.member.secondaryResponsibleNurse &&
      userEvent.member.secondaryResponsibleNurse.guid === currentUser.guid);

  return (
    <div className={`member-row ${visited ? '' : 'new-errands'}`} onClick={onClick}>
      <div className={`columns errand-item ${isSecondaryCaregiver ? 'secondary-caregiver' : ''}`}>
        <div className="column">
          <strong data-sensitive>{`${userEvent.member.givenName} ${userEvent.member.familyName}`}</strong>,{' '}
          {getAgeFromPersonalNumber(userEvent.member.personalNumber)} <Translate value="global.years" />
        </div>
        <div className="column is-3 errand-item__wait-time">
          {`${moment(timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}`} (<WaitTime sinceTimestamp={timestamp} />)
        </div>
        <div className={`column is-1 z99 responsible-doctor ${isOwnPatient ? 'own-patient' : ''}`}>
          {caregiver ? (
            <span title={`${caregiver.givenName} ${caregiver.familyName}`}>
              {initials(caregiver.givenName, caregiver.familyName)}
            </span>
          ) : (
            <Translate className="new-patient" value="inbox_view.new_patient" />
          )}
        </div>
        <div className="column is-2 mukta">{userEvent.member.source || '-'}</div>
        <div
          className={`column errand-icons has-text-right ${
            (historical && groupedPropertyNames.length > 1) ||
            groupedPropertyNames.length > ACTIVE_ERRANDS_COLLAPSE_THRESHOLD
              ? 'icons__collapsed'
              : ''
          }`}
        >
          <div className="errand-icon-container">
            {groupedPropertyNames.map((eventType, i) => {
              const numEvents = groupedByEventType[eventType].length;
              return (
                <span
                  className={`icons pos-rel ${decamelize(eventType, '-')}`}
                  key={i}
                  title={I18n.t(`global.event_type.${eventType}`)}
                >
                  {PAL_EVENTS.includes(eventType) || PAS_EVENTS.includes(eventType) ? (
                    <span className="fs-12 caregiver-abbr">{PAL_EVENTS.includes(eventType) ? 'PAL' : 'PAS'}</span>
                  ) : null}
                  <span className={`event-count ${numEvents === 1 ? 'count-margin-fix' : ''}`}>
                    {numEvents > 1 ? numEvents : ''}
                  </span>
                  {!historical &&
                  userEvent.events.some((event) => event.eventType === eventType && event.snoozedUntilDate) ? (
                    <span
                      className="icon snooze opacity-6"
                      style={{ position: 'absolute', right: -5, bottom: -5, width: '20px', height: '20px' }}
                    ></span>
                  ) : null}
                </span>
              );
            })}
          </div>
        </div>
        {visited && !historical ? (
          <div className="errand-actions">
            <DropdownMenu
              actions={[
                {
                  i18nKey: 'inbox_view.errand_action.mark_unread',
                  authority: 'viewPatients',
                  onClick: onMarkAsUnread
                }
              ]}
              userAuthorities={authorities}
              padIcon={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ErrandRow;
