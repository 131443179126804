import React, { Fragment, useState } from 'react';
import { Translate } from 'react-redux-i18n';

const ConsentMessage = ({ message }) => {
  const [expanded, setExpanded] = useState(false);

  if (
    !message.checkupOptions ||
    !message.checkupOptions.availableOptions ||
    !message.checkupOptions.availableOptions.length
  ) {
    return null;
  }

  return (
    <div className={`system-message-wrapper`} onClick={() => setExpanded(!expanded)}>
      <div className={`system-message-container ${expanded ? 'expanded' : ''}`}>
        {expanded ? (
          <div className="system-message">
            <div className="system-message__content">
              <blockquote>{message.text}</blockquote>
            </div>
            <div className="flex vertical-align">
              <div className="consent-status"></div>
            </div>
          </div>
        ) : (
          <Fragment>
            {message.checkupOptions?.chosenOption ? (
              <Translate value={`patient_view.chat.consent_message.${message.checkupOptions.chosenOption}`} />
            ) : (
              <Translate value="patient_view.chat.consent_message.option_not_chosen" />
            )}
            <span className="chevron"></span>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ConsentMessage;
