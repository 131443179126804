import React, { Component, Fragment } from 'react';
import { Switch } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Client } from '@stomp/stompjs';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import Sidebar from '../../components/Sidebar';
import InboxView from '../../components/InboxView';
import PatientView from '../../components/PatientView';
import LogoutView from '../../components/LogoutView';
import PrivateRoute from '../../components/PrivateRoute';
import ReferralsView from '../../components/ReferralsView';
import Notification from '../../components/Notification';
import ModalDialog from '../../components/ModalDialog';
import PaginatedMemberList from '../../components/MemberLists/PaginatedMemberList';
import MaintenanceBanner from '../../components/MaintenanceBanner';
import UnassignedPatientsView from '../../components/UnassignedPatientsView';
import PriorityErrandsView from '../../components/PriorityErrandsView';
import ActiveConsultationsView from '../../components/ActiveConsultationsView';
import {
  APPLICATION_BASE_PATH,
  CURRENT_ENV,
  DATE_FORMAT,
  HEALTH_PING_SUCCESS_THRESHOLD,
  MAINTENANCE_STORAGE_KEY
} from '../../constants';
import { clearStorage, loadSessionState } from '../../services/sessionStorage';
import metadata from '../../metadata.json';
import ceMark from '../../assets/ce-mark.png';
import { loadState, removeItem, saveState } from '../../services/localStorage';
import {
  getEvents,
  getHandledEvents,
  hideNotification,
  acknowledgeReleaseNotes,
  disableInstance,
  setStompClient,
  setBroadcastChannel,
  hideCeModal,
  getReferralPackages,
  hideMaintenanceModal,
  showMaintenanceModal,
  showDeployInProgressModal,
  healthPing,
  resetHealthPingSuccesses,
  hideDeployInProgressModal,
  hideMaintenanceCompleteModal,
  showMaintenanceCompleteModal,
  checkTokenValidity,
  manuallyRefreshToken,
  getCaregiverProfileImage
} from '../../actions';
import './App.scss';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const websocketPath = metadata.environments[env].apiUrl.replace('http', 'ws');

const initWebsocket = (jwt, requestId, setStompClient, disableCallback, maintenanceCallback) => {
  const client = new Client({
    brokerURL: `${websocketPath}/websocket`,
    reconnectDelay: 5000,
    connectHeaders: {
      Authorization: `Bearer ${jwt}`
    }
  });

  setStompClient(client);

  client.onConnect = () => {
    client.subscribe('/user/queue/clinic', (message) => {
      const body = JSON.parse(message.body);

      if (body.messageType === 'loginMessage') {
        // Use the requestId to identify if the request came from our own tab.
        if (body[body.messageType].requestId !== requestId) {
          disableCallback();
          client.deactivate();
        }
      } else if (body.messageType === 'maintenanceDeploy') {
        maintenanceCallback(body.maintenanceDeployMessage.seconds);
      }
    });
  };

  client.onStompError = (frame) => {
    console.log('Broker reported error: ' + frame.headers['message']);
    console.log('Additional details: ' + frame.body);
    const auth = loadSessionState('auth', true);
    if (auth && auth.token && auth.token.jwt) {
      client.connectHeaders.Authorization = `Bearer ${auth.token.jwt}`;
    }
  };

  client.activate();
};

class App extends Component {
  constructor(props) {
    super(props);

    this.acknowledgeReleaseNotes = this.acknowledgeReleaseNotes.bind(this);
    this.initMaintenanceMode = this.initMaintenanceMode.bind(this);
    this.healthPing = this.healthPing.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleWindowFocus = this.handleWindowFocus.bind(this);

    this.state = {
      healthPingPollerId: null
    };

    this.keysPressed = {};
    this.observedKeys = ['Control', 'y'];
  }

  componentDidMount() {
    if ('BroadcastChannel' in window) {
      const clinicChannel = new window.BroadcastChannel('clinic');

      this.props.setBroadcastChannel(clinicChannel);

      clinicChannel.postMessage('init');

      clinicChannel.onmessage = (e) => {
        if (e.data === 'init') {
          this.props.disableInstance('BroadcastChannel');
          clearStorage();
        }
      };
    }

    if (!this.props.events.userEvents.length && !this.props.events.isLoading) {
      this.props.getEvents(
        this.props.authToken,
        this.props.user.guid,
        this.props.isNurse,
        this.props.events.viewingAllEvents
      );
    }

    this.props.getReferralPackages(this.props.authToken);

    const requestId = new Date().getTime().toString();
    sessionStorage.setItem('requestId', requestId);

    initWebsocket(
      this.props.authToken,
      requestId,
      this.props.setStompClient,
      () => {
        this.props.disableInstance('RequestId mismatch');
        clearStorage();
      },
      (secondsUntilMaintenance) => {
        const maintenanceStartsAt = moment().add(secondsUntilMaintenance, 's');
        saveState(maintenanceStartsAt.format(DATE_FORMAT), MAINTENANCE_STORAGE_KEY);
        this.setState({ maintenanceStartsAt });

        this.props.showMaintenanceModal();

        setTimeout(() => {
          this.initMaintenanceMode();
        }, secondsUntilMaintenance * 1000);
      }
    );

    const storedMaintenanceStartTime = loadState(MAINTENANCE_STORAGE_KEY);

    if (storedMaintenanceStartTime) {
      let maintenanceStartsAt = moment(storedMaintenanceStartTime, DATE_FORMAT);
      this.setState({ maintenanceStartsAt });
      if (maintenanceStartsAt.isAfter(moment())) {
        const secondsUntilMaintenance = moment
          .duration(moment(maintenanceStartsAt, DATE_FORMAT).diff(moment()))
          .asSeconds();

        setTimeout(() => {
          this.initMaintenanceMode();
        }, secondsUntilMaintenance * 1000);
      } else {
        removeItem(MAINTENANCE_STORAGE_KEY);
      }
    }

    this.checkTokenValidityInterval = window.setInterval(this.props.checkTokenValidity, 10000);

    if (this.props.user.profileImage) {
      this.props.getCaregiverProfileImage(this.props.authToken, this.props.user.guid, this.props.user.profileImage.id);
    }

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('focus', this.handleWindowFocus);
  }

  componentWillUnmount() {
    window.clearInterval(this.checkTokenValidityInterval);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('focus', this.handleWindowFocus);
  }

  healthPing() {
    if (this.props.ui.healthPingSuccesses >= HEALTH_PING_SUCCESS_THRESHOLD) {
      clearInterval(this.state.healthPingPollerId);
      this.props.hideDeployInProgressModal();
      this.props.resetHealthPingSuccesses();
      this.props.showMaintenanceCompleteModal();
    } else {
      this.props.healthPing();
    }
  }

  initMaintenanceMode() {
    this.props.showDeployInProgressModal();
    removeItem(MAINTENANCE_STORAGE_KEY);
    const healthPingPollerId = window.setInterval(this.healthPing, 30000);
    this.setState({
      maintenanceStartsAt: undefined,
      healthPingPollerId
    });
  }

  acknowledgeReleaseNotes() {
    this.props.acknowledgeReleaseNotes();
    window.location.reload();
  }

  refreshToken() {
    this.props.manuallyRefreshToken(this.props.authToken, loadSessionState('requestId'));
  }

  handleKeyDown(e) {
    if (this.observedKeys.includes(e.key)) {
      this.keysPressed[e.key] = true;
    }

    if (this.keysPressed['Control'] && this.keysPressed['y']) {
      document.body.classList.toggle('patient-data-hidden');
    }
  }

  handleKeyUp(e) {
    if (this.observedKeys.includes(e.key)) {
      this.keysPressed[e.key] = false;
    }
  }

  handleWindowFocus() {
    this.keysPressed = {};
  }

  render() {
    return (
      <Fragment>
        <div className="anonymous-mode-notice">
          <Translate value="global.anonymous_mode_notice" dangerousHTML />
        </div>
        <div className="columns">
          <div className={`column no-padding main-page-content ${this.props.ui.chatCollapsed ? 'chat-collapsed' : ''}`}>
            {this.state.maintenanceStartsAt ? <MaintenanceBanner startTime={this.state.maintenanceStartsAt} /> : null}
            <Switch>
              <PrivateRoute exact path={`/${APPLICATION_BASE_PATH}`} component={InboxView} />
              <PrivateRoute path={`/${APPLICATION_BASE_PATH}/member/:memberId`} component={PatientView} />
              <PrivateRoute
                path={`/${APPLICATION_BASE_PATH}/members`}
                component={PaginatedMemberList}
                namespace="ACTIVE"
              />
              <PrivateRoute path={`/${APPLICATION_BASE_PATH}/referrals`} component={ReferralsView} />
              <PrivateRoute
                path={`/${APPLICATION_BASE_PATH}/inactive-members`}
                component={PaginatedMemberList}
                namespace="INACTIVE"
              />
              <PrivateRoute path={`/${APPLICATION_BASE_PATH}/unassigned`} component={UnassignedPatientsView} />
              <PrivateRoute path={`/${APPLICATION_BASE_PATH}/priority-errands`} component={PriorityErrandsView} />
              <PrivateRoute
                path={`/${APPLICATION_BASE_PATH}/active-consultations`}
                component={ActiveConsultationsView}
              />
              <PrivateRoute path={`/${APPLICATION_BASE_PATH}/logout`} component={LogoutView} />
            </Switch>
          </div>
          <Sidebar />
        </div>
        <ModalDialog
          headerI18nKey="release_notes.header"
          actionI18nKey="global.buttons.ok"
          visible={!!this.props.releaseSinceLastLogin}
          onClose={this.acknowledgeReleaseNotes}
          onActionCompleted={this.acknowledgeReleaseNotes}
          actionCompletable={true}
          actionCompleting={false}
        >
          <p>
            <Translate value="release_notes.general_info" />
          </p>
          <p className="mt-10">
            <a
              href="https://blodtrycksdoktorn.se/internt/uppdateringar-i-clinic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translate value="release_notes.link_text" />
            </a>
          </p>
        </ModalDialog>
        <ModalDialog headerI18nKey="instance_disabled.header" visible={this.props.ui.instanceDisabled} opaque={true}>
          <p>
            <Translate value="instance_disabled.body" />
          </p>
        </ModalDialog>
        <ModalDialog
          headerI18nKey="about_clinic.header"
          actionI18nKey="global.buttons.ok"
          visible={this.props.ui.ceModalVisible}
          onClose={this.props.hideCeModal}
          onActionCompleted={this.props.hideCeModal}
          actionCompletable={true}
        >
          <div className="columns">
            <div className="column is-9 no-padding">
              <div>Clinic {metadata.version}</div>
              <div>
                <Translate value="about_clinic.accumbo_medical_system" />
              </div>
              <div className="mt-10 fs-14">
                <div>Blodtrycksdoktorn AB</div>
                <div>
                  <Translate value="about_clinic.address" />
                </div>
              </div>
            </div>
            <div className="column no-padding right-text">
              <img src={ceMark} alt="CE" width="60" />
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          headerI18nKey="maintenance_mode.maintenance_warning_header"
          actionI18nKey="global.buttons.ok"
          visible={this.props.ui.maintenanceNoticeModalVisible}
          onClose={this.props.hideMaintenanceModal}
          onActionCompleted={this.props.hideMaintenanceModal}
          actionCompletable={true}
        >
          <p>
            <Translate value="maintenance_mode.maintenance_warning_body" />
          </p>
        </ModalDialog>
        <ModalDialog
          headerI18nKey="maintenance_mode.maintenance_complete_header"
          actionI18nKey="global.buttons.ok"
          visible={this.props.ui.maintenanceCompleteModalVisible}
          onClose={this.props.hideMaintenanceCompleteModal}
          onActionCompleted={this.props.hideMaintenanceCompleteModal}
          actionCompletable={true}
        >
          <p>
            <Translate value="maintenance_mode.maintenance_complete_body" />
          </p>
        </ModalDialog>
        <ModalDialog
          headerI18nKey="maintenance_mode.deploy_in_progress_header"
          visible={this.props.ui.deployModalVisible}
        >
          <p>
            <Translate value="maintenance_mode.deploy_in_progress_body" />
          </p>
        </ModalDialog>
        <ModalDialog
          headerI18nKey="session.token_expiration_warning_header"
          actionI18nKey="global.buttons.ok"
          visible={this.props.tokenExpirationWarningModalVisible}
          onClose={this.refreshToken}
          onActionCompleted={this.refreshToken}
          actionCompletable={true}
          actionCompleting={this.props.manuallyRefreshingToken}
        >
          <p>
            <Translate value="session.token_expiration_warning_body" />
          </p>
        </ModalDialog>
        <Notification {...this.props.notification} onClose={this.props.hideNotification} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tokenExpirationWarningModalVisible: state.auth.tokenExpirationWarningModalVisible,
    manuallyRefreshingToken: state.auth.manuallyRefreshingToken,
    authToken: state.auth.token.jwt,
    releaseSinceLastLogin: state.auth.token.releaseSinceLastLogin,
    ui: state.ui,
    notification: state.notifications,
    user: state.auth.token.user,
    events: state.events,
    isNurse: state.auth.isNurse
  };
};

const mapActionsToProps = {
  getEvents,
  getHandledEvents,
  hideNotification,
  acknowledgeReleaseNotes,
  disableInstance,
  setStompClient,
  setBroadcastChannel,
  hideCeModal,
  getReferralPackages,
  showMaintenanceModal,
  hideMaintenanceModal,
  showDeployInProgressModal,
  hideDeployInProgressModal,
  healthPing,
  resetHealthPingSuccesses,
  hideMaintenanceCompleteModal,
  showMaintenanceCompleteModal,
  checkTokenValidity,
  manuallyRefreshToken,
  getCaregiverProfileImage
};

export default connect(mapStateToProps, mapActionsToProps)(Sentry.withProfiler(App));
