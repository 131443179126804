import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import ToggleSwitch from '../ToggleSwitch';
import LargeInput from '../LargeInput';
import LoadingSpinner from '../LoadingSpinner';
import ModalDialog from '../ModalDialog';
import api from '../../api/apiClient';
import { loginRequest, loginSuccess, loginError, acknowledgeLoginError, showMaintenanceModal } from '../../actions';
import { loadState, saveState, removeItem } from '../../services/localStorage';
import {
  APPLICATION_BASE_PATH,
  bankIdBase6Image,
  API_ERROR_CODES,
  DATE_FORMAT,
  MAINTENANCE_STORAGE_KEY
} from '../../constants';
import './LoginView.scss';
import metadata from '../../metadata.json';

const allowCredentialsLogin =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_CREDENTIALS_LOGIN === 'enabled';

class LoginView extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.toggleCredentialLoginModal = this.toggleCredentialLoginModal.bind(this);
    this.onUsernameInput = this.onUsernameInput.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
    this.checkMaintenanceMode = this.checkMaintenanceMode.bind(this);

    this.state = {
      identifier: '',
      remembered: false,
      credentialLoginModalVisible: false,
      username: '',
      password: ''
    };
  }

  componentDidMount() {
    const storedIdentifier = loadState('identifier');

    if (storedIdentifier) {
      this.setState({
        identifier: storedIdentifier,
        remembered: true
      });
    }
  }

  toggleRememberMe() {
    const storedIdentifier = loadState('identifier');

    this.setState({
      remembered: !this.state.remembered
    });

    if (storedIdentifier) {
      removeItem('identifier');
    } else {
      saveState(this.state.identifier, 'identifier');
    }
  }

  toggleCredentialLoginModal() {
    this.setState({
      credentialLoginModalVisible: !this.state.credentialLoginModalVisible
    });
  }

  onInputChange(event) {
    this.setState({
      identifier: event.target.value
    });
  }

  checkMaintenanceMode(loginResponse) {
    if (loginResponse.secondsUntilMaintenanceMode) {
      this.props.showMaintenanceModal();
      const maintenanceStartsAt = moment().add(loginResponse.secondsUntilMaintenanceMode, 's');
      saveState(maintenanceStartsAt.format(DATE_FORMAT), MAINTENANCE_STORAGE_KEY);
    }
  }

  onSubmit(event) {
    event.preventDefault();

    if (this.state.remembered) {
      saveState(this.state.identifier, 'identifier');
    }

    this.props.onLoginRequest();

    if (allowCredentialsLogin && this.state.credentialLoginModalVisible) {
      this.setState({ credentialLoginModalVisible: false });
      const credentials = { identifier: this.state.username, password: this.state.password, method: 'credentials' };

      api
        .logInTest(credentials)
        .then((result) => {
          this.checkMaintenanceMode(result);
          this.props.onLoginSuccess(result);
        })
        .catch((error) => {
          this.props.onLoginError(error);
        });
    } else {
      const identifier = this.state.identifier.replace('-', '');
      api
        .challenge(identifier)
        .then((result) => {
          return api.login(result.refToken, identifier);
        })
        .then((result) => {
          this.checkMaintenanceMode(result);
          this.props.onLoginSuccess(result);
        })
        .catch((error) => {
          console.log(error);
          this.props.onLoginError(error);
        });
    }
  }

  onUsernameInput(e) {
    this.setState({
      username: e.target.value
    });
  }

  onPasswordInput(e) {
    this.setState({
      password: e.target.value
    });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: `/${APPLICATION_BASE_PATH}` } };

    if (this.props.redirectToReferrer && !this.props.loginError) {
      return <Redirect to={from} />;
    }

    return (
      <Fragment>
        <div className="login-container">
          <div className="login-form">
            {!this.props.isLoggingIn ? (
              <form method="POST" onSubmit={this.onSubmit} autoComplete="off">
                <h1>
                  <Translate value="login_view.header" />
                </h1>
                <LargeInput
                  value={this.state.identifier}
                  onChange={this.onInputChange}
                  name="personal-number"
                  placeholder="login_view.input_placeholder"
                  required={true}
                  pattern="[0-9]{6,}-*[0-9]+"
                />

                <button type="submit">
                  <Translate value="login_view.login_with" />
                </button>
                <span>
                  <Translate value="login_view.remember_me" />
                </span>
                <ToggleSwitch onChange={this.toggleRememberMe} checked={this.state.remembered} />
              </form>
            ) : (
              <div>
                <h1>
                  <Translate value="login_view.start_bank_id" />
                </h1>
                <img src={bankIdBase6Image} alt="BankID" />
                <LoadingSpinner />
              </div>
            )}
          </div>
          <div className="build-number">
            <div>
              Version:{' '}
              <span onClick={allowCredentialsLogin ? this.toggleCredentialLoginModal : undefined}>
                {metadata.version}
              </span>{' '}
              (Build: #{process.env.REACT_APP_BITRISE_BUILD_NUMBER || 0})
            </div>
            <div></div>
          </div>
        </div>
        <ModalDialog
          headerI18nKey="login_view.login_failed_header"
          actionI18nKey="global.buttons.ok"
          visible={!!this.props.loginError}
          onClose={this.props.acknowledgeLoginError}
          onActionCompleted={this.props.acknowledgeLoginError}
          actionCompletable={true}
          actionCompleting={false}
          size="small"
        >
          {this.props.loginError && this.props.loginError.response ? (
            <div className="error-message">
              <Translate
                value={API_ERROR_CODES[JSON.parse(this.props.loginError.response.text).code] || 'server_error.fallback'}
              />
            </div>
          ) : (
            <div className="error-message">
              <Translate value="server_error.connectivity" />
            </div>
          )}
        </ModalDialog>
        <ModalDialog
          header="Inloggning"
          actionI18nKey="login_view.login"
          visible={this.state.credentialLoginModalVisible}
          onClose={this.toggleCredentialLoginModal}
          onActionCompleted={this.onSubmit}
          actionCompletable={!!(this.state.username.length && this.state.password.length)}
          actionCompleting={false}
          size="small"
        >
          <div className="columns">
            <div className="column no-padding">
              <input
                placeholder="E-postadress"
                type="text"
                className="w-100"
                value={this.state.username || ''}
                onChange={this.onUsernameInput}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column no-padding">
              <input
                placeholder="Lösenord"
                type="password"
                className="w-100"
                value={this.state.password || ''}
                onChange={this.onPasswordInput}
              />
            </div>
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    redirectToReferrer: state.auth.redirectToReferrer,
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.error
  };
};

const mapActionsToProps = {
  onLoginRequest: loginRequest,
  onLoginSuccess: loginSuccess,
  onLoginError: loginError,
  acknowledgeLoginError,
  showMaintenanceModal
};

export default connect(mapStateToProps, mapActionsToProps)(LoginView);
