import * as types from '../constants/actionTypes';
import { HTTP_STATUS_CODES } from '../constants';

const sortByDate = (a, b) => {
  if (a.createdDate === b.createdDate) {
    return 0;
  }

  if (a.createdDate > b.createdDate) {
    return -1;
  }

  return 1;
};

const initialState = {
  jiraIssues: [],
  modalVisible: false,
  selectedSupportType: null,
  selectedSupportTypeId: 0,
  issueDescription: '',
  issueSummary: '',
  issueTypes: [
    {
      id: 2,
      type: 'Support case',
      summary: 'Ta kontakt med denna patient',
      description: 'Jag får inget svar från denna patient. Kan ni ta kontakt?'
    },
    {
      id: 3,
      type: 'Support case',
      summary: 'Skicka mätare',
      description: 'Skicka ut mätare till denna patient. Anledning: '
    },
    {
      id: 4,
      type: 'Support case',
      summary: 'Problem med mätare',
      description: 'Denna patient har följande problem med sin blodtrycksmätare: '
    },
    {
      id: 5,
      type: 'Remissutskick',
      summary: 'Remiss för Skåne-patient',
      description: 'Skicka fysisk remiss för Region Skåne till denna patient.'
    },
    {
      id: 6,
      type: 'Support case',
      summary: 'Annat ärende',
      description: ''
    },
    {
      id: 1,
      type: 'Support case',
      summary: 'Avaktivera patient',
      description: 'Kan ni avaktivera denna patient? Anledning: '
    }
  ]
};

const jiraReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEMBER_JIRA_ISSUES_REQUEST:
      return { ...state, loadingJiraIssues: true };
    case types.GET_MEMBER_JIRA_ISSUES_SUCCESS:
      return {
        ...state,
        loadingJiraIssues: false,
        jiraIssues: action.payload.sort(sortByDate),
        jiraIssuesError: undefined
      };
    case types.GET_MEMBER_JIRA_ISSUES_ERROR:
      return {
        ...state,
        loadingJiraIssues: false,
        jiraIssuesError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.POST_MEMBER_JIRA_ISSUE_REQUEST:
      return { ...state, creatingIssue: true };
    case types.POST_MEMBER_JIRA_ISSUE_SUCCESS:
      return {
        ...state,
        creatingIssue: false,
        modalVisible: false,
        jiraIssues: [action.payload, ...state.jiraIssues],
        selectedSupportType: null,
        selectedSupportTypeId: 0,
        createIssueError: undefined
      };
    case types.POST_MEMBER_JIRA_ISSUE_ERROR:
      return { ...state, creatingIssue: false, createIssueError: action.payload };
    case types.SHOW_JIRA_MODAL:
      return { ...state, modalVisible: true };
    case types.HIDE_JIRA_MODAL:
      return { ...state, modalVisible: false };
    case types.SET_JIRA_ISSUE_TYPE: {
      const selectedSupportType = state.issueTypes.find((type) => type.id === action.payload);
      return {
        ...state,
        selectedSupportType,
        issueDescription: selectedSupportType && selectedSupportType.description,
        selectedSupportTypeId: action.payload
      };
    }
    case types.SET_JIRA_ISSUE_DESCRIPTION:
      return { ...state, issueDescription: action.payload };
    case types.CLEAR_CURRENT_MEMBER:
      return initialState;
    default:
      return state;
  }
};

export default jiraReducer;
