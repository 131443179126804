import React, { Component, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { decamelize } from '../../../../../utils';
import './CheckupRow.scss';

class CheckupRow extends Component {
  render() {
    const { attributeI18n, checkup } = this.props;
    const caregiverType = checkup.type.includes('nurse') ? 'nurse' : 'doctor';

    if (!attributeI18n || !checkup) return null;

    let checkupReasonsDataTip;

    if (checkup.checkupReasons && checkup.checkupReasons.length) {
      checkupReasonsDataTip = checkup.checkupReasons.map((r) => r.leadText).join('<br />');
    }

    if (checkup.checkupReasonFreeText) {
      checkupReasonsDataTip
        ? (checkupReasonsDataTip += '<br />' + checkup.checkupReasonFreeText)
        : (checkupReasonsDataTip = checkup.checkupReasonFreeText);
    }

    return (
      <Fragment>
        <div className="checkup-row row-container">
          <div className="columns table-row">
            <div className="column row-category is-4">
              <Translate value={attributeI18n} />
            </div>
            <div className="column row-response is-2">{moment(checkup.checkupDate).format('YYYY-MM-DD')}</div>
            <div className="column is-5">
              <Translate value={`global.checkup_type.${decamelize(checkup.type)}`} />,{' '}
              <span className="caregiver-type">
                <Translate
                  value={`decision_support_view.checkup_section.checkup_timeline.caregiver_type.${caregiverType}`}
                />
              </span>
              {checkup.type.toLowerCase().includes('reminder') && checkupReasonsDataTip ? (
                <span className="icons info checkup-reasons-icon" data-tip={checkupReasonsDataTip}></span>
              ) : null}
            </div>
          </div>
        </div>
        <ReactTooltip multiline={true} effect="solid" className="custom-tooltip" />
      </Fragment>
    );
  }
}

export default CheckupRow;
