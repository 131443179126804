import uuidv4 from 'uuid/v4';
import { HTTP_STATUS_CODES } from '../constants';
import * as types from '../constants/actionTypes';

const initialState = {
  dayNoteModalVisible: false,
  dayNoteContent: '',
  notes: [],
  chatMessages: [],
  showOnlyOwnNotes: false,
  memberComment: {},
  memberCommentText: ''
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTES_REQUEST:
      return { ...state, loadingNotes: true };
    case types.GET_NOTES_SUCCESS:
      return {
        ...state,
        loadingNotes: false,
        notes: action.payload.map((note) => {
          return {
            id: uuidv4(),
            ...note
          };
        }),
        latestRiskNote: action.payload.filter((note) => {
          return note.noteType === 'risknote';
        })[0] // Kanske får sortera på timestamp här först
      };
    case types.GET_NOTES_ERROR:
      return { ...state, loadingNotes: false, notesError: action.payload };
    case types.CLEAR_NOTES:
      return initialState;
    case types.ADD_NOTE_REQUEST:
      return { ...state, addingNote: true };
    case types.ADD_NOTE_SUCCESS:
      return {
        ...state,
        notes: [{ id: uuidv4(), ...action.payload }, ...state.notes],
        addingNote: false,
        latestRiskNote: action.payload.noteType === 'risknote' ? action.payload : state.latestRiskNote
      };
    case types.ADD_NOTE_ERROR:
      return { ...state, addingNote: false, addNoteError: action.payload };
    case types.ADD_DAY_NOTE_REQUEST:
      return { ...state, savingDayNote: true };
    case types.ADD_DAY_NOTE_SUCCESS:
      return {
        ...state,
        savingDayNote: false,
        notes: [{ id: uuidv4(), ...action.payload }, ...state.notes],
        dayNoteContent: '',
        dayNoteModalVisible: false,
        dayNoteError: undefined
      };
    case types.ADD_DAY_NOTE_ERROR:
      return { ...state, savingDayNote: false, dayNoteError: action.payload };
    case types.SHOW_DAY_NOTE_MODAL:
      return { ...state, dayNoteModalVisible: true };
    case types.HIDE_DAY_NOTE_MODAL:
      return { ...state, dayNoteModalVisible: false };
    case types.UPDATE_DAY_NOTE_CONTENT:
      return { ...state, dayNoteContent: action.payload };
    case types.TOGGLE_SHOW_ONLY_OWN_NOTES:
      return { ...state, showOnlyOwnNotes: !state.showOnlyOwnNotes };
    case types.GET_CHAT_MESSAGES_SUCCESS:
      if (state.chatMessages.length) {
        return state;
      }

      return {
        ...state,
        chatMessages: action.payload.messages
          .filter((m) => m.type === 'message')
          .map((m) => ({ timestamp: m.timestamp, id: m.id }))
      };
    case types.GET_MEMBER_COMMENT_REQUEST:
      return { ...state, loadingMemberComment: true };
    case types.GET_MEMBER_COMMENT_SUCCESS:
      return {
        ...state,
        loadingMemberComment: false,
        memberComment: action.payload,
        memberCommentText: action.payload.text,
        memberCommentError: undefined
      };
    case types.GET_MEMBER_COMMENT_ERROR:
      return {
        ...state,
        loadingMemberComment: false,
        memberCommentError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.SAVE_MEMBER_COMMENT_REQUEST:
      return { ...state, savingMemberComment: true };
    case types.SAVE_MEMBER_COMMENT_SUCCESS:
      return {
        ...state,
        savingMemberComment: false,
        memberComment: action.payload,
        memberCommentText: action.payload.text,
        memberCommentSaveError: undefined
      };
    case types.SAVE_MEMBER_COMMENT_ERROR:
      return { ...state, savingMemberComment: false, memberCommentSaveError: action.payload };
    case types.UPDATE_MEMBER_COMMENT_TEXT:
      return { ...state, memberCommentText: action.text };
    default:
      return state;
  }
};

export default notesReducer;
