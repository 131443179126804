import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import metadata from '../../metadata.json';
import ChatHeader from './ChatHeader';
import ChatItem from './ChatItem';
import ChatFooter from './ChatFooter';
import {
  CHAT_POLL_RATE_IN_MS,
  CHAT_THUMBNAIL_WIDTH,
  HTTP_STATUS_CODES,
  CURRENT_ENV,
  FREE_TEXT_TEMPLATE_TAG_REPLACEMENT
} from '../../constants';
import {
  getChatMessages,
  postChatMessage,
  getNotes,
  previewFile,
  showFullSizeImage,
  deleteChatMessageDraft,
  updateDraftCountdown,
  startEditingDraft,
  updateDraftText,
  setChatMessageHighlight,
  toggleChatMessageFilter,
  setChatImageUrl
} from '../../actions';
import './PatientChat.scss';
import ModalDialog from '../ModalDialog/index.js';
import { DebounceInput } from 'react-debounce-input';
import ChatItemSkeleton from './ChatItemSkeleton';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');

class PatientChat extends Component {
  constructor(props) {
    super(props);

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.postChatMessage = this.postChatMessage.bind(this);
    this.fetchNewMessages = this.fetchNewMessages.bind(this);
    this.preview = this.preview.bind(this);
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
    this.startEditingDraft = this.startEditingDraft.bind(this);
    this.highlightMatchedText = this.highlightMatchedText.bind(this);

    this.chatContainer = React.createRef();
    this.chatWrapper = React.createRef();
    this.pollerId = null;

    const observerOptions = {
      root: document.querySelector('#chat-messages-container'),
      rootMargin: '0px',
      threshold: 0
    };

    this.intersectionObserver = new IntersectionObserver(this.intersectionObserverCallback, observerOptions);
  }

  componentDidMount() {
    const observer = new MutationObserver(this.scrollToBottom);
    const config = { childList: true };
    observer.observe(this.chatWrapper.current, config);

    this.fetchNewMessages(true);

    this.pollerId = window.setInterval(this.fetchNewMessages, CHAT_POLL_RATE_IN_MS);

    this.countdownUpdater = window.setInterval(this.props.updateDraftCountdown, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.chatMessages.length !== prevProps.chatMessages.length) {
      const thumbnails = document.querySelectorAll('.chat-message__thumbnail');
      thumbnails.forEach((thumbnail) => this.intersectionObserver.observe(thumbnail));
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.pollerId);
    window.clearInterval(this.countdownUpdater);
  }

  fetchNewMessages(scrollToBottom) {
    this.props.getChatMessages(
      this.props.authToken,
      this.props.memberGuid,
      scrollToBottom ? this.scrollToBottom : undefined
    );
  }

  scrollToBottom() {
    if (!this.props.chat.messageFilters.length) {
      this.chatContainer.current.scrollTop = this.chatContainer.current.scrollHeight;
    }
  }

  postChatMessage(chatMessage, fileInfo) {
    if (!chatMessage.message.includes(FREE_TEXT_TEMPLATE_TAG_REPLACEMENT)) {
      this.props.postChatMessage(
        this.props.authToken,
        this.props.memberGuid,
        chatMessage,
        fileInfo,
        `${this.props.user.givenName} ${this.props.user.familyName}`,
        this.props.chat.responseType,
        this.props.chat.hasEditedDraft
      );
    } else {
      window.alert(I18n.t('canned_response.free_text_alert', { tag: FREE_TEXT_TEMPLATE_TAG_REPLACEMENT }));
    }
  }

  deleteDraft(messageId) {
    return () => {
      if (window.confirm(I18n.t('patient_view.chat.draft.confirm_deletion'))) {
        this.props.deleteChatMessageDraft(this.props.authToken, this.props.memberGuid, messageId);
      }
    };
  }

  startEditingDraft(messageId) {
    return () => {
      this.props.startEditingDraft(this.props.authToken, this.props.memberGuid, messageId);
    };
  }

  preview(fileId, filename, contentType) {
    const { authToken, member, previewFile } = this.props;
    previewFile(authToken.jwt, member.guid, fileId, filename, contentType);
  }

  intersectionObserverCallback(entries) {
    entries.forEach((entry) => {
      const imagePath = entry.target.getAttribute('data-thumbnail-image-src');
      const messageId = entry.target.getAttribute('data-message-id');
      if (entry.isIntersecting && imagePath) {
        fetch(`${basePath}${imagePath}?maxWidth=${CHAT_THUMBNAIL_WIDTH}`, {
          headers: {
            Authorization: `Bearer ${this.props.authToken.jwt}`,
            'Accumbo-Client-ID': this.props.clientId
          }
        })
          .then((response) => response.blob())
          .then((image) => {
            this.intersectionObserver.unobserve(entry.target);
            this.props.setChatImageUrl(URL.createObjectURL(image), messageId);
          })
          .catch(() => {
            entry.target.innerHTML = `<span class="error-message">${I18n.t(
              'patient_view.chat.image_thumbnail_error'
            )}</span>`;
          });
      }
    });
  }

  highlightMatchedText(id, searchText, type) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    this.props.setChatMessageHighlight(id, searchText, type);
  }

  render() {
    const { member, chatMessages, chatError, i18n, showFullSizeImage, chat, user, updateDraftText, dragHandle } =
      this.props;
    const hasError = chatError && chatError.status !== 404;

    return (
      <Fragment>
        <div className="patient-chat">
          <ChatHeader
            member={member}
            showFullSizeImage={showFullSizeImage}
            chatMessages={chatMessages}
            highlightMatchedText={this.highlightMatchedText}
            onToggleFilter={this.props.toggleChatMessageFilter}
            activeFilters={chat.messageFilters}
          />
          <div className="chat-messages-container" id="chat-messages-container" ref={this.chatContainer}>
            {dragHandle}
            <div className="member-chat-messages" ref={this.chatWrapper}>
              {chat.isFetchingInitial
                ? [0, 1, 2, 3, 4, 5, 6].map((i) => {
                    return <ChatItemSkeleton key={i} index={i} />;
                  })
                : null}
              {hasError && !chatMessages.length ? (
                <div className="error-message">
                  <Translate value="global.error_loading_data" />
                </div>
              ) : (
                chatMessages.map((message) => {
                  return (
                    <ChatItem
                      message={message}
                      key={message.id}
                      member={member}
                      user={user}
                      dateTimeFormat={i18n.translations[i18n.locale].time.chat_timestamp_format}
                      locale={i18n.locale}
                      onPreview={this.preview}
                      onThumbnailClick={showFullSizeImage}
                      onDelete={this.deleteDraft}
                      countdownText={chat.countdownText}
                      showDraftActions={chat.showDraftActions}
                      onStartEdit={this.startEditingDraft}
                      activeFilters={chat.messageFilters}
                    />
                  );
                })
              )}
            </div>
          </div>
          {chatMessages.length && hasError ? (
            <div className="error-message polling-error">
              <Translate
                value={`patient_view.chat.${
                  chatError.status && chatError.status === HTTP_STATUS_CODES.CONFLICT
                    ? 'error_conflict'
                    : 'polling_error'
                }`}
              />
            </div>
          ) : null}
          <ChatFooter
            onSubmit={this.postChatMessage}
            memberName={`${member.givenName} ${member.familyName}`}
            canSendMessage={chat.canSendMessage}
          />
        </div>
        <ModalDialog
          headerI18nKey="patient_view.chat.draft.edit_header"
          actionI18nKey="patient_view.chat.draft.send_edit"
          visible={chat.editDraftModalActive}
          onClose={() => {
            this.postChatMessage({ message: chat.originalDraftText, messageType: 'draft' });
          }}
          onActionCompleted={() => {
            this.postChatMessage({ message: chat.newDraftText, messageType: 'draft' });
          }}
          actionCompletable={chat.canSendDraftUpdate}
          actionCompleting={false}
          size="medium"
        >
          <div className="chat-modal-content">
            <h3>
              <Translate value="patient_view.chat.draft.countdown_stopped" />
            </h3>
            <DebounceInput value={chat.newDraftText || ''} element="textarea" onChange={updateDraftText} />
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token,
    clientId: state.auth.clientId,
    user: state.auth.token.user,
    chat: state.patientChat,
    chatMessages: state.patientChat.chatMessages,
    chatError: state.patientChat.error,
    member: state.currentMember,
    i18n: state.i18n
  };
};

const mapActionsToProps = {
  getChatMessages,
  postChatMessage,
  getNotes,
  previewFile,
  showFullSizeImage,
  deleteChatMessageDraft,
  updateDraftCountdown,
  startEditingDraft,
  updateDraftText,
  setChatMessageHighlight,
  toggleChatMessageFilter,
  setChatImageUrl
};

export default connect(mapStateToProps, mapActionsToProps)(PatientChat);

export const PatientChatErrorFallback = () => {
  return (
    <div className="patient-chat center-align">
      <div className="block">
        <div>
          <Translate value="patient_view.chat.error.title" />
        </div>
        <div>
          <Translate value="patient_view.chat.error.body" />
        </div>
      </div>
    </div>
  );
};
