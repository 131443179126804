import * as types from '../constants/actionTypes';
import moment from 'moment';
import flatten from 'flatten';
import {
  ALLERGY_DSS_ITEM_ID,
  DATE_FORMAT,
  MEDICATION_CHANGE_ITEM_ID,
  MEDICATION_CHANGE_REASON_ITEM_ID,
  HTTP_STATUS_CODES,
  OCCUPATION_DSS_ITEM_ID,
  HEREDITY_DSS_ITEM_ID,
  PREVIOUS_DISEASE_DSS_ITEM_ID,
  CURRENT_DISEASE_DSS_ITEM_ID
} from '../constants';
import { getNumberOfColumnsForWidth, updateLabResultColumnWidth, getWeightObjectivesDiffs } from '../utils';
import { transformLabResultsData } from '../utils/labResultsHelper';

const updateDssItem = (action) => (dssItem) => {
  if (dssItem.itemId !== action.payload.itemId) {
    return dssItem;
  }

  return action.payload;
};

const initialState = {
  hasUnsavedChanges: false,
  hasUnsavedBloodPressureGoalChanges: false,
  allergyDssItemComments: [],
  finalNoteModalActive: false,
  finalNoteConfirmationModalActive: false,
  transformedLabResults: {
    fixedColumns: [],
    labResultColumns: [],
    labResults: []
  },
  showLabEvaluation: true,
  chatCollapsed: false,
  selectedRiskFactors: [],
  riskProfile: {
    adjustableFactors: []
  },
  anamnesisUpdateSummary: [],
  medicationChangesSummary: [],
  medicineExtras: {
    supplements: [],
    medicineChanges: []
  },
  status: { finalNoteReasonsAllowed: [] },
  finalNoteContent: {},
  periodSize: 14,
  currentPeriods: [],
  lastTwoWeekAverage: null,
  isCleared: true,
  hasWrittenFinalNote: false,
  hasFetchedStatusPage: false
};

const decisionSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_DSS_DATA:
      return { ...initialState };
    case types.GET_DECISION_SUPPORT_SUMMARY_REQUEST:
      return { ...state, isLoadingSummary: true };
    case types.GET_DECISION_SUPPORT_SUMMARY_SUCCESS: {
      const allergyItem = action.payload.items.filter((item) => item.itemId === ALLERGY_DSS_ITEM_ID)[0];
      const medicationChangeItem = action.payload.items.filter((item) => item.itemId === MEDICATION_CHANGE_ITEM_ID)[0];
      const medicationChangeReasonItem = action.payload.items.filter(
        (item) => item.itemId === MEDICATION_CHANGE_REASON_ITEM_ID
      )[0];
      let allergyComments = [];
      let newAllergyComments = [];

      if (allergyItem && allergyItem.value && allergyItem.value.comments) {
        allergyComments = allergyItem.value.comments.map((comments) => comments.comment);
      }

      if (state.allergyDssItemComments.length) {
        newAllergyComments = state.allergyDssItemComments;
      } else {
        newAllergyComments = allergyComments;
      }

      return {
        ...state,
        isCleared: false,
        isLoadingSummary: false,
        summary: action.payload,
        allergyItem,
        allergyDssItemComments: newAllergyComments,
        medicationChangesSummary: [medicationChangeItem, medicationChangeReasonItem],
        finalNoteContent: {
          occupation: action.payload.items.filter((item) => item.itemId === OCCUPATION_DSS_ITEM_ID)[0],
          heredity: action.payload.items.filter((item) => item.itemId === HEREDITY_DSS_ITEM_ID)[0],
          previousDiseases: action.payload.items.filter((item) => item.itemId === PREVIOUS_DISEASE_DSS_ITEM_ID)[0],
          currentDiseases: action.payload.items.filter((item) => item.itemId === CURRENT_DISEASE_DSS_ITEM_ID)[0]
        },
        summaryError: undefined
      };
    }
    case types.GET_DECISION_SUPPORT_SUMMARY_ERROR:
      return { ...state, isLoadingSummary: false, summaryError: action.payload };
    case types.ADD_DSS_OVERVIEW_ITEM_COMMENT_REQUEST:
      return { ...state, postingComment: true };
    case types.ADD_DSS_OVERVIEW_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        isCleared: false,
        allergyDssItemComments:
          action.payload.itemId === ALLERGY_DSS_ITEM_ID
            ? action.payload.value.comments && action.payload.value.comments.map((comments) => comments.comment)
            : state.allergyDssItemComments,
        postingComment: false,
        itemComment: undefined,
        commentModified: undefined,
        summary: {
          ...state.summary,
          items: state.summary.items.map(updateDssItem(action))
        },
        riskProfile: {
          ...state.riskProfile,
          risk: state.riskProfile && state.riskProfile.risk && state.riskProfile.risk.map(updateDssItem(action))
        },
        treatmentGoal: state.treatmentGoal && state.treatmentGoal.map(updateDssItem(action)),
        medicineExtras: {
          supplements: state.medicineExtras.supplements.map(updateDssItem(action)),
          medicineChanges: state.medicineExtras.medicineChanges.map(updateDssItem(action))
        }
      };
    case types.ADD_DSS_OVERVIEW_ITEM_COMMENT_ERROR:
      return { ...state, postingComment: false, addOverviewCommentError: action.payload };
    case types.SET_DSS_RELEVANT_VALUES_REQUEST:
      return { ...state, settingRelevantValues: true };
    case types.SET_DSS_RELEVANT_VALUES_SUCCESS:
      return {
        ...state,
        isCleared: false,
        settingRelevantValues: false,
        relevantValuesToUpdate: undefined,
        summary: {
          ...state.summary,
          items: state.summary.items.map(updateDssItem(action))
        }
      };
    case types.SET_DSS_RELEVANT_VALUES_ERROR:
      return { ...state, settingRelevantValues: false, relevantValuesError: action.payload };
    case types.GET_DSS_ITEM_HISTORY_REQUEST:
      return { ...state, loadingItemHistory: true };
    case types.GET_DSS_ITEM_HISTORY_SUCCESS:
      return {
        ...state,
        isCleared: false,
        loadingItemHistory: false,
        currentItemHistory: action.payload,
        currentItemHistoryError: undefined
      };
    case types.GET_DSS_ITEM_HISTORY_ERROR:
      return {
        ...state,
        loadingItemHistory: false,
        currentItemHistory: undefined,
        currentItemHistoryError: action.payload
      };
    case types.GET_DSS_ALLERGY_ITEM_REQUEST:
      return { ...state, loadingAllergyDssItemComments: true };
    case types.GET_DSS_ALLERGY_ITEM_SUCCESS: {
      const historicalAllergyComments = flatten(
        action.payload.map((historyItem) => {
          if (!historyItem.valueHistory) return null;
          return historyItem.valueHistory
            .filter((valueHistoryItem) => valueHistoryItem.comments !== null)
            .map((valueHistoryItem) => {
              if (valueHistoryItem.comments) {
                return valueHistoryItem.comments.map((comment) => {
                  if (comment.comment) return comment.comment;
                  return null;
                });
              }
              return null;
            });
        })
      ).filter((v) => v !== null);

      return {
        ...state,
        isCleared: false,
        loadingAllergyDssItemComments: false,
        allergyDssItemComments: historicalAllergyComments,
        allergyDssItemCommentsError: undefined
      };
    }
    case types.GET_DSS_ALLERGY_ITEM_ERROR:
      return { ...state, loadingAllergyDssItemComments: false, allergyDssItemCommentsError: action.payload };
    case types.SET_DSS_CURRENT_ITEM_NUMBER:
      return { ...state, currentItemNumber: action.payload };
    case types.SET_DSS_RELEVANT_VALUES_TO_UPDATE:
      return { ...state, relevantValuesToUpdate: action.payload };
    case types.SET_DSS_ITEM_COMMENT:
      return { ...state, itemComment: action.payload, commentModified: true };
    case types.CLEAR_DSS_ITEM_EDIT_VALUES:
      return {
        ...state,
        currentItemNumber: undefined,
        relevantValuesToUpdate: undefined,
        itemComment: undefined,
        commentModified: undefined
      };
    case types.GET_DSS_MEASUREMENT_STATUS_REQUEST:
      return { ...state, isLoadingMeasurementStatus: true };
    case types.GET_DSS_MEASUREMENT_STATUS_SUCCESS:
      return {
        ...state,
        isCleared: false,
        isLoadingMeasurementStatus: false,
        measurementStatus: action.payload,
        measurementStatusError: undefined
      };
    case types.GET_DSS_MEASUREMENT_STATUS_ERROR:
      return { ...state, isLoadingMeasurementStatus: false, measurementStatusError: action.payload };
    case types.GET_DSS_BLOOD_PRESSURE_SUMMARY_REQUEST:
      return { ...state, isLoadingBloodPressureSummary: true };
    case types.GET_DSS_BLOOD_PRESSURE_SUMMARY_SUCCESS: {
      const variationData = [];

      variationData[0] = {
        attributeI18n: 'decision_support_view.average_last_three_months',
        value: action.payload.average3months
          ? `${action.payload.average3months.systolic}/${action.payload.average3months.diastolic} mmHg`
          : null
      };

      variationData[1] = {
        attributeI18n: 'decision_support_view.variation_systolic',
        value: action.payload.variationSystolic
          ? action.payload.variationSystolic
              .map(
                (variation, i) => `${variation} mmHg${i !== action.payload.variationSystolic.length - 1 ? ', ' : ''}`
              )
              .join('')
          : null
      };

      variationData[2] = {
        attributeI18n: 'decision_support_view.variation_diastolic',
        value: action.payload.variationDiastolic
          ? action.payload.variationDiastolic
              .map(
                (variation, i) => `${variation} mmHg${i !== action.payload.variationDiastolic.length - 1 ? ', ' : ''}`
              )
              .join('')
          : null
      };

      const specialMeasurements = [
        {
          attributeI18n: 'decision_support_view.orthostatic_hypotension',
          value: action.payload.orthostaticHypotension
        }
      ];

      return {
        ...state,
        isCleared: false,
        isLoadingBloodPressureSummary: false,
        bloodPressureSummary: { ...action.payload, variationData, specialMeasurements },
        bloodPressureSummaryError: undefined
      };
    }
    case types.GET_DSS_BLOOD_PRESSURE_SUMMARY_ERROR:
      return { ...state, isLoadingBloodPressureSummary: false, bloodPressureSummaryError: action.payload };
    case types.GET_LAB_RESULTS_REQUEST:
      return { ...state, isLoadingLabResults: true };
    case types.GET_LAB_RESULTS_SUCCESS: {
      const transformedLabResults = transformLabResultsData(action.payload, action.locale);
      const totalLabResultColumns = transformedLabResults.labResultColumns.length;
      const labResultsStartIndex = 0;
      const labResultsEndIndex = getNumberOfColumnsForWidth(
        action.windowWidth,
        state.showLabEvaluation,
        state.chatCollapsed
      );

      return {
        ...state,
        isCleared: false,
        isLoadingLabResults: false,
        labResults: action.payload,
        labResultsError: undefined,
        transformedLabResults,
        labResultsStartIndex,
        labResultsEndIndex,
        totalLabResultColumns
      };
    }
    case types.GET_LAB_RESULTS_ERROR:
      return { ...state, isLoadingLabResults: false, labResultsError: action.payload };
    case types.VIEW_OLDER_LAB_RESULTS: {
      const olderLabResultsStartIndex = state.labResultsStartIndex + 1;
      const olderLabResultsEndIndex = state.labResultsEndIndex + 1;

      return { ...state, labResultsStartIndex: olderLabResultsStartIndex, labResultsEndIndex: olderLabResultsEndIndex };
    }
    case types.VIEW_NEWER_LAB_RESULTS: {
      const newerLabResultsStartIndex = state.labResultsStartIndex - 1;
      const newerLabResultsEndIndex = state.labResultsEndIndex - 1;

      return { ...state, labResultsStartIndex: newerLabResultsStartIndex, labResultsEndIndex: newerLabResultsEndIndex };
    }
    case types.TOGGLE_SHOW_LAB_EVALUATION: {
      let newShowLabEvaluationState = !state.showLabEvaluation;

      return {
        ...state,
        showLabEvaluation: newShowLabEvaluationState,
        transformedLabResults: {
          ...state.transformedLabResults,
          labResultColumns: updateLabResultColumnWidth(
            state.transformedLabResults.labResultColumns,
            newShowLabEvaluationState
          )
        },
        labResultsEndIndex:
          state.labResultsStartIndex +
          getNumberOfColumnsForWidth(action.windowWidth, newShowLabEvaluationState, state.chatCollapsed)
      };
    }
    case types.GET_BLOOD_PRESSURE_TREND_REQUEST:
      return { ...state, isLoadingTrends: true };
    case types.GET_BLOOD_PRESSURE_TREND_SUCCESS: {
      let lastPeriod = 0;

      if (action.payload.length) {
        if (state.lastPeriod === undefined || action.payload[action.payload.length - 1].periodId > state.lastPeriod) {
          lastPeriod = action.payload[action.payload.length - 1].periodId;
        } else {
          lastPeriod = state.lastPeriod;
        }
      }

      const firstPeriod = action.payload.length ? action.payload[0].periodId : -1;
      const trendPeriods = action.payload.map((p, i) => {
        let interval;

        switch (state.periodSize) {
          case 14:
            interval = `${p.periodId + (firstPeriod + i + 1)}-${p.periodId + (firstPeriod + i + 2)}`;
            break;
          case 90:
            interval = `${p.periodId * 90 + 1}-${p.periodId * 90 + 90}`;
            break;
          default:
            interval = `${p.periodId + 1}`;
        }

        return {
          ...p,
          interval
        };
      });

      return {
        ...state,
        isCleared: false,
        isLoadingTrends: false,
        bloodPressureTrends: trendPeriods,
        lastPeriod,
        currentPeriods: action.payload.map((period) => period.periodId)
      };
    }
    case types.GET_TWO_WEEK_TREND_SUCCESS:
      return {
        ...state,
        isCleared: false,
        lastTwoWeekAverage: action.payload.length ? action.payload[action.payload.length - 1] : null
      };
    case types.GET_TWO_WEEK_TREND_ERROR:
      return { ...state, lastTwoWeekAverage: null };
    case types.GET_BLOOD_PRESSURE_TREND_ERROR:
      return { ...state, isLoadingTrends: false, error: action.payload };
    case types.SET_TREND_PERIOD_SIZE: {
      let last;

      if (state.periodSize === action.payload) {
        last = state.lastPeriod;
      }

      return { ...state, periodSize: action.payload || 14, lastPeriod: last };
    }
    case types.GET_DSS_RISK_PROFILE_REQUEST:
      return { ...state, isLoadingRiskProfile: true };
    case types.GET_DSS_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        isCleared: false,
        isLoadingRiskProfile: false,
        riskProfile: action.payload,
        treatmentGoal: [action.payload.memberGoal],
        goalSystolic: action.payload.goalSystolic,
        goalDiastolic: action.payload.goalDiastolic,
        measureFrequencyWeekly: action.payload.measureFrequencyWeekly,
        selectedRiskFactors: action.payload.adjustableFactors
          ? action.payload.adjustableFactors
              .filter((riskFactor) => riskFactor.recommendation && riskFactor.recommendation.selected)
              .map((riskFactor) => riskFactor.id)
          : state.selectedRiskFactors,
        riskProfileError: undefined
      };
    case types.GET_DSS_RISK_PROFILE_ERROR:
      return { ...state, isLoadingRiskProfile: false, riskProfileError: action.payload };
    case types.TOGGLE_RISK_FACTOR_RECOMMENDATION: {
      let selectedRiskFactors = [...state.selectedRiskFactors];
      const recommendationIndex = selectedRiskFactors.indexOf(action.payload);

      if (recommendationIndex > -1) {
        selectedRiskFactors.splice(recommendationIndex, 1);
      } else {
        selectedRiskFactors = [...selectedRiskFactors, action.payload];
      }

      return { ...state, selectedRiskFactors, hasUnsavedChanges: true };
    }
    case types.GET_OBJECTIVES_REQUEST:
      return { ...state, loadingObjectives: true };
    case types.GET_OBJECTIVES_SUCCESS:
      return {
        ...state,
        isCleared: false,
        loadingObjectives: false,
        memberObjectives: {
          ...action.payload,
          weightObjectiveProgress: getWeightObjectivesDiffs(action.payload.weightObjectiveProgress)
        },
        objectivesError: undefined
      };
    case types.GET_OBJECTIVES_ERROR:
      return { ...state, loadingObjectives: false, objectivesError: action.payload };
    case types.POST_RISK_FACTOR_RECOMMENDATIONS_REQUEST:
      return { ...state, postingRiskFactorRecommendations: true };
    case types.POST_RISK_FACTOR_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        isCleared: false,
        postingRiskFactorRecommendations: false,
        riskFactor: action.payload,
        riskFactorRecommendationsPostError: undefined
      };
    case types.POST_RISK_FACTOR_RECOMMENDATIONS_ERROR:
      return { ...state, postingRiskFactorRecommendations: false, riskFactorRecommendationsPostError: action.payload };
    case types.GET_DSS_STATUS_REQUEST:
      return { ...state, isLoadingStatus: true };
    case types.GET_DSS_STATUS_SUCCESS: {
      const statusTableData = [];

      statusTableData[0] = {
        attributeI18n: 'decision_support_view.notes_section.last_signed',
        value: action.payload.signedTimestamp
          ? moment(action.payload.signedTimestamp, DATE_FORMAT).format('YYYY-MM-DD')
          : '-'
      };

      const status = {
        ...action.payload,
        isInitialCheckup:
          action.payload.finalNoteReasonsAllowed.length === 1 &&
          action.payload.finalNoteReasonsAllowed[0] === 'initialCheckup'
      };

      return {
        ...state,
        isCleared: false,
        isLoadingStatus: false,
        status,
        statusTableData,
        hasFetchedStatusPage: true,
        statusError: undefined
      };
    }
    case types.GET_DSS_STATUS_ERROR:
      return { ...state, isLoadingStatus: false, statusError: action.payload };
    case types.DSS_SET_MEASUREMENT_FREQUENCY:
      return { ...state, measureFrequencyWeekly: action.payload, hasUnsavedBloodPressureGoalChanges: true };
    case types.SET_DSS_GOAL_SYSTOLIC:
      return { ...state, goalSystolic: action.payload, hasUnsavedBloodPressureGoalChanges: true };
    case types.SET_DSS_GOAL_DIASTOLIC:
      return { ...state, goalDiastolic: action.payload, hasUnsavedBloodPressureGoalChanges: true };
    case types.SIGN_DSS_REQUEST:
      return { ...state, isLoadingSigning: true };
    case types.SIGN_DSS_SUCCESS:
      return {
        ...state,
        isCleared: false,
        isLoadingSigning: false,
        hasUnsavedChanges: false,
        signingError: undefined,
        signingSuccess: true,
        finalNoteConfirmationModalActive: false
      };
    case types.SIGN_DSS_ERROR:
      return { ...state, isLoadingSigning: false, signingError: action.payload, signingSuccess: false };
    case types.SHOW_FINAL_NOTE_MODAL:
      return { ...state, finalNoteModalActive: true };
    case types.HIDE_FINAL_NOTE_MODAL:
      return { ...state, finalNoteModalActive: false };
    case types.SHOW_FINAL_NOTE_CONFIRMATION_MODAL:
      return { ...state, finalNoteConfirmationModalActive: true };
    case types.HIDE_FINAL_NOTE_CONFIRMATION_MODAL:
      return { ...state, finalNoteConfirmationModalActive: false };
    case types.SAVE_DSS:
      return { ...state, isSavingDss: true };
    case types.SAVE_DSS_SUCCESS:
      return { ...state, isCleared: false, isSavingDss: false };
    case types.SAVE_DSS_ERROR:
      return { ...state, isSavingDss: false };
    case types.WINDOW_RESIZED:
      return {
        ...state,
        labResultsEndIndex:
          state.labResultsStartIndex +
          getNumberOfColumnsForWidth(action.payload, state.showLabEvaluation, state.chatCollapsed)
      };
    case types.COLLAPSE_CHAT:
      return {
        ...state,
        chatCollapsed: true,
        labResultsEndIndex:
          state.labResultsStartIndex + getNumberOfColumnsForWidth(action.windowWidth, state.showLabEvaluation, true)
      };
    case types.EXPAND_CHAT:
      return {
        ...state,
        chatCollapsed: false,
        labResultsEndIndex:
          state.labResultsStartIndex + getNumberOfColumnsForWidth(action.windowWidth, state.showLabEvaluation, false)
      };
    case types.POST_BLOOD_PRESSURE_GOALS_REQUEST:
      return { ...state, isSavingBloodPressureGoals: true };
    case types.POST_BLOOD_PRESSURE_GOALS_SUCCESS:
      return {
        ...state,
        isCleared: false,
        hasUnsavedBloodPressureGoalChanges: false,
        isSavingBloodPressureGoals: false,
        goalSystolic: action.payload.systolic,
        goalDiastolic: action.payload.diastolic
      };
    case types.POST_BLOOD_PRESSURE_GOALS_ERROR:
      return { ...state, isSavingBloodPressureGoals: false };
    case types.GET_ANAMNESIS_UPDATE_SUMMARY_REQUEST:
      return { ...state, loadingAnamnesisUpdateSummary: true };
    case types.GET_ANAMNESIS_UPDATE_SUMMARY_SUCCESS:
      return {
        ...state,
        isCleared: false,
        loadingAnamnesisUpdateSummary: false,
        anamnesisUpdateSummary: action.payload,
        anamnesisUpdateError: undefined
      };
    case types.GET_ANAMNESIS_UPDATE_SUMMARY_ERROR:
      return { ...state, loadingAnamnesisUpdateSummary: false, anamnesisUpdateError: action.payload };
    case types.GET_MEMBER_MEDICINE_EXTRAS_REQUEST:
      return { ...state, loadingMedicineExtras: true };
    case types.GET_MEMBER_MEDICINE_EXTRAS_SUCCESS:
      return {
        ...state,
        isCleared: false,
        loadingMedicineExtras: false,
        medicineExtras: action.payload,
        medicineExtrasError: undefined
      };
    case types.GET_MEMBER_MEDICINE_EXTRAS_ERROR:
      return {
        ...state,
        loadingMedicineExtras: false,
        medicineExtrasError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.SAVE_FINAL_NOTE_REQUEST:
      return { ...state, savingFinalNote: true };
    case types.SAVE_FINAL_NOTE_SUCCESS:
      return {
        ...state,
        isCleared: false,
        savingFinalNote: false,
        hasUnsavedChanges: false,
        finalNoteConfirmationModalActive: false
      };
    case types.SAVE_FINAL_NOTE_ERROR:
      return { ...state, savingFinalNote: false };
    case types.SET_ACTIVE_CAREGIVER_REQUEST:
      return { ...state, settingActiveCaregiver: true };
    case types.SET_ACTIVE_CAREGIVER_SUCCESS:
      return { ...state, settingActiveCaregiver: false };
    case types.SET_ACTIVE_CAREGIVER_ERROR:
      return { ...state, settingActiveCaregiver: false };
    case types.ADD_NOTE_SUCCESS:
      return { ...state, hasWrittenFinalNote: action.payload.noteType === 'finalnote' };
    default:
      return state;
  }
};

export default decisionSupportReducer;
