import * as types from '../constants/actionTypes';
import { extractProperties, extractCaregivers } from '../utils';
import { USER_PROPERTY_KEYS } from '../constants';
import { loadSessionState } from '../services/sessionStorage';

const getQueryString = (queryParams) => {
  return Object.keys(queryParams)
    .filter((key) => queryParams[key].value !== null)
    .map((key) => key + queryParams[key].comparator + queryParams[key].value)
    .join(';');
};

const auth = loadSessionState('auth', true);

let queryParams = {
  caregiver: {
    value: auth && auth.token && auth.token.user ? auth.token.user.guid : null,
    comparator: '=='
  }
};
let searchQuery = getQueryString(queryParams);
let caregiverProperty;

const initialState = {
  membersACTIVE: [],
  currentPageACTIVE: 0,
  searchQueryACTIVE: searchQuery,
  queryParamsACTIVE: queryParams,
  currentSearchTermACTIVE: '',
  paginationACTIVE: {
    offset: -1,
    limit: -1,
    totalNumberOfItems: -1
  },
  membersINACTIVE: [],
  currentPageINACTIVE: 0,
  searchQueryINACTIVE: searchQuery,
  queryParamsINACTIVE: queryParams,
  currentSearchTermINACTIVE: '',
  paginationINACTIVE: {
    offset: -1,
    limit: -1,
    totalNumberOfItems: -1
  },
  ownPatientsACTIVE: [],
  ownPatientsINACTIVE: []
};

const membersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types[`GET_${action.namespace}_MEMBERS_REQUEST`]:
      return { ...state, [`loadingMembers${action.namespace}`]: true };
    case types[`GET_${action.namespace}_MEMBERS_SUCCESS`]: {
      const members = action.payload.members
        .map((searchResult) => {
          return { ...searchResult.user, ...searchResult.userExtras };
        })
        .map((user) => extractProperties(user, USER_PROPERTY_KEYS))
        .map((user) => extractCaregivers(user));
      caregiverProperty = `responsible${action.isNurse ? 'Nurse' : 'Doctor'}`;

      return {
        ...state,
        [`loadingMembers${action.namespace}`]: false,
        [`members${action.namespace}`]: members,
        [`pagination${action.namespace}`]: action.payload.pagination,
        [`ownPatients${action.namespace}`]: [
          ...new Set([
            ...state[`ownPatients${action.namespace}`],
            ...members
              .filter(
                (member) => member[caregiverProperty] && member[caregiverProperty].guid === action.currentUser.guid
              )
              .map((member) => member.guid)
          ])
        ],
        [`membersError${action.namespace}`]: undefined
      };
    }
    case types[`GET_${action.namespace}_MEMBERS_ERROR`]:
      return {
        ...state,
        [`loadingMembers${action.namespace}`]: false,
        [`membersError${action.namespace}`]: action.payload
      };
    case types[`SET_CURRENT_PAGE_${action.namespace}`]:
      return { ...state, [`currentPage${action.namespace}`]: action.payload };
    case types[`SET_SEARCH_QUERY_${action.namespace}`]:
      queryParams = { ...state[`queryParams${action.namespace}`], ...action.payload.searchQuery };
      searchQuery = getQueryString(queryParams);

      return {
        ...state,
        [`queryParams${action.namespace}`]: queryParams,
        [`searchQuery${action.namespace}`]: searchQuery,
        [`currentSearchTerm${action.namespace}`]:
          action.payload.searchTerm !== undefined
            ? action.payload.searchTerm
            : state[`currentSearchTerm${action.namespace}`],
        [`currentPage${action.namespace}`]: 0
      };
    case types.LOGIN_SUCCESS:
      queryParams.caregiver = { value: action.payload.user.guid, comparator: '==' };
      searchQuery = getQueryString(queryParams);
      return {
        ...state,
        queryParamsACTIVE: queryParams,
        searchQueryACTIVE: searchQuery,
        queryParamsINACTIVE: queryParams,
        searchQueryINACTIVE: searchQuery
      };
    case types.SET_CURRENT_MEMBER: {
      let newOwnPatientsACTIVE = state.ownPatientsACTIVE;
      let newOwnPatientsINACTIVE = state.ownPatientsINACTIVE;

      if (action.payload.caregivers) {
        if (action.payload.caregivers.map((caregiver) => caregiver.caregiver.guid).includes(action.caregiverGuid)) {
          newOwnPatientsACTIVE = [...newOwnPatientsACTIVE, action.payload.guid];
          newOwnPatientsINACTIVE = [...newOwnPatientsINACTIVE, action.payload.guid];
        }
      }

      return { ...state, ownPatientsACTIVE: newOwnPatientsACTIVE, ownPatientsINACTIVE: newOwnPatientsINACTIVE };
    }
    default:
      return state;
  }
};

export default membersListReducer;
