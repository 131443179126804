import * as types from '../constants/actionTypes';

const initialState = {
  loadingCaregiverMetrics: false,
  caregiverMetrics: {
    numberOfPatients: -1,
    numberOfActivePatients: -1
  }
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CAREGIVER_METRICS_REQUEST:
      return { ...state, loadingCaregiverMetrics: true };
    case types.GET_CAREGIVER_METRICS_SUCCESS: {
      const metricNames = action.payload.map((metric) => metric.name);
      const numberOfPatientsIndex = metricNames.indexOf('numberOfPatients');
      const numberOfPatients = parseInt(action.payload[numberOfPatientsIndex].value, 10);
      const numberOfActivePatientsIndex = metricNames.indexOf('numberOfActivePatients');
      const numberOfActivePatients = parseInt(action.payload[numberOfActivePatientsIndex].value, 10);
      const valuesToBeRemoved = [numberOfPatientsIndex, numberOfActivePatientsIndex];

      return {
        ...state,
        loadingCaregiverMetrics: false,
        caregiverMetrics: {
          numberOfPatients,
          numberOfActivePatients,
          metrics: action.payload.filter((value, index) => {
            return valuesToBeRemoved.indexOf(index) === -1;
          })
        },
        caregiverMetricsError: undefined
      };
    }
    case types.GET_CAREGIVER_METRICS_ERROR:
      return {
        ...state,
        loadingCaregiverMetrics: false,
        caregiverMetricsError: action.payload,
        caregiverMetrics: { numberOfPatients: -1, numberOfActivePatients: -1 }
      };
    case types.ASSIGN_PAL_SUCCESS:
      return {
        ...state,
        caregiverMetrics: {
          ...state.caregiverMetrics,
          numberOfPatients: state.caregiverMetrics.numberOfPatients + 1,
          numberOfActivePatients: state.caregiverMetrics.numberOfActivePatients + 1
        }
      };
    default:
      return state;
  }
};

export default dashboardReducer;
