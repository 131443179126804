import React, { Fragment, Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import metadata from '../../../metadata.json';
import ModalDialog from '../../ModalDialog';
import ContentLoader from '../../ContentLoader';
import {
  previewFile,
  hideUploadFileModal,
  showUploadFileModal,
  selectContentType,
  uploadFile,
  selectFileToUpload,
  editFilenameBeforeUpload,
  postFileToChat,
  showFullSizeImage,
  hideCreateDocumentModal,
  showCreateDocumentModal,
  selectDocumentType,
  generateDocument,
  updateTemplateContentProperty
} from '../../../actions';
import { ACCEPTABLE_FILE_TYPES, DATE_FORMAT, IMAGE_LIST_THUMBNAIL_WIDTH, CURRENT_ENV } from '../../../constants';
import './MemberFiles.scss';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');

class MemberFiles extends Component {
  constructor(props) {
    super(props);

    this.preview = this.preview.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.postFileToChat = this.postFileToChat.bind(this);
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this);
    this.generateDocument = this.generateDocument.bind(this);
    this.beginCreateDocument = this.beginCreateDocument.bind(this);

    this.fileInputRef = React.createRef();

    const observerOptions = {
      root: document.querySelector('.member-files-container'),
      rootMargin: '0px',
      threshold: 0
    };

    this.intersectionObserver = new IntersectionObserver(this.intersectionObserverCallback, observerOptions);

    this.previewOptions = {
      headerI18nKey: 'patient_view.chat.fullsize_image_header',
      size: 'large'
    };
  }

  preview(fileId, filename, contentType) {
    const { authToken, member, previewFile } = this.props;
    const previewOptions = {
      onActionCompleted: this.postFileToChat,
      actionI18nKey: 'patient_view.notes.send_to_chat'
    };
    previewFile(authToken, member.guid, fileId, filename, contentType, previewOptions);
  }

  uploadFile() {
    const { authToken, member, files, uploadFile } = this.props;
    const formData = new FormData();

    formData.append('file', files.file);

    uploadFile(
      authToken,
      member.guid,
      files.filename,
      formData.get('file'),
      files.selectedContentType,
      this.fileInputRef
    );
  }

  postFileToChat() {
    const { authToken, member, preview, postFileToChat } = this.props;

    postFileToChat(
      authToken,
      member.guid,
      preview.currentFile.id,
      preview.currentFile.contentType,
      preview.currentFile.filename
    );
  }

  beginCreateDocument() {
    const { user, member, medications } = this.props;
    const memberCurrentMedications = medications.currentMedications
      .map((medication, index) => {
        return `${index + 1}. ${medication.current.name} (${medication.current.form}), ${
          medication.current.strength || ''
        }, ${medication.current.dosageRegimen || ''} \n`;
      })
      .join('');

    const prefilledData = {
      caregiverName: `${user.givenName} ${user.familyName}`,
      caregiverPhoneNumber: user.phoneNumber,
      memberName: `${member.givenName} ${member.familyName}`,
      memberPersonalNumber: member.personalNumber,
      memberAddress:
        member.streetaddress && member.postalCode && member.city
          ? `${member.streetaddress}, ${member.postalCode} ${member.city}`
          : '',
      memberPhoneNumber: member.phoneNumber,
      memberCurrentMedications
    };

    this.props.showCreateDocumentModal(prefilledData);
  }

  generateDocument() {
    const { authToken, member, files } = this.props;
    this.props.generateDocument(
      authToken,
      member.guid,
      files.selectedDocumentType,
      files.documentContent,
      this.preview
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.files.memberImages.length !== prevProps.files.memberImages.length) {
      const thumbnails = document.querySelectorAll('.image-list__thumbnail');
      thumbnails.forEach((thumbnail) => this.intersectionObserver.observe(thumbnail));
    }
  }

  componentDidMount() {
    const thumbnails = document.querySelectorAll('.image-list__thumbnail');
    thumbnails.forEach((thumbnail) => this.intersectionObserver.observe(thumbnail));
  }

  intersectionObserverCallback(entries) {
    entries.forEach((entry) => {
      const imagePath = entry.target.getAttribute('data-thumbnail-image-src');
      if (entry.isIntersecting && imagePath && !entry.target.innerHTML) {
        fetch(`${basePath}${imagePath}?maxWidth=${IMAGE_LIST_THUMBNAIL_WIDTH}`, {
          headers: {
            Authorization: `Bearer ${this.props.authToken}`,
            'Accumbo-Client-ID': this.props.clientId
          }
        })
          .then((response) => response.blob())
          .then((image) => {
            entry.target.innerHTML = `<img src="${URL.createObjectURL(image)}" />`;
          })
          .catch((error) => console.log(error));
      }
    });
  }

  render() {
    const {
      files,
      hideUploadFileModal,
      showUploadFileModal,
      selectContentType,
      selectFileToUpload,
      editFilenameBeforeUpload,
      showFullSizeImage,
      hideCreateDocumentModal,
      selectDocumentType,
      updateTemplateContentProperty
    } = this.props;

    return (
      <Fragment>
        <div className="member-files-container">
          <div className="columns">
            <div className="column no-padding mr-20">
              <div className="rounded-container member-files">
                <div className="columns mb-0">
                  <div className="column is-3 no-padding">
                    <h2>
                      <Translate value="global.documents" />
                    </h2>
                  </div>
                  <div className="column no-padding text-right">
                    <span className="document-action" onClick={showUploadFileModal}>
                      <Translate value="patient_view.notes.upload_file" />
                    </span>
                    <span className="document-action" onClick={this.beginCreateDocument}>
                      <Translate value="global.create" />
                    </span>
                  </div>
                </div>
                <ContentLoader
                  isLoading={files.loadingMemberFiles}
                  error={files.memberFilesError}
                  errorTitleI18n="patient_view.notes.member_files_error"
                >
                  {files.memberFiles.length ? (
                    files.memberFiles.map((file, i) => {
                      return (
                        <DocumentRow
                          key={i}
                          file={file}
                          onClick={() => this.preview(file.id, file.filename, file.contentType)}
                        />
                      );
                    })
                  ) : (
                    <span className="field__empty">
                      <Translate value="patient_view.notes.no_documents" />
                    </span>
                  )}
                </ContentLoader>
              </div>
            </div>
            <div className="column no-padding">
              <div className="rounded-container member-files">
                <h2>
                  <Translate value="global.images" />
                </h2>
                <ContentLoader
                  isLoading={files.loadingMemberFiles}
                  error={files.memberFilesError}
                  errorTitleI18n="patient_view.notes.member_files_error"
                >
                  {files.memberImages.length ? (
                    files.memberImages.map((image, i) => {
                      return (
                        <ImageRow
                          key={i}
                          image={image}
                          onClick={() => showFullSizeImage(image.filePath, this.previewOptions)}
                        />
                      );
                    })
                  ) : (
                    <span className="field__empty">
                      <Translate value="patient_view.notes.no_images" />
                    </span>
                  )}
                </ContentLoader>
              </div>
            </div>
          </div>
        </div>
        <ModalDialog
          headerI18nKey="patient_view.notes.upload_file"
          actionI18nKey="patient_view.notes.upload_file"
          visible={files.uploadFileModalActive}
          onClose={hideUploadFileModal}
          onActionCompleted={this.uploadFile}
          actionCompletable={!!(files.selectedContentType && files.file)}
          actionCompleting={files.uploadingFile}
        >
          <div className="file-upload-dialog">
            <input
              type="file"
              id="upload-file-input"
              onChange={selectFileToUpload}
              accept={ACCEPTABLE_FILE_TYPES}
              ref={this.fileInputRef}
            />
            <label htmlFor="upload-file-input">
              <Translate value="global.select_file" />
            </label>
            <h4>
              <Translate value="patient_view.notes.edit_filename" />
            </h4>
            <input
              type="text"
              className="w-100"
              placeholder={files.file ? null : I18n.t('patient_view.notes.no_file_selected')}
              value={files.filename}
              onChange={editFilenameBeforeUpload}
            />
            {files.unsupportedFileTypeSelected ? (
              <div className="error-message mt-5">
                <Translate value="patient_view.notes.unsupported_filetype" />
              </div>
            ) : null}
            <div className="notice mt-15 mb-15">
              <Translate value="patient_view.notes.filename_notice" />
            </div>
            <h4>
              <Translate value="patient_view.notes.content_type" />
            </h4>
            <div className="control">
              <input
                type="radio"
                name="upload-content-type"
                id="content-option-referral"
                value="referral"
                checked={files.selectedContentType === 'referral'}
                onChange={selectContentType}
              />
              <label className="radio" htmlFor="content-option-referral">
                <Translate value="global.referral" />
              </label>
              <input
                type="radio"
                name="upload-content-type"
                id="content-option-labresult"
                value="labresult"
                checked={files.selectedContentType === 'labresult'}
                onChange={selectContentType}
              />
              <label className="radio" htmlFor="content-option-labresult">
                <Translate value="global.labresult" />
              </label>
              <input
                type="radio"
                name="upload-content-type"
                id="content-option-certificate"
                value="certificate"
                checked={files.selectedContentType === 'certificate'}
                onChange={selectContentType}
              />
              <label className="radio" htmlFor="content-option-certificate">
                <Translate value="global.certificate" />
              </label>
              <input
                type="radio"
                name="upload-content-type"
                id="content-option-other"
                value="other"
                checked={files.selectedContentType === 'other'}
                onChange={selectContentType}
              />
              <label className="radio" htmlFor="content-option-other">
                <Translate value="global.other" />
              </label>
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          headerI18nKey="patient_view.notes.create_document"
          actionI18nKey="patient_view.notes.create_document"
          visible={files.createDocumentModalActive}
          onClose={hideCreateDocumentModal}
          onActionCompleted={this.generateDocument}
          actionCompletable={files.selectedDocumentType !== null}
          actionCompleting={files.generatingDocument}
          contentClass="pt-10"
          size="slim"
        >
          <div className="fw-input">
            <div className="mb-20">
              <span>
                <input
                  type="radio"
                  id="document-type-referral"
                  value="referral"
                  name="document-type"
                  checked={files.selectedDocumentType === 'referral'}
                  onChange={selectDocumentType}
                />
                <label htmlFor="document-type-referral">
                  <Translate value="global.referral" />
                </label>
              </span>
              <span className="ml-15">
                <input
                  type="radio"
                  id="document-type-certificate"
                  value="certificate"
                  name="document-type"
                  checked={files.selectedDocumentType === 'certificate'}
                  onChange={selectDocumentType}
                />
                <label htmlFor="document-type-certificate">
                  <Translate value="global.certificate" />
                </label>
              </span>
            </div>
            {files.selectedDocumentType !== null ? (
              <Fragment>
                <fieldset>
                  <legend>
                    <Translate value="patient_view.notes.referring_doctor" />
                  </legend>
                  <div className="columns">
                    <div className="column no-padding pad-right">
                      <h4>
                        <Translate value="global.name" />
                      </h4>
                      <DebounceInput
                        type="text"
                        value={files.documentContent.caregiverName}
                        debounceTimeout={300}
                        onChange={(e) => {
                          updateTemplateContentProperty('caregiverName', e);
                        }}
                      />
                    </div>
                    <div className="column no-padding">
                      <h4>
                        <Translate value="global.phone" />
                      </h4>
                      <DebounceInput
                        type="text"
                        value={files.documentContent.caregiverPhoneNumber}
                        debounceTimeout={300}
                        onChange={(e) => {
                          updateTemplateContentProperty('caregiverPhoneNumber', e);
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>
                    <Translate value="patient_view.notes.patient_details" />
                  </legend>
                  <div className="columns">
                    <div className="column no-padding pad-right">
                      <h4>
                        <Translate value="global.name" />
                      </h4>
                      <DebounceInput type="text" disabled={true} value={files.documentContent.memberName} />
                    </div>
                    <div className="column no-padding">
                      <h4>
                        <Translate value="global.personalNumber" />
                      </h4>
                      <DebounceInput type="text" disabled={true} value={files.documentContent.memberPersonalNumber} />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column no-padding">
                      <h4>
                        <Translate value="global.address" />
                      </h4>
                      <DebounceInput
                        type="text"
                        value={files.documentContent.memberAddress}
                        debounceTimeout={300}
                        onChange={(e) => {
                          updateTemplateContentProperty('memberAddress', e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column no-padding">
                      <h4>
                        <Translate value="global.phone_number" />
                      </h4>
                      <DebounceInput
                        type="text"
                        value={files.documentContent.memberPhoneNumber}
                        debounceTimeout={300}
                        onChange={(e) => {
                          updateTemplateContentProperty('memberPhoneNumber', e);
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
              </Fragment>
            ) : null}
            {files.selectedDocumentType === 'referral' ? (
              <Fragment>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.referral_recipient" />
                    </h4>
                    <DebounceInput
                      type="text"
                      maxLength="57"
                      value={files.documentContent.referralRecipient}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('referralRecipient', e);
                      }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.requested_examination" />
                    </h4>
                    <DebounceInput
                      type="text"
                      maxLength="54"
                      value={files.documentContent.requestedExamination}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('requestedExamination', e);
                      }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.diagnosis_and_query" />
                    </h4>
                    <DebounceInput
                      type="text"
                      maxLength="50"
                      value={files.documentContent.diagnosis}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('diagnosis', e);
                      }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.anamnesis" />
                    </h4>
                    <DebounceInput
                      element="textarea"
                      maxLength="770"
                      value={files.documentContent.anamnesis}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('anamnesis', e);
                      }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.current_medications" />
                    </h4>
                    {this.props.medications.unsignedMedications.length ? (
                      <h4 className="error-message">
                        <Translate value="patient_view.notes.has_unsigned_medications_warning" />
                      </h4>
                    ) : null}
                    <DebounceInput
                      element="textarea"
                      value={files.documentContent.memberCurrentMedications}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('memberCurrentMedications', e);
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            ) : null}
            {files.selectedDocumentType === 'certificate' ? (
              <Fragment>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.recipient" />
                    </h4>
                    <DebounceInput
                      type="text"
                      maxLength="60"
                      value={files.documentContent.referralRecipient}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('referralRecipient', e);
                      }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.diagnosis" />
                    </h4>
                    <DebounceInput
                      type="text"
                      maxLength="65"
                      value={files.documentContent.diagnosis}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('diagnosis', e);
                      }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column no-padding">
                    <h4>
                      <Translate value="patient_view.notes.document_field.anamnesis_summary" />
                    </h4>
                    <DebounceInput
                      element="textarea"
                      maxLength="1600"
                      value={files.documentContent.anamnesis}
                      debounceTimeout={300}
                      onChange={(e) => {
                        updateTemplateContentProperty('anamnesis', e);
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            ) : null}
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.token.user,
    authToken: state.auth.token.jwt,
    member: state.currentMember,
    files: state.files,
    preview: state.preview,
    medications: state.medications,
    clientId: state.auth.clientId
  };
};

const mapActionsToProps = {
  previewFile,
  hideUploadFileModal,
  showUploadFileModal,
  selectContentType,
  uploadFile,
  selectFileToUpload,
  editFilenameBeforeUpload,
  postFileToChat,
  showFullSizeImage,
  hideCreateDocumentModal,
  showCreateDocumentModal,
  selectDocumentType,
  generateDocument,
  updateTemplateContentProperty
};

export default connect(mapStateToProps, mapActionsToProps)(MemberFiles);

const DocumentRow = ({ file, onClick }) => {
  return (
    <div className="file-row document" onClick={onClick} data-sensitive>
      <div>
        <strong>{file.filename}</strong>
      </div>
      <div>
        <Translate value="patient_view.notes.document_type" />: <Translate value={`global.${file.contentType}`} />
      </div>
      <div>
        <Translate
          value="patient_view.notes.uploaded_by"
          uploaderName={`${file.uploadedBy && file.uploadedBy.givenName} ${
            file.uploadedBy && file.uploadedBy.familyName
          }`}
        />
      </div>
      <div>{moment(file.timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}</div>
    </div>
  );
};

const ImageRow = ({ image, onClick }) => {
  return (
    <div className="file-row image" onClick={onClick} data-sensitive>
      <div className="flex">
        <div className="mr-10">
          <div className="image-list__thumbnail" data-thumbnail-image-src={image.filePath}></div>
        </div>
        <div>
          <Translate value="global.added" />: {moment(image.timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
        </div>
      </div>
    </div>
  );
};
